export default class WorkerLoader extends Worker {
  constructor(worker: any, onMessage: any) {
    const code = worker.toString();
    const blob = new Blob([`(${code})()`]);
    const workerUrl = window.URL.createObjectURL(blob);
    super(workerUrl);

    window.URL.revokeObjectURL(workerUrl);    
    this.onmessage = onMessage
  }
}