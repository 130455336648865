import { MetarEntity } from "../../pages/main/main.slice";
import { ICAO_LIST } from "../constants/category.constant";

interface ThreadValue {
  ids: string[];
  metars: string[];
  threadInfo: {
    threadIndex: number;
    threadSize: number;
    originalDataSize: number;
  }
}
export const CreateEntityWorker = () => {
  const dateRegex = new RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2} \d{2}:\d{2}/, 'gm');

  const removeICAO = (METARs: string, icaoList: typeof ICAO_LIST) => {
    const icaosRegex = new RegExp(icaoList.join("|"), 'gm');
    return METARs.replace(icaosRegex, '');
  }

  const createThreads = (ids: string[], metars: string[], threadSize: number) => {
    const dataSize = ids.length;
    const threadCount = Math.ceil(dataSize / threadSize);

    const threads = new Set<ThreadValue>();
    for (let i=0; i < threadCount - 1; i++) {
      const [start, end] = [threadSize*i, threadSize * (i+1)];
      threads.add({ 
        ids: ids.slice(start, end),
        metars: metars.slice(start, end),
        threadInfo: {
          threadIndex: i,
          threadSize,
          originalDataSize: dataSize,
        },
      })
    }
    threads.add({ 
      ids: ids.slice(threadSize * (threadCount-1)),
      metars: metars.slice(threadSize * (threadCount-1)),
      threadInfo: {
        threadIndex: threadCount-1,
        threadSize,
        originalDataSize: dataSize,
      },
    })

    return threads;
  }

  const createThreadEntity = (threads: Set<ThreadValue>) => {
    const result: MetarEntity[] = [];
    threads.forEach((thread) => {
      const { ids, metars, threadInfo } = thread;
      const { threadIndex, threadSize, originalDataSize } = threadInfo;
      const entities: any = {};
      ids.forEach((date, idx) => {
        const currentIdx = idx + threadSize*threadIndex;
        const progress = Math.round(currentIdx / originalDataSize * 100);
        const next = Math.round((currentIdx + 1) / originalDataSize * 100);

        if (progress !== next) {
          postMessage(progress);
        }

        if (currentIdx === originalDataSize-1) {
          postMessage(100);
        }

        entities[date] = metars[idx].trim()
      })

      result.push({ ids, entities });
      threads.delete(thread);
    })

    return result;
  }

  const combineThreadEntity = (threadEntity: MetarEntity[]) => {    
    const result: MetarEntity = { ids: [], entities: {} };
    threadEntity.forEach((entity) => {
      result.ids = result.ids.concat(entity.ids);
      Object.assign(result.entities, entity.entities);
    })

    result.ids = result.ids.sort((a, b) => Date.parse(b) - Date.parse(a));
    return result;
  }

  const createEntity = (METARs: string, icaoList: typeof ICAO_LIST): MetarEntity | null => {
    const dates = METARs.match(dateRegex);
    const metars = removeICAO(METARs, icaoList).split(dateRegex).slice(1);
    if (!dates || !metars) return null;

    const threadSize = 2000;
    const threads = createThreads(dates, metars, threadSize);
    const threadEntity = createThreadEntity(threads);

    const entity = combineThreadEntity(threadEntity);
    return entity;
  }

  globalThis.onmessage = (event) => {
    const { METARs, icaoList } = event.data;    
    const entity = createEntity(METARs, icaoList);
    
    postMessage(entity);
  }
}