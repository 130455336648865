import { MONTH } from "../../../common/constants/category.constant";
import { CALM, SIXTEEN_DIRECTION, SIXTEEN_DIRECTION_VALUE } from "../../../common/constants/direction.constants";
import { Windrose, WindroseChartDataset } from "./windrose.interface";

export class WindroseHelper {
  constructor(
    private readonly sixteenDirValue = SIXTEEN_DIRECTION_VALUE,
    private readonly ktKeys = ['0-1', '1-5', '5-10', '10-15', '15-20', '20-25', '25+'] as const,
    private readonly dirKeys = [...SIXTEEN_DIRECTION, CALM] as const,
    private readonly month = MONTH,
    private readonly calm = CALM,
  ) {}

  getEchartWinroseDataset(windrose: Windrose, month: number) {
    const dataset = this.getWindroseDataset(windrose);
  
    const dataname = 'direction';
    const dimension = [dataname, ...this.ktKeys];
    const source = this.dirKeys.map((key) => (
      { [dataname]: key, ...dataset[month][key] }
    ))
  
    return { dimension, source };
  }

  getMaxForEcharts(dataset: ReturnType<typeof this.getEchartWinroseDataset>) {
    const { source } = dataset;
    const sumArr = source.map((obj) => {
      let sum = 0;
      Object.values(obj).forEach((val) => {
        if (typeof val === 'number') sum += val
      })
      return sum;
    })
    return Math.max(...sumArr);
  }

  private getWindroseDataset(windrose: Windrose) {
    const result = this.getEmptyWindroseDataset();

    windrose.forEach((byMonth, idx) => {
      const directions = Object.keys(byMonth);
      for (let dir of directions) {
        if (dir === 'total' || dir === 'VRB') continue;

        const { speed } = byMonth[dir];
        const dirKey = this.getDirKeys(dir);
        speed.forEach((ws) => {
          const ktKey = this.splitBy5KT(ws);
          result[idx][dirKey][ktKey]++;
        })
      }

      const { total } = byMonth;
      for (let dir of this.dirKeys) {
        for (let kt of this.ktKeys) {
          result[idx][dir][kt] = parseFloat((100 * result[idx][dir][kt] / total).toFixed(2));
        }
      }
    })

    return result;
  }

  private getDirKeys(direction: string) {
    if (direction === this.calm) {
      return this.calm as typeof CALM;
    }
    else return this.sixteenDirValue[direction];
  }

  private splitBy5KT(windspeed: number) {
    if (windspeed < 1) {
      return '0-1';
    }
    else if (windspeed >= 1 && windspeed < 5) {
      return '1-5';
    }
    else if (windspeed >= 5 && windspeed < 10) {
      return '5-10';
    }
    else if (windspeed >= 10 && windspeed < 15) {
      return '10-15';
    }
    else if (windspeed >= 15 && windspeed < 20) {
      return '15-20';
    }
    else if (windspeed >= 20 && windspeed < 25) {
      return '20-25';
    }
    else {
      return '25+';
    }
  }

  private getEmptyWindroseDataset() {
    return this.month.map(() => {
      const dataByDir = this.dirKeys.reduce((acc, key) => (
        { ...acc, [key]: [...this.ktKeys].reduce((acc, key) => ({ ...acc, [key]: 0 }), {}) }
      ), {} as WindroseChartDataset);
      return dataByDir
    })
  }
}