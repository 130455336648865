import { WEATHER_CODE } from "../../../common/constants/category.constant";

export class WeatherHelper {
  constructor(private readonly wxCode = WEATHER_CODE) {}

  isInMETAR(METAR: string, wxcode: typeof this.wxCode[number]) {
    const trimed = this.trimMETAR(METAR);
    const codeRegex = new RegExp(wxcode, 'gm');
    return codeRegex.test(trimed);
  } 

  private removeRemark(METAR: string) {
    const splited = METAR.split('RMK');
    return splited[0];
  }

  private trimMETAR(METAR: string) {
    return this.removeRemark(METAR.trim());
  }
}