import React, { useCallback } from 'react';

export interface ModalWrapperProps {
  children: JSX.Element;
  setModal?: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
  innerHeight?: string;
}

export default function ModalWrapper(props: ModalWrapperProps) {
  const { children, setModal, innerHeight } = props;

  const onClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (
      setModal && 
      e.target instanceof HTMLDivElement && 
      e.target.id === 'modal-wrapper'
    ) {
      setModal(null);
    }
  }, [setModal]);

  return (
    <div
      id="modal-wrapper"
      className="fixed inset-0 flex justify-center items-center w-full h-full z-50 bg-black/40 animate-show"
      onClick={onClick}
    >
      <div className={`w-[90%] ${innerHeight || 'h-4/5'} max-w-4xl p-4 bg-white opacity-100 text-gray-900 rounded-lg`}>
        {children}
      </div>
    </div>
  );
}
