import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { AnalyticsComponentProps } from "../Analytics.container";
import { AnalyticsDataset } from "../analytics.interface";
import { AnalyticsState, setWeatherCondition } from "../analytics.slice";
import Hourly from "../components/Hourly";
import Monthly from "../components/Monthly";
import Search from "./Search";

export default function WeatherContainer(props: AnalyticsComponentProps) {
  const { entity, analyticsHelper } = props;
  const { weather } = useAppSelector((state) => state.analytics);
  const { condition } = weather;
  const dispatch = useAppDispatch();

  let dataset: AnalyticsDataset | null = null;
  if (condition) {
    dataset = analyticsHelper.createDataset(entity, 'weather', condition);
  }

  const submitWxCondition = useCallback((payload: AnalyticsState['weather']['condition']) => {
    dispatch(setWeatherCondition(payload));
  }, [dispatch])
  
  return(
    <>
      <Search submitWxCondition={submitWxCondition} weather={weather} />
      {
        dataset 
        ? <>
            <Monthly dataset={dataset} analyticsHelper={analyticsHelper} />
            <Hourly dataset={dataset} analyticsHelper={analyticsHelper} />
          </>
        : null
      }
    </>
  )
}