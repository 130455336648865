import { useCallback, useState } from "react";
import Card from "../Card";
import MonthlyTable from "../components/Monthly-Table";
import Switch from "../components/Switch";
import { HumidityHelper } from "./humidity.helper";
import { HumidityDataset, HumidityMonthlyEchart } from "./humidity.interface";
import MonthlyEchart from "./Monthly-Echart";

export interface MonthlyProps {
  dataset: HumidityDataset;
  humidityHelper: HumidityHelper;
}

export default function Monthly(props: MonthlyProps) {
  const { dataset, humidityHelper } = props;
  const [year, setYear] = useState('total');
  const [view, setView] = useState<'graph' | 'table'>('graph');
  const echartDataset = humidityHelper.getDatasetForEcharts(dataset, year, 'monthly') as HumidityMonthlyEchart;

  const onChangeSelect = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setYear(e.target.value)
  }, [])

  return (
    <Card>
      <div className="flex justify-between text-sm font-semibold mb-4">
        <h3>Relative Humidity [%] (monthly)</h3>
        <select onChange={onChangeSelect} value={year}>
          {Object.keys(dataset.monthly).map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
      </div>
      {
        view === 'graph' 
        ? <MonthlyEchart echartDataset={echartDataset} />
        : <MonthlyTable chartDataset={dataset.monthly[year]} classification={'RH'} total={false} />
      }
      <Switch view={view} setView={setView} />
    </Card>
  )
}