import Wrapper from "../../common/components/container/Wrapper";
import { CATEGORY } from "../../common/constants/category.constant";
import Card from "./Card";

export default function Index() {
  return(
    <>
      <header className="bg-gray-100 text-zinc-800">
        <Wrapper>
          <div className="h-40 py-12 text-center">
            <h3 className="text-xl font-semibold">Category</h3>
            <p>Select what you want to analyze</p>
          </div>
        </Wrapper>
      </header>
      <main className="bg-gray-100">
        <Wrapper>
          <div className="pb-10">
            {CATEGORY.map((category) => (
              <Card category={category} key={category}/>
            ))}
          </div>
        </Wrapper>
      </main>
    </>
  )
}