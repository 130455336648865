import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import WorkerLoader from "../../../common/workers/loader.worker";
import { WindroseWorker } from "./windrose.worker";
import { getWindroseEntity } from "../analytics.slice";
import Chart from "./Chart";
import Table from "./Table";
import { WindroseHelper } from "./windrose.helper";
import { AnalyticsComponentProps } from "../Analytics.container";
import Loading from "../../../common/components/modal/Loading";

const windroseHelper = new WindroseHelper();

export default function WindroseContainer(props: AnalyticsComponentProps) {
  const { entity } = props;
  const dispatch = useAppDispatch();
  const { windrose } = useAppSelector((state) => state.analytics);

  useEffect(() => {
    const windroseWorker = new WorkerLoader(WindroseWorker, (message: any) => {
      const data = message.data;
      dispatch(getWindroseEntity(data));
      windroseWorker.terminate();
    })

    windroseWorker.postMessage(entity);
  }, [entity, dispatch])

  if (!windrose) {
    return (
      <Loading />
    )
  }

  return(
    <>
      <Table windrose={windrose} />
      <Chart windrose={windrose} windroseHelper={windroseHelper} />
    </>
  )
}