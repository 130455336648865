import { useCallback, useState } from "react";
import { MONTH } from "../../../common/constants/category.constant";
import Card from "../components/Card";
import { Windrose } from "./windrose.interface";

export interface AnalyticsTableProps {
  windrose: Windrose;
}

export default function Table(props: AnalyticsTableProps) {
  const { windrose } = props;
  const [month, setMonth] = useState(0);
  const windroseByMonth = windrose[month];

  const onChangeSelect = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setMonth(parseInt(e.target.value))
  }, [])

  return(
    <Card>
      <div className="h-80 overflow-y-auto scrollbar-hide">
        <div className="flex justify-between text-sm font-semibold mb-4">
          <h3>By Direction</h3>
          <select onChange={onChangeSelect} value={month}>
            {MONTH.map((month, idx) => (
              <option key={month} value={idx}>{month}</option>
            ))}
          </select>
        </div>
        <table className="w-full text-sm text-center table-auto">
          <thead className="text-gray-400 bg-gray-50">
            <tr className="h-8">
              <th>Direction[˚]</th>
              <th>Mean Speed[KT]</th>
              <th>Max Gust[KT]</th>
              <th>Rate[%]</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {windrose && Object.keys(windroseByMonth).map((dir) => {
              if (dir === 'total') return null;
              
              return(
                <tr key={`${month}:${dir}`}>
                  <td>{dir}</td>
                  <td>{windroseByMonth[dir]['mean']}</td>
                  <td>{windroseByMonth[dir]['max']}</td>
                  <td>{String(windroseByMonth[dir]['rate']*100).slice(0,4)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Card>
  )
}