import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CATEGORY_DESCRIPTION, CATEGORY } from "../../common/constants/category.constant";

export interface CardProps {
  category: typeof CATEGORY[number];
}

export default function Card(props: CardProps) {
  const { category } = props;
  const [mouseover, setMouseover] = useState(false);
  const navigate = useNavigate();

  const onMouseover = useCallback(() => {
    setMouseover(true);
  }, []);

  const onMouseout = useCallback(() => {
    setMouseover(false);
  }, []);

  const onClick = useCallback(() => {
    navigate('/analytics', { state: category });
  }, []);

  return(
    <div className="mx-auto shadow-lg rounded-b-2xl max-w-lg mb-8 cursor-pointer" onMouseOver={onMouseover} onMouseOut={onMouseout} onClick={onClick}>
      <div className="relative flex flex-col justify-center items-center rounded-t-xl h-44 bg-card-bg before:absolute before:bottom-0 before:bg-red-600 before:w-1/3 before:h-6 before:rounded-t-3xl after:h-6 after:absolute after:-bottom-6 after:inset-x-0 after:rounded-b-2xl after:h-6 after:bg-card-midline">
        <div className={`w-12 h-12 border-8 ${mouseover ? 'animate-spin' : 'rotate-45'}`}></div>
        <p className="pt-4 font-semibold text-white">{category.toUpperCase()}</p>
      </div>
      <div className="rounded-b-xl h-16 bg-gray-50 pt-8 text-center text-sm text-black">
        {CATEGORY_DESCRIPTION[category]}
      </div>
    </div>
  )
}