import { MONTH } from "../../../common/constants/category.constant";
import { UnitHelper } from "../../../common/helper/unit.helper";
import { MetarEntity } from "../../main/main.slice";
import { HumidityDataset, HumidityHourlyEchart, HumidityMonthlyEchart, HumidityTable } from "./humidity.interface";

export class HumidityHelper {
  constructor(
    private readonly temperatureRegex = / M?\d{2}\/M?\d{2} /gm,
    private readonly monthList = MONTH,
    private readonly unitHelper = new UnitHelper(),
  ) {}

  getDatasetForEcharts(dataset: HumidityDataset, year: string | 'total', key: 'monthly' | 'hourly') {
    switch (key) {
      case 'monthly': {
        const dimensions = ['month', 'RH'] as const;
        const source = dataset.monthly[year].map((RH, month) => ({
          'month': this.monthList[month],
          'RH': RH,
        }));
        return { dimensions, source } as HumidityMonthlyEchart;
      }
      case 'hourly': {
        const dimensions = ['hour', 'RH'] as const;
        const echartDataset = dataset.hourly[year].map((monthly, month) => {
          const source: { hour: string, 'RH': number }[] = [];
          monthly.forEach((RH, hour) => {
            source.push({ 
              'hour': hour.toString().padStart(2, '0'), 
              'RH': RH
            })
          })
          return { dimensions, source } as HumidityHourlyEchart;
        })
        return echartDataset;
      }
    }
  }

  createDataset(entity: MetarEntity): HumidityDataset {
    const humidityTable = this.toHumidityTable(entity);

    const years = Object.keys(humidityTable);
    const monthlyResult: any = { 'total': Array(12).fill(0).map(() => 0) };
    const hourlyResult: any = { 'total': Array(12).fill(0).map(() => (
      Array(24).fill(0).map(() => 0)
    ))};
    
    const tempForMonthlyTotal = Array(12).fill(0).map(() => ({ count: 0, sum: 0 }));
    const tempForHourlyTotal = Array(12).fill(0).map(() => (
      Array(24).fill(0).map(() => ({ count: 0, sum: 0 }))
    ));
    
    for (let i=0; i < years.length; i++) {
      const year = years[i];
      if (!monthlyResult[year]) {
        monthlyResult[year] = Array(12).fill(0).map(() => 0);
      }

      if (!hourlyResult[year]) {
        hourlyResult[year] = Array(12).fill(0).map(() => (
          Array(24).fill(0).map(() => 0)
        ))
      }

      for (let month=0; month < 12; month++) {
        if (humidityTable[year][month].length === 0) continue;

        const monthly = humidityTable[year][month];

        const flat = monthly.flat().filter((T) => !Number.isNaN(T));
        const RHsum = flat.reduce((acc, cur) => acc+cur, 0);
        const RHlength = flat.length;

        if (RHlength > 0) {
          monthlyResult[year][month] = this.unitHelper.roundAt(RHsum / RHlength, 2);
          tempForMonthlyTotal[month].count += RHlength;
          tempForMonthlyTotal[month].sum += RHsum;
        }

        const temp = Array(24).fill(0).map(() => [] as number[]);
        monthly.forEach((daily, date) => {
          for (let hour=0; hour<24; hour++) {
            const RH = daily[hour];

            if (Number.isNaN(RH)) continue;
            temp[hour].push(RH);
          }
        })

        temp.forEach((hourly, hour) => {
          const RHsum = hourly.reduce((acc,cur) => acc+cur, 0);
          const RHLength = hourly.length;
          if (RHLength > 0) {
            hourlyResult[year][month][hour] = this.unitHelper.roundAt(RHsum / RHLength, 2);
            tempForHourlyTotal[month][hour].count += RHLength;
            tempForHourlyTotal[month][hour].sum += RHsum;
          }
        })
      }
    }

    for (let month=0; month < 12; month++) {
      const monthly = tempForMonthlyTotal[month];
      if (monthly.count > 0) {
        const monthlyMean = this.unitHelper.roundAt(monthly.sum / monthly.count, 2);
        monthlyResult['total'][month] = monthlyMean;
      }

      for (let hour=0; hour < 24; hour++) {
        const hourly = tempForHourlyTotal[month][hour];
        if (hourly.count > 0) {
          const hourlyMean = this.unitHelper.roundAt(hourly.sum / hourly.count, 2);
          hourlyResult['total'][month][hour] = hourlyMean;
        }
      }
    }

    return {
      monthly: monthlyResult,
      hourly: hourlyResult,
    };
  }

  toHumidityTable(entity: MetarEntity): HumidityTable {
    const { ids, entities } = entity;
    const result: any = {};
    const diff = 10;
    for (let i=0; i < ids.length; i++) {
      const dateStr = ids[i];
      if (!this.unitHelper.isNearly0clock(dateStr, diff)) continue;

      let { year, month, date, hour } = this.unitHelper.getDateComponent(dateStr);

      if (
        !this.unitHelper.is0clock(dateStr) &&
        this.unitHelper.isNearly0clock(dateStr, diff)
      ) {
        const adjusted = this.unitHelper.adjustTo0clock(dateStr, diff);
        [year, month, date, hour] = [adjusted.year, adjusted.month, adjusted.date, adjusted.hour];
      }

      if (!result[year]) {
        result[year] = Array(12).fill(0).map(() => [])
      }
      
      if (result[year][month].length === 0) {
        const numOfDays = this.unitHelper.getNumberofDays(year, month+1);
        result[year][month] = Array(numOfDays).fill(0).map((_, i) => (
          Array(24).fill(0).map(() => NaN)
        ));
      }

      const METAR = entities[dateStr];
      const temperature = this.getTemperature(METAR);
      if (!temperature) continue;

      const [T, Td] = temperature;

      if (this.unitHelper.is0clock(dateStr)) {
        result[year][month][date-1][hour] = this.getRelativeHumidity(T, Td);
      }

      if (!this.unitHelper.is0clock(dateStr) && Number.isNaN(result[year][month][date-1][hour])) {
        result[year][month][date-1][hour] = this.getRelativeHumidity(T, Td);
      }
    }

    return result;
  }

  private getTemperature(METAR: string): [number, number] | null {
    const matched = METAR.match(this.temperatureRegex);

    if (!matched) return null;

    const [T, Td] = matched[0].split('/');
    if (!T || !Td) return null;

    return [
      parseInt(T.replace('M', '-')), 
      parseInt(Td.replace('M', '-'))
    ];
  }

  private getRelativeHumidity(T:number, Td: number) {
    return 100 * (Math.exp(17.625*Td / (243.04+Td))) / Math.exp(17.625*T / (243.04+T));
  }
}