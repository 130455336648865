import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Category from '../pages/category/index';
import Analytics from '../pages/analytics/Analytics.container';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/category',
    element: <Category />,
  },
  {
    path: '/analytics',
    element: <Analytics />,
  }
])