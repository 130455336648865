import { CATEGORY, TREND_FORECAST, WEATHER_CODE } from "../../common/constants/category.constant";
import { TypeGuardHelper } from "../../common/helper/typeguard.helper";
import { UnitHelper } from "../../common/helper/unit.helper";
import { MetarEntity } from "../main/main.slice";
import { AnalyticsDataset } from "./analytics.interface";
import { AnalyticsConditions } from "./analytics.slice";
import StrongwindHelper from "./strongwind/strongwind.helper";
import VisibilityHelper from "./visibility/visibility.helper";
import { WeatherHelper } from "./weather/weather.helper";

export class AnalyticsHelper {
  private readonly unitHelper: UnitHelper;
  private readonly typeguardHelper: TypeGuardHelper;
  private readonly strongwindHelper: StrongwindHelper;
  private readonly visibilityHelper: VisibilityHelper;
  private readonly weatherHelper: WeatherHelper;
  private readonly trendForecast: typeof TREND_FORECAST;

  constructor() {
    this.unitHelper = new UnitHelper();
    this.trendForecast = TREND_FORECAST;
    this.typeguardHelper = new TypeGuardHelper();
    this.strongwindHelper = new StrongwindHelper(this.unitHelper);
    this.visibilityHelper = new VisibilityHelper(this.unitHelper);
    this.weatherHelper = new WeatherHelper();
  }

 getPeriodFromEntity(entity: MetarEntity) {
    const { ids } = entity;
    return {
      st: ids[ids.length-1],
      ed: ids[0],
    }
  }

  countMetFromEntity(entity: MetarEntity) {
    return entity.ids.length;
  }

  createDataset(entity: MetarEntity, key: typeof CATEGORY[number], condition: AnalyticsConditions['strongwind'] | AnalyticsConditions['visibility'] | AnalyticsConditions['weather']) {
    const { ids, entities } = entity;
    const result = this.createEmptyDataset(ids);

    let monthlyAll = [];
    let hourlyAll = [];
    for (let i=0; i < ids.length; i++) {
      const { year, month, date, hour } = this.unitHelper.getDateComponent(ids[i]);
      const trimedMETAR = this.trimMETAR(entities[ids[i]]);
      
      switch (key) {
        case 'strongwind': {
          if (!this.typeguardHelper.isOfType<AnalyticsConditions['strongwind']>(condition, 'wind', 'runway')) continue
          if (!this.isStrongwindData(trimedMETAR, condition)) continue;
          break;
        }
        case 'visibility': {
          if (typeof condition !== 'number') continue;
          if (!this.isLowVisData(trimedMETAR, condition as number)) continue;
          break;
        }
        case 'weather': {
          if (!WEATHER_CODE.includes(condition as AnalyticsConditions['weather'])) continue;
          if (!this.weatherHelper.isInMETAR(trimedMETAR, condition as AnalyticsConditions['weather'])) continue;
          break;
        }
      }

      monthlyAll.push(`${year}-${month+1}-${date}`);
      hourlyAll.push(`${year}-${month+1}-${date} ${hour}:00`);
    }

    monthlyAll = [...new Set(monthlyAll)];
    hourlyAll = [...new Set(hourlyAll)];

    monthlyAll.forEach((dateStr) => {
      const { year, month } = this.unitHelper.getDateComponent(dateStr);
      result.entities[year][month]['monthly']++;
      result.entities['total'][month]['monthly']++;
    })

    hourlyAll.forEach((dateStr) => {
      const { year, month, hour } = this.unitHelper.getDateComponent(dateStr);
      result.entities[year][month]['hourly'][hour]++;
      result.entities['total'][month]['hourly'][hour]++;
    })
    
    return result;
  }

  getDatasetForEcharts(dataset: AnalyticsDataset, year: string | 'total', key: 'monthly' | 'hourly') {
    switch (key) {
      case 'monthly': {
        return dataset.entities[year].map((data) => data['monthly'])
      }
      case 'hourly': {
        return dataset.entities[year].map((data) => data['hourly'])
      }
    }
  }

  private createEmptyDataset(ids: MetarEntity['ids']): AnalyticsDataset {
    const years = ids.map((dateStr) => this.unitHelper.getDateComponent(dateStr).year.toString());
    const keys = [...new Set(years)].sort();
    const dataset = [...keys, 'total'].reduce((acc, key) => {
      const data = Array(12).fill(0).map(() => ({
        hourly: Array(24).fill(0) as number[],
        monthly: 0,
      }))
      return {...acc, [key]: data }
    }, {} as any)

    return {
      ids: [...keys, 'total'],
      entities: dataset,
    };
  }

  private isLowVisData(METAR: string, condition: AnalyticsConditions['visibility']) {
    const visFromMETAR = this.visibilityHelper.getVisibility(METAR);
    if (visFromMETAR && this.visibilityHelper.isLessEqualThan(visFromMETAR, condition)) {
      return true;
    }

    return false;  
  }

  private isStrongwindData(METAR: string, condition: AnalyticsConditions['strongwind']) {
    const windFromMETAR = this.strongwindHelper.getWindComponent(METAR);
    const { wind, runway } = condition;

    if (windFromMETAR 
      && typeof wind === 'number'
      && this.strongwindHelper.isFasterThan(windFromMETAR, wind, runway)) 
    {
      return true;
    }

    return false;    
  }

  private removeRemark(METAR: string) {
    const splited = METAR.split('RMK');
    return splited[0];
  }

  private removeTrendForecast(METAR: string) {
    const tfRegex = new RegExp(this.trendForecast.join('|'), 'gm');
    const matched = tfRegex.exec(METAR);
    if (matched){
      return METAR.slice(0, matched.index)
    }
    return METAR;
  }

  private trimMETAR(METAR: string) {
    const rmkRemoved = this.removeRemark(METAR);
    const trendForecastRemoved = this.removeTrendForecast(rmkRemoved);
    const trimed = trendForecastRemoved.trim().replace(/NTSIG|DSN|SNE|SNS|SNN|SNW|SNB|TSNO|VISNO/gm,'')
    return trimed;
  }
}