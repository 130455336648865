import * as echarts from 'echarts';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { HourlyChildrenProps } from './Hourly';


export default function HourlyEchart(props: HourlyChildrenProps) {
  const { chartDataset } = props;
  const chartRef = useRef<HTMLDivElement>(null);

  const onResize = useCallback((chart: echarts.ECharts, chartRef: React.RefObject<HTMLDivElement>) => {
    return () => {
      if (!chartRef || !chartRef.current) return;
      const { offsetWidth, offsetHeight } = chartRef.current;
      
      chart.resize({
        width: offsetWidth,
        height: offsetHeight,
      })
    }
  }, [])

  const options = useMemo(() => ({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '8%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: Array(24).fill(0).map((_, i) => i.toString().padStart(2, '0')),
        axisTick: {
          alignWithLabel: true
        },
      }
    ],
    yAxis: [{ 
      type: 'value',
    }],
    series: [
      {
        type: 'line',
        data: chartDataset,
        smooth: false,
      }
    ]
  }), [chartDataset])
  
  useEffect(() => {
    let chart: echarts.ECharts;
		if (chartRef.current) {
	    chart = echarts.init(chartRef.current);
	    chart.setOption(options);
      window.addEventListener('resize', onResize(chart, chartRef))
		}
    return (() => { 
      if (chartRef && chartRef.current) {
        echarts.dispose(chartRef.current)
        window.addEventListener('resize', onResize(chart, chartRef))
      }
    })
  }, [chartRef, onResize, chartDataset, options]);

  return (
    <>
      <div
        ref={chartRef}
        className="w-full h-80 md:h-[500px]"
      />
    </>
  )
}