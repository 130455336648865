import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDLE, LOADING, FAIL, SUCCESS } from "../../common/constants/status.constant";

export interface MetarEntity {
  ids: string[];
  entities: Record<string, string>;
}

export interface MainState {
  entity: MetarEntity
  status: typeof IDLE | typeof LOADING | typeof SUCCESS | typeof FAIL;
  progress: number;
  error: string | null;
}

const intialState: MainState = {
  entity: { ids: [], entities: {} },
  status: IDLE,
  progress: 0,
  error: null,
};

const mainSlice = createSlice({
  name: 'main',
  initialState: intialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<Partial<MainState>>) => {
      return { ...state, ...action.payload };
    }
  }
});

export default mainSlice.reducer;
export const { updateEntity } = mainSlice.actions;