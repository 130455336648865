import * as echarts from "echarts";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MONTH } from "../../../common/constants/category.constant";
import { SIXTEEN_DIRECTION } from "../../../common/constants/direction.constants";
import Card from "../components/Card";
import { WindroseHelper } from "./windrose.helper";
import { Windrose } from "./windrose.interface";

const legendName = [
  "0-1 KT",
  "1-5 KT",
  "5-10 KT",
  "10-15 KT",
  "15-20 KT",
  "20-25 KT",
  "≥ 25 KT",
]

export interface WindroseChartProps {
  windrose: Windrose;
  windroseHelper: WindroseHelper;
}

export default function Chart(props: WindroseChartProps) {
  const { windrose, windroseHelper } = props;
  const [month, setMonth] = useState(0);

  const dataset = windroseHelper.getEchartWinroseDataset(windrose, month);
  const calm = dataset.source[16]['0-1'];
  const max = windroseHelper.getMaxForEcharts(dataset);

  const chartRef = useRef<HTMLDivElement>(null);
  
  const onChangeSelect = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setMonth(parseInt(e.target.value))
  }, [])

  const onResize = useCallback((chart: echarts.ECharts, chartRef: React.RefObject<HTMLDivElement>) => {
    return () => {
      if (!chartRef || !chartRef.current) return;
      const { offsetWidth, offsetHeight } = chartRef.current;
      
      chart.resize({
        width: offsetWidth,
        height: offsetHeight,
      })
    }
  }, [])

  const options = useMemo(() => ({
    tooltip: {
      trigger: 'item',
      textStyle: { color: '#333' },
    },
    color: ["#0001F7", "#0284c7", "#00B8FE", "#00FF68", "#BEFE00", "#FFFF00", "#FFA800", "#E10100"],
    angleAxis: {
      type: 'category',
      data: SIXTEEN_DIRECTION,
      boundaryGap: false,
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: { },
      },
      axisLabel: {
        show: true,
        interval: 1,
      },
    },
    radiusAxis: {
      min: 0,
      max: 1.2*max,
      axisLabel: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
    },
    polar: {
      center: ['50%', '55%'],
      radius: ['13%', '75%'],
    },
    dataset,
    series: [
      ...Array(7).fill(0).map((_, idx) => (
        { type: 'bar', coordinateSystem: 'polar', name: legendName[idx], stack: 'a' }
      )),
      {
        type: 'pie',
        center: ['50%', '55%'],
        radius: ['13%', '13%'],
        avoidLabelOverlap: false,
        label: {
          color: '#000',
          fontSize: '11',
          position: 'center',
          formatter: () => {
            return `Calm\n${calm}`;
          },
        },
        labelLine: {
          show: false,
        },
        data: [{ name: 'CALM (0-1KT)', value: calm }],
      },
    ],
    legend: {
      show: true,
      data: legendName,
      width: '100%',
    }
  }), [dataset, calm, max]);
  
  useEffect(() => {
    let chart: echarts.ECharts;
		if (chartRef.current) {
	    chart = echarts.init(chartRef.current);
	    chart.setOption(options);
      window.addEventListener('resize', onResize(chart, chartRef))
		}
    return (() => { 
      if (chartRef && chartRef.current) {
        echarts.dispose(chartRef.current)
        window.addEventListener('resize', onResize(chart, chartRef))
      }
    })
  }, [chartRef, month, onResize, options]);

  return (
    <Card>
      <div className="flex justify-between text-sm font-semibold mb-4">
        <h3>Windrose(gust not included) [%]</h3>
        <select onChange={onChangeSelect} value={month}>
          {MONTH.map((month, idx) => (
            <option key={month} value={idx}>{month}</option>
          ))}
        </select>
      </div>
      <div
        ref={chartRef}
        className="w-full h-[400px] md:h-[600px]"
      />
    </Card>
  )
}