import React, { useCallback } from "react";

export interface SwitchProps {
  view: 'graph' | 'table';
  setView: React.Dispatch<React.SetStateAction<'graph' | 'table'>>
}

export default function Switch(props: SwitchProps) {
  const { view, setView } = props;
  const onClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const target = (e.target as HTMLDivElement).id as 'graph' | 'table';
    setView(target);
  }, [])

  return(
    <div onClick={onClick} className="flex w-40 h-8 mt-4 mx-auto bg-blue-900 text-sm text-center cursor-pointer">
      <div id="graph" className={`w-1/2 h-full p-1 ${view === 'graph' ? 'text-white' : 'bg-gray-300 hover:bg-blue-100'}`}>Graph</div>
      <div id="table" className={`w-1/2 h-full p-1 ${view === 'table' ? 'text-white' : 'bg-gray-300 hover:bg-blue-100'}`}>Table</div>
    </div>
  )
}