import Card from "../components/Card";
import { ReactComponent as QuestionMark } from '../../../assets/svg/question-mark.svg';
import React, { useCallback } from "react";
import { AnalyticsState } from "../analytics.slice";
import Questionmark from "../components/Questionmark";

export interface StrongwindSearchProps {
  submitStrongwindCondition: (payload: AnalyticsState['strongwind']['condition']) => void;
  strongwind: AnalyticsState['strongwind'];
}

export default function Search(props: StrongwindSearchProps) {
  const { submitStrongwindCondition, strongwind } = props;
  const { wind, runway } = strongwind.condition;

  const onSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    const $inputSpeed = parseInt(((e.target as HTMLFormElement)[0] as HTMLInputElement).value) || null;
    const $inputRunway = parseInt(((e.target as HTMLFormElement)[1] as HTMLInputElement).value) || null;

    submitStrongwindCondition({ wind: $inputSpeed, runway: $inputRunway });
  }, [submitStrongwindCondition])

  return(
    <Card>
      <div className="flex justify-between text-sm">
        <h3 className="font-semibold after:content-['*'] after:mr-0.5 after:text-red-500">SEARCH</h3>
        <Questionmark>
          <SearchChildren />
        </Questionmark>
      </div>
      <hr className="mt-2 mb-4"/>
      <form className="flex justify-between" onSubmit={onSubmit}>
        <div>
          <div className="text-sm py-1">
            <label htmlFor="speed" className="inline-block w-28">Strong Wind ≥ </label>
            <input type='text' defaultValue={wind || undefined} className="w-12 border mx-1 text-right" id="speed"/>
            KT
          </div>
          <div className="text-sm py-1">
            <label htmlFor="runway" className="inline-block w-28">Runway</label>
            <input type='text' defaultValue={runway || undefined} className="w-12 border mx-1 text-right" id="runway"/>
          </div>
        </div>
        <button type="submit" className='w-14 h-14 my-auto rounded-lg bg-green-500 text-xs text-white flex justify-center items-center hover:bg-green-400'>
          SEARCH
        </button>
      </form>
    </Card>
  )
}

function SearchChildren() {
  return(
    <div className="text-sm sm:text-base">
      <h4 className="text-lg font-semibold">METAR</h4>
      <p>RJAA 111000Z <strong className="text-sky-600">36022G38KT</strong> 320V020 9999 FEW040 SCT200 M05/M11 Q1032 NOSIG</p>
      <ul className="list-disc list-inside pt-2 pl-2 text-sm">
        <li>Analyze whether the wind speed(include gust) is greater than or equal certain value</li>
        <li>If you input runway number, It analyzes cross wind is greater than or equal</li>
        <li>Automatically transform MPS → KT</li>
        <li>It doesn't anaylize <strong className="text-red-600">Remark</strong></li>
        <li>It doesn't anaylize <strong className="text-red-600">Trend Forecast</strong></li>
      </ul>
    </div>
  )
}