import Wrapper from "../../common/components/container/Wrapper";
import { ReactComponent as Photo } from '../../assets/svg/photo.svg';
import { ReactComponent as Setting } from '../../assets/svg/setting.svg';

export default function Footer() {
  return(
    <footer className='pb-8'>
      <Wrapper>
        <div className="text-center text-sm text-zinc-400 font-medium">
          <p>Created & Designed By atmosG</p>
          <p className='mt-1 flex justify-center content-center'>
            <Setting className='w-4 h-4 my-auto mr-1' fill='#fff'/>
            atmosg@naver.com
          </p>
          <p className='mt-1 flex justify-center content-center'>
            <Photo className='w-4 h-4 my-auto mr-1'/>
            amadas72@naver.com
          </p>
        </div>
      </Wrapper>
    </footer>
  )
}