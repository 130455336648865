import { useCallback, useState } from "react";
import { MONTH } from "../../../common/constants/category.constant";
import Card from "../components/Card";
import HourlyTable from "../components/Hourly-Table";
import Switch from "../components/Switch";
import HourlyEchart from "./Hourly-Echart";
import { HumidityHelper } from "./humidity.helper";
import { HumidityDataset, HumidityHourlyEchart } from "./humidity.interface";

export interface HourlyProps {
  dataset: HumidityDataset;
  humidityHelper: HumidityHelper;
}

export default function Hourly(props: HourlyProps) {
  const { dataset, humidityHelper } = props;
  const [year, setYear] = useState('total');
  const [month, setMonth] = useState(0);
  const [view, setView] = useState<'graph' | 'table'>('graph');
  const echartDataset = (humidityHelper.getDatasetForEcharts(dataset, year, 'hourly') as HumidityHourlyEchart[])[month];

  const onChangeSelect = useCallback((e: React.ChangeEvent<HTMLDivElement>) => {
    switch (e.target.id) {
      case 'year': {
        return setYear(((e.target as any).value))
      }
      case 'month': {
        return setMonth(((e.target as any).value))
      }
    }
  }, [])

  return (
    <Card>
      <div className="flex justify-between text-sm font-semibold mb-4">
        <h3>Relative Humidity [%] (hourly)</h3>
        <div onChange={onChangeSelect} className="flex">
          <select value={year} id="year">
            {Object.keys(dataset.hourly).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
          <select value={month} id="month">
            {MONTH.map((month, idx) => (
              <option key={month} value={idx}>{month}</option>
            ))}
          </select>
        </div>
      </div>
      
      {
        view === 'graph' 
        ? <HourlyEchart echartDataset={echartDataset}/>
        : <HourlyTable chartDataset={dataset.hourly[year][month]} classification={'RH'} total={false}/>
      }
      <Switch view={view} setView={setView} />
    </Card>
  )
}