import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { AnalyticsComponentProps } from "../Analytics.container";
import { AnalyticsDataset } from "../analytics.interface";
import { AnalyticsState, setVisiblityContidtion } from "../analytics.slice";
import Hourly from "../components/Hourly";
import Monthly from "../components/Monthly";
import Search from "./Search";

export default function VisibilityContainer(props: AnalyticsComponentProps) {
  const { entity, analyticsHelper } = props;
  const { visibility } = useAppSelector((state) => state.analytics);
  const { condition } = visibility;
  const dispatch = useAppDispatch();

  let dataset: AnalyticsDataset | null = null;
  if (condition) {
    dataset = analyticsHelper.createDataset(entity, 'visibility', condition);
  }

  const submitVisCondition = useCallback((payload: AnalyticsState['visibility']['condition']) => {
    dispatch(setVisiblityContidtion(payload));
  }, [dispatch])
  
  return(
    <>
      <Search submitVisCondition={submitVisCondition} visibility={visibility} />
      {
        dataset 
        ? <>
            <Monthly dataset={dataset} analyticsHelper={analyticsHelper} />
            <Hourly dataset={dataset} analyticsHelper={analyticsHelper} />
          </>
        : null
      }
    </>
  )
}