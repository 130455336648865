import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import Wrapper from "../../common/components/container/Wrapper";
import { CATEGORY } from "../../common/constants/category.constant";
import { AnalyticsHelper } from "./analytics.helper";
import MetarInfo from "./components/MetarInfo";
import WindroseContainer from "./windorse/Windrose.container";
import StrongwindContainer from "./strongwind/Strongwind.container";
import { MetarEntity } from "../main/main.slice";
import VisibilityContainer from "./visibility/Visibility.container";
import WeatherContainer from "./weather/Weather.container";
import Nodata from "./components/Nodata";
import TemperatureContainer from "./temperature/TemperatureContainer";
import HumidityContainer from "./humidity/Humidity.container";

export interface AnalyticsComponentProps {
  entity: MetarEntity;
  analyticsHelper: AnalyticsHelper;
}

const analyticsHelper = new AnalyticsHelper();
export default function AnalyticsContainer() {
  const location = useLocation();
  const category = location.state as typeof CATEGORY[number];
  const { entity } = useAppSelector((state) => state.main);

  if (!category) {
    return <Nodata />
  }

  let content;
  switch (category) {
    case 'windrose' : {
      content = <WindroseContainer entity={entity} analyticsHelper={analyticsHelper}/>;
      break;
    }
    case 'strongwind': {
      content = <StrongwindContainer entity={entity} analyticsHelper={analyticsHelper}/>
      break;
    }
    case 'visibility': {
      content = <VisibilityContainer entity={entity} analyticsHelper={analyticsHelper}/>
      break;
    }
    case 'weather': {
      content = <WeatherContainer entity={entity} analyticsHelper={analyticsHelper}/>
      break;
    }
    case 'temperature': {
      content = <TemperatureContainer entity={entity} analyticsHelper={analyticsHelper}/>
      break;
    }
    case 'humidity': {
      content = <HumidityContainer entity={entity} analyticsHelper={analyticsHelper}/>
      break;
    }
  }

  return(
    <>
      <header className="bg-gray-100 text-zinc-800">
        <Wrapper>
          <div className="h-40 py-12 text-center">
            <h2 className="text-xl font-semibold">Analytics Home</h2>
            <p>{category.toUpperCase()}</p>
          </div>
        </Wrapper>
      </header>
      <main className="bg-gray-100">
        <Wrapper>
          <div className="pb-40 min-h-screen">
            <MetarInfo entity={entity} analyticsHelper={analyticsHelper} />
            {content}
          </div>
        </Wrapper>
      </main>
    </>
  )
}