import { MONTH } from "../../../common/constants/category.constant";
import { UnitHelper } from "../../../common/helper/unit.helper";
import { MetarEntity } from "../../main/main.slice";
import { HourlyEchartDataset, HourlyTDataset, MonthlyEchartDataset, MonthlyTDataset, TemperatureDataset, TemperatureTable } from "./temperature.interface";

export class TemperatureHelper {
  constructor(
    private readonly temperatureRegex = / M?\d{2}\/M?\d{2} /gm,
    private readonly monthList = MONTH,
    private readonly unitHelper = new UnitHelper(),
  ) {}

  getDatasetForEcharts(dataset: TemperatureDataset, year: string | 'total', key: 'monthly' | 'hourly') {
    switch (key) {
      case 'monthly': {
        const dimensions = ['month', 'mean T_max', 'mean T', 'mean T_min'];
        const source = dataset.monthly[year].map((data, month) => ({
          'month': this.monthList[month],
          'mean T_max': data.meanTmax,
          'mean T': data.meanT,
          'mean T_min': data.meanTmin,
        }));
        return { dimensions, source } as MonthlyEchartDataset;
      }
      case 'hourly': {
        const dimensions = ['hour', 'mean T'];
        const echartDataset = dataset.hourly[year].map((monthly, month) => {
          const source: { hour: string, 'mean T': number }[] = [];
          monthly.forEach((hourly, hour) => {
            source.push({ 
              'hour': hour.toString().padStart(2, '0'), 
              'mean T': hourly.meanT
            })
          })
          return { dimensions, source } as HourlyEchartDataset;
        })
        return echartDataset
      }
    }
  }

  createDataset(entity: MetarEntity): TemperatureDataset {
    const tTable = this.toTemperatureTable(entity);
    return {
      monthly: this.createMonthlyDataset(tTable),
      hourly: this.createHourlyDataset(tTable),
    }
  }

  private createHourlyDataset(tTable: TemperatureTable): HourlyTDataset {
    const years = Object.keys(tTable);
    const result: any = { 'total': Array(12).fill(0).map(() => Array(24).fill(0).map(() => ({
      meanT: NaN, meanTmax: NaN, meanTmin: NaN,
    })))};

    const tempForTotal = Array(12).fill(0).map(() => (
      Array(24).fill(0).map(() => ({
        T: [], Tmax: [], Tmin: []
      } as { T: number[], Tmax: number[], Tmin: number[] }))
    ));

    for (let i=0; i<years.length; i++) {
      const year = years[i];
      if (!result[year]) {
        result[year] = Array(12).fill(0).map(() => []) as any;
      }

      for (let month=0; month<12; month++) {
        if (tTable[year][month].length ===0) continue;

        const monthlyResult = Array(24).fill(0).map(() => ({ 
          Tmax: 0, Tmin: 0, meanT: 0 
        }));
        const temp = Array(24).fill(0).map(() => [] as number[]);
        const monthly = tTable[year][month];
        for (let day=0; day<monthly.length; day++) {
          const daily = monthly[day];

          for (let hour=0; hour<24; hour++) {
            const T = daily[hour];
            
            if (Number.isNaN(T)) continue;
            temp[hour].push(T);
          }
        }

        for (let hour=0; hour<temp.length; hour++) {
          const hourlyData = temp[hour];

          if (hourlyData.length <= 0) continue;

          const Tmax = Math.max(...hourlyData);
          const Tmin = Math.min(...hourlyData);
          
          monthlyResult[hour].Tmax = Tmax;
          monthlyResult[hour].Tmin = Tmin;
          monthlyResult[hour].meanT = this.unitHelper.roundAt(hourlyData.reduce((acc,cur) => acc+cur, 0) / hourlyData.length, 2);
  
          const total = tempForTotal[month][hour];
          total.T = [...total.T, ...hourlyData];
          total.Tmax.push(Tmax);
          total.Tmin.push(Tmin);
        }

        result[year][month] = monthlyResult;
      }
    }

    tempForTotal.forEach((monthlyData, month) => {
      monthlyData.forEach((hourlyData, hour) => {
        const { T, Tmax, Tmin } = hourlyData;
        const Tlength = T.length;
        const TmaxLength = Tmax.length;
        const TminLength = Tmin.length;
        const target = result['total'][month][hour];
        if (Tlength > 0) {
          target.meanT = this.unitHelper.roundAt(T.reduce((acc, cur) => acc+cur, 0) / Tlength, 2)
        }

        if (TmaxLength > 0) {
          target.meanTmax = this.unitHelper.roundAt(Tmax.reduce((acc, cur) => acc+cur, 0) / TmaxLength, 2)
        }

        if (TminLength > 0) {
          target.meanTmin = this.unitHelper.roundAt(Tmin.reduce((acc, cur) => acc+cur, 0) / TminLength, 2)
        }
      })
    })

    return result;
  }

  private createMonthlyDataset(tTable: TemperatureTable): MonthlyTDataset {
    const years = Object.keys(tTable);
    const result = { 'total': Array(12).fill(0).map(() => ({ 
      Tmax: NaN, Tmin: NaN, meanT: NaN, meanTmax: NaN, meanTmin: NaN
    })) } as MonthlyTDataset;

    const tempForTotal = Array(12).fill(0).map(() => ({ 
      count: 0, Tsum: 0, Tmax: [], Tmin: [] 
    }) as { count: number; Tsum: number; Tmax: number[]; Tmin: number[] });

    for (let i=0; i < years.length; i++) {
      const year = years[i];
      if (!result[year]) {
        result[year] = Array(12).fill(0).map(() => ({ })) as any;
      }

      for (let month=0; month < 12; month++) {
        if (tTable[year][month].length === 0) continue;

        const monthly = tTable[year][month];
        const monthlyResult = result[year][month];

        const flat = monthly.flat().filter((T) => !Number.isNaN(T));
        const Tsum = flat.reduce((acc, cur) => acc+cur, 0);
        const Tlength = flat.length;

        monthlyResult.Tmax = Math.max(...flat);
        monthlyResult.Tmin = Math.min(...flat);
        monthlyResult.meanT = this.unitHelper.roundAt(Tsum / Tlength, 2);
        
        tempForTotal[month].Tsum += Tsum;
        tempForTotal[month].count += Tlength;

        const [max, min]: [number[], number[]] = [[], []];
        for (let day=0; day < monthly.length; day++) {
          const exceptNaN = monthly[day].filter((T) => !Number.isNaN(T));
          if (exceptNaN.length === 0) continue;
          max.push(Math.max(...exceptNaN));
          min.push(Math.min(...exceptNaN));
        }

        monthlyResult.meanTmax = this.unitHelper.roundAt(max.reduce((acc, cur) => acc+cur, 0) / max.length, 2);
        monthlyResult.meanTmin = this.unitHelper.roundAt(min.reduce((acc, cur) => acc+cur, 0) / min.length, 2);
        tempForTotal[month].Tmax = [...tempForTotal[month].Tmax, ...max];
        tempForTotal[month].Tmin = [...tempForTotal[month].Tmin, ...min];
      }
    }

    tempForTotal.forEach((monthlyData, month) => {
      const { count, Tsum, Tmax, Tmin } = monthlyData;
      const TmaxLength = Tmax.length;
      const TminLength = Tmin.length;

      const target = result['total'][month];
      
      if (count > 0) {
        target.meanT = this.unitHelper.roundAt(Tsum / count, 2);
      }
      
      if (TmaxLength > 0) {
        target.Tmax = Math.max(...Tmax);
        target.meanTmax = this.unitHelper.roundAt(Tmax.reduce((acc, cur) => acc+cur, 0) / TmaxLength, 2);
      }
      
      if (TminLength > 0) {
        target.Tmin = Math.min(...Tmin);
        target.meanTmin = this.unitHelper.roundAt(Tmin.reduce((acc, cur) => acc+cur, 0) / TminLength, 2);
      }
    })

    return result;
  }

  toTemperatureTable(entity: MetarEntity): TemperatureTable {
    const { ids, entities } = entity;
    const result: any = {};
    const diff = 10;
    for (let i=0; i < ids.length; i++) {
      const dateStr = ids[i];
      let { year, month, date, hour } = this.unitHelper.getDateComponent(dateStr);
      if (!this.unitHelper.isNearly0clock(dateStr, diff)) continue;

      if (
        !this.unitHelper.is0clock(dateStr) &&
        this.unitHelper.isNearly0clock(dateStr, diff)
      ) {
        const adjusted = this.unitHelper.adjustTo0clock(dateStr, diff);
        [year, month, date, hour] = [adjusted.year, adjusted.month, adjusted.date, adjusted.hour];
      }

      if (!result[year]) {
        result[year] = Array(12).fill(0).map(() => [])
      }

      if (result[year][month].length === 0) {
        const numOfDays = this.unitHelper.getNumberofDays(year, month+1);
        result[year][month] = Array(numOfDays).fill(0).map((_, i) => (
          Array(24).fill(0).map(() => NaN)
        ));
      }

      const METAR = entities[dateStr];
      const temperature = this.getTemperature(METAR);
      if (!temperature) continue;

      const [T] = temperature;

      if (this.unitHelper.is0clock(dateStr)) {
        result[year][month][date-1][hour] = T;
      }
      
      if (!this.unitHelper.is0clock(dateStr) && Number.isNaN(result[year][month][date-1][hour])) {
        result[year][month][date-1][hour] = T;
      }
    }

    return result;
  }

  private getTemperature(METAR: string): [number, number] | null {
    const matched = METAR.match(this.temperatureRegex);

    if (!matched) return null;

    const [T, Td] = matched[0].split('/');
    if (!T || !Td) return null;

    return [
      parseInt(T.replace('M', '-')), 
      parseInt(Td.replace('M', '-'))
    ];
  }
}