import { useCallback, useState } from "react";
import Card from "../components/Card";
import MonthlyEchart from "./Monthly-Echart";
import MonthlyTable from "./Monthly-Table";
import Switch from "../components/Switch";
import { AnalyticsHelper } from "../analytics.helper";
import { AnalyticsDataset } from "../analytics.interface";

export interface MonthlyProps {
  dataset: AnalyticsDataset;
  analyticsHelper: AnalyticsHelper;
}

export interface MonthlyChildrenProps {
  chartDataset: number[];
  classification?: string;
  total?: boolean;
}

export default function Monthly(props: MonthlyProps) {
  const { dataset, analyticsHelper } = props;
  const [year, setYear] = useState('total');
  const [view, setView] = useState<'graph' | 'table'>('graph');
  const chartDataset = analyticsHelper.getDatasetForEcharts(dataset, year, 'monthly') as number[];

  const onChangeSelect = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setYear(e.target.value)
  }, [])

  return (
    <Card>
      <div className="flex justify-between text-sm font-semibold mb-4">
        <h3>Number of observed days (monthly)</h3>
        <select onChange={onChangeSelect} value={year}>
          {dataset.ids.map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
      </div>
      {
        view === 'graph' 
        ? <MonthlyEchart chartDataset={chartDataset} /> 
        : <MonthlyTable chartDataset={chartDataset} total={true}/>
      }
      <Switch view={view} setView={setView} />
    </Card>
  )
}