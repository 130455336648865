import { TypeGuardHelper } from "../../../common/helper/typeguard.helper";
import { HourlyTData, HourlyTotalTData } from "./temperature.interface";

export interface HourlyTableProps {
  hourlyDataset: HourlyTData[] | HourlyTotalTData[];
  typeHelper: TypeGuardHelper;
}
export default function HourlyTable(props: HourlyTableProps) {
  let { hourlyDataset, typeHelper } = props;
  const isTotal = hourlyDataset[0] && typeHelper.isOfType<HourlyTotalTData>(hourlyDataset[0], 'meanTmax');
  const checkNaN = (val: any) => (
    !Number.isNaN(val) ? val : null
  )

  return(
    <div className="h-80 md:h-[500px] overflow-y-auto scrollbar-hide">
      <table className="w-full text-sm text-center table-fixed">
        <thead className="text-gray-400 bg-gray-50">
          <tr className="h-8">
            <th className="w-1/4">Hour</th>
            <th><span className="overline">T</span></th>
            <th><span className={isTotal ? 'overline' : ''}>T</span><sub>max</sub></th>
            <th><span className={isTotal ? 'overline' : ''}>T</span><sub>min</sub></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100 table-fixed">
          {hourlyDataset.map((data, hour) => (
            <tr key={hour} className="md:h-8">
              <td>{hour.toString().padStart(2,'0')}</td>
              <td>{checkNaN(data.meanT)}</td>
              <td>{isTotal ? checkNaN((data as HourlyTotalTData).meanTmax) : checkNaN((data as HourlyTData).Tmax)}</td>
              <td>{isTotal ? checkNaN((data as HourlyTotalTData).meanTmin) : checkNaN((data as HourlyTData).Tmin)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}