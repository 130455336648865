import { MONTH } from "../../../common/constants/category.constant";
import { MonthlyTData } from "./temperature.interface";

export interface MonthlyTableProps {
  monthlyDataset: MonthlyTData[]
}

export default function MonthlyTable(props: MonthlyTableProps) {
  const { monthlyDataset } = props;
  const checkNaN = (val: any) => (
    !Number.isNaN(val) ? val : null
  )

  return(
    <div className="h-80 md:h-[500px] overflow-y-auto scrollbar-hide">
      <table className="w-full text-sm text-center table-fixed">
        <thead className="text-gray-400 bg-gray-50">
          <tr className="h-8">
            <th className="w-1/4">Month</th>
            <th className="overline">T</th>
            <th><span className="overline">T</span><sub className="none">max</sub></th>
            <th><span className="overline">T</span><sub className="none">min</sub></th>
            <th>T<sub>max</sub></th>
            <th>T<sub>min</sub></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100">
          {MONTH.map((month, idx) => (
            <tr key={month} className="md:h-8">
              <td>{month}</td>
              <td>{checkNaN(monthlyDataset[idx].meanT)}</td>
              <td>{checkNaN(monthlyDataset[idx].meanTmax)}</td>
              <td>{checkNaN(monthlyDataset[idx].meanTmin)}</td>
              <td>{checkNaN(monthlyDataset[idx].Tmax)}</td>
              <td>{checkNaN(monthlyDataset[idx].Tmin)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}