import { UnitHelper } from "../../../common/helper/unit.helper";
import { NotVRBWind, Wind } from "../analytics.interface";


export default class StrongwindHelper {
  constructor(
    private readonly unitHelper: UnitHelper,
    private readonly windRegex = /(VRB\d{2}(G\d{2})?)(KT|MPS)|(\d{5}(G\d\d)?)(KT|MPS)/gm,
  ) {}

  isFasterThan(wind: Wind, condition: number, runway: number | null) {
    if (typeof runway === 'number' && wind['direction'] !== 'VRB') {
      const { steady, gust } = this.getCrosswind(wind as NotVRBWind, runway);
      return (steady >= condition || gust >= condition);
    }

    const { steady, gust } = wind;
    return (steady >= condition || gust >= condition);
  }

  getCrosswind(wind: NotVRBWind, runway: number) {
    const { direction, steady, gust } = wind;
    const radianDiff = this.unitHelper.degreeToRadian((runway * 10 - direction));
    return {
      steady: steady * Math.abs(Math.sin(radianDiff)),
      gust: gust * Math.abs(Math.sin(radianDiff)),
    };
  }

  getWindComponent(METAR: string) {
    const matched = METAR.match(this.windRegex);
    if (!matched) return null;

    const windMet = matched[0];

    const isVRB = windMet.startsWith('VRB');
    const unit = windMet.endsWith('KT') ? 'KT' : 'MPS';
    const wind: Wind = {
      direction: isVRB ? 'VRB' : 360,
      steady: 0,
      gust: 0,   
    }
    
    if (!this.isValidDirection(wind.direction)) return null;
  
    const splited = windMet.split('G');
    wind.steady = parseInt(splited[0].slice(3,5));
  
    if (splited.length === 2) {
      wind.gust = parseInt(splited[1].slice(0,2));
    }
  
    if (unit === 'MPS') {
      wind.steady = this.unitHelper.mpsToKT(wind.steady);
      wind.gust = this.unitHelper.mpsToKT(wind.gust);
    }

    if (!isVRB) {
      wind.direction = parseInt(splited[0].slice(0,3));
    }

    if (wind.direction === 0) {
      wind.direction = 360
    }

    return wind;
  }

  isValidDirection(direction: number | 'VRB') {
    if (typeof direction === 'number') {
      return (direction >= 0 && direction <= 360) 
          && (direction % 10 === 0) 
    }
    else return direction === 'VRB'
  }
}