import { TypeGuardHelper } from "../../../common/helper/typeguard.helper";
import { AnalyticsComponentProps } from "../Analytics.container";
import Hourly from "./Hourly";
import Monthly from "./Monthly";
import { TemperatureHelper } from "./temperature.helper"

const typeHelper = new TypeGuardHelper();
const THelper = new TemperatureHelper();

export default function TemperatureContainer(props: AnalyticsComponentProps) {
  const { entity } = props;
  const dataset = THelper.createDataset(entity);
  
  return (
    <>
      {
        dataset 
        ? <>
            <Monthly dataset={dataset} THelper={THelper} />
            <Hourly dataset={dataset} THelper={THelper} typeHelper={typeHelper} />
          </>
        : null
      }
    </>
  )
}