import { MONTH } from "../../../common/constants/category.constant";
import { MonthlyChildrenProps } from "./Monthly";

export default function MonthlyTable(props: MonthlyChildrenProps) {
  const { chartDataset, classification, total } = props;

  return(
    <div className="h-80 md:h-[500px] overflow-y-auto scrollbar-hide">
      <table className="w-full text-sm text-center table-fix">
        <thead className="text-gray-400 bg-gray-50">
          <tr className="h-8">
            <th className="w-1/4">Month</th>
            <th>{classification || 'Count'}</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100">
          {MONTH.map((month, idx) => (
            <tr key={month} className="md:h-8">
              <td>{month}</td>
              <td>{chartDataset[idx]}</td>
            </tr>
          ))}
          {
            total &&
            <tr className="bg-gray-50 h-8">
              <td>TOTAL</td>
              <td>{chartDataset.reduce((acc, cur) => acc+cur, 0)}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  )
}