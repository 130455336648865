import { useCallback, useState } from "react";
import { MONTH } from "../../../common/constants/category.constant";
import Card from "../components/Card";
import HourlyEchart from "./Hourly-Echart";
import HourlyTable from "./Hourly-Table";
import Switch from "../components/Switch";
import { AnalyticsDataset } from "../analytics.interface";
import { AnalyticsHelper } from "../analytics.helper";

export interface HourlyProps {
  dataset: AnalyticsDataset;
  analyticsHelper: AnalyticsHelper;
}

export interface HourlyChildrenProps {
  chartDataset: number[];
  classification?: string;
  total?: boolean;
}

export default function Hourly(props: HourlyProps) {
  const { dataset, analyticsHelper } = props;
  const [year, setYear] = useState('total');
  const [month, setMonth] = useState(0);
  const [view, setView] = useState<'graph' | 'table'>('graph');
  const chartDataset = analyticsHelper.getDatasetForEcharts(dataset, year, 'hourly')[month] as number[];

  const onChangeSelect = useCallback((e: React.ChangeEvent<HTMLDivElement>) => {
    switch (e.target.id) {
      case 'year': {
        return setYear(((e.target as any).value))
      }
      case 'month': {
        return setMonth(((e.target as any).value))
      }
    }
  }, [])

  return (
    <Card>
      <div className="flex justify-between text-sm font-semibold mb-4">
        <h3>Number of observed days (hourly)</h3>
        <div onChange={onChangeSelect} className="flex">
          <select value={year} id="year">
            {dataset.ids.map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
          <select value={month} id="month">
            {MONTH.map((month, idx) => (
              <option key={month} value={idx}>{month}</option>
            ))}
          </select>
        </div>
      </div>
      
      {
        view === 'graph' 
        ? <HourlyEchart chartDataset={chartDataset}/>
        : <HourlyTable chartDataset={chartDataset} total={true}/>
      }
      <Switch view={view} setView={setView} />
    </Card>
  )
}