import Card from "../components/Card";
import React, { useCallback } from "react";
import { AnalyticsState } from "../analytics.slice";
import { WEATHER_CODE } from "../../../common/constants/category.constant";
import Questionmark from "../components/Questionmark";

export interface WeatherSearchProps {
  submitWxCondition: (payload: AnalyticsState['weather']['condition']) => void;
  weather: AnalyticsState['weather'];
}

export default function Search(props: WeatherSearchProps) {
  const { submitWxCondition, weather } = props;
  const { condition } = weather;

  const onSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const $selected = ((e.target as any)[0] as HTMLOptionElement).value as string;
    const trimed = $selected.trim() as typeof WEATHER_CODE[number];
    submitWxCondition(trimed);
  }, [submitWxCondition])

  return(
    <Card>
      <div className="flex justify-between text-sm">
        <h3 className="font-semibold after:content-['*'] after:mr-0.5 after:text-red-500">SEARCH</h3>
        <Questionmark>
          <SearchChildren />
        </Questionmark>
      </div>
      <hr className="mt-2 mb-4"/>
      <form className="flex justify-between" onSubmit={onSubmit}>
        <div className="text-sm py-1">
          <span className="inline-block w-28">Weather Code</span>
          <select defaultValue={condition || undefined}>
            {WEATHER_CODE.map((code) => (
              <option key={code} defaultValue={code}>&nbsp;{code}&nbsp;</option>
            ))}
          </select>
        </div>
        <button type="submit" className='w-14 h-14 my-auto rounded-lg bg-green-500 text-xs text-white flex justify-center items-center hover:bg-green-400'>
          SEARCH
        </button>
      </form>
    </Card>
  )
}

function SearchChildren() {
  return(
    <div className="text-sm sm:text-base">
      <h4 className="text-lg font-semibold">METAR</h4>
      <p>ZSYT 191800Z 23020KT <strong className="text-sky-600">-TSRA</strong> FEW015CB BKN030 OVC070 25/23 Q1006 NOSIG <span className="line-through">RMK TS NW MOV SE</span></p>
      <ul className="list-disc list-inside pt-2 pl-2 text-sm">
        <li>It anaylizes whether certain weather is in or not</li>
        <li>If you chose TS, It anaylizes all weather that contain TS (TSRA, VCTS, ... )</li>
        <li>It doesn't anaylize <strong className="text-red-600">Remark</strong></li>
        <li>It doesn't anaylize <strong className="text-red-600">Trend Forecast</strong></li>
      </ul>
    </div>
  )
}