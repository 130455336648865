import React, { useEffect, useRef } from 'react';
import Loading from '../../common/components/modal/Loading';
import { ICAO_LIST } from '../../common/constants/category.constant';
import { CreateEntityWorker } from '../../common/workers/create-entity.worker';
import WorkerLoader from '../../common/workers/loader.worker';
import { MainState } from './main.slice';

export interface MainFormProps {
  setModal: any;
  updateEntityWrapper: (payload: Partial<MainState>) => void
}

export default function Form({ setModal, updateEntityWrapper }: MainFormProps) {
  const $textArea = useRef<HTMLTextAreaElement>(null);

  const entityWorker = new WorkerLoader(CreateEntityWorker, (message: any) => {
    const data = message.data;
    if (typeof data === 'number') {
      updateEntityWrapper({ 
        status: 'loading', 
        progress: data 
      })
    }
    else if (typeof data === 'object') {
      updateEntityWrapper({
        status: 'success',
        entity: { ids: data.ids, entities: data.entities },
      });
      entityWorker.terminate();
    }
    else {
      updateEntityWrapper({
        status: 'fail',
        progress: 0,
      })
      entityWorker.terminate();
    }
  })

  const onSubmitForm = async (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    const input = $textArea.current?.value;
    if (!input) return setModal(null);
    setModal(<Loading />);
  
    const message = {
      METARs: input,
      icaoList: ICAO_LIST,
    }
    entityWorker.postMessage(message);
    setModal(null)
  };

  return (
    <>
      <div className="max-w-6xl h-full m-auto">
        <h3 className='text-midium font-semibold after:content-["*"] after:ml-0.5 after:text-red-500'>
            METARs
        </h3>
        <form className="pt-4 h-full" onSubmit={onSubmitForm}>
          <textarea
            ref={$textArea}
            className="block w-full h-[calc(100%-68px)] py-2 px-4 bg-gray-50 rounded-lg text-sm resize-none border"
          ></textarea>
          <button
            className="w-full h-8 mt-4 bg-zinc-700 text-zinc-50 rounded-sm hover:bg-zinc-500"
            type="submit"
          >
            START
          </button>
        </form>
      </div>
    </>
  );
}