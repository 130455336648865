import { MetarEntity } from "../../main/main.slice";
import { Wind } from "../analytics.interface";
import { Windrose } from "./windrose.interface";

export const WindroseWorker = () => {
  const windRegex = /(VRB\d{2}(G\d{2})?)(KT|MPS)|(\d{5}(G\d\d)?)(KT|MPS)/gm;

  const createResultFormat = () => {
    const result = Array(12).fill({});
    const keys = Array(39).fill(10).map((val, i) => {
      if (i <= 35) return String(val*(i+1))
      else if (i === 36) return 'VRB';
      else if (i === 37) return 'CALM';
      else return 'total';
    });
    result.forEach((_, i, arr) => {
      const obj:any = {};
      keys.forEach((key) => {
        if (key === 'total') { obj[key] = 0; }
        else obj[key] = { count: 0, speed: [], sum: 0, mean: 0, max: 0, rate: 0 };
      })
      arr[i] = obj
    })

    return result as Windrose;
  }

  const roundAt = (num: number, point: number) => parseFloat(num.toFixed(point));
    
  const getWindComponent = (METAR: string) => {
    const matched = METAR.match(windRegex);
    if (!matched) return null;

    const windMet = matched[0];

    const isVRB = windMet.startsWith('VRB');
    const unit = windMet.endsWith('KT') ? 'KT' : 'MPS';
    const wind: Wind = {
      direction: isVRB ? 'VRB' : 360,
      steady: 0,
      gust: 0,   
    }
  
    const splited = windMet.split('G');
    wind.steady = parseInt(splited[0].slice(3,5));
  
    if (splited.length === 2) {
      wind.gust = parseInt(splited[1].slice(0,2));
    }
  
    if (unit === 'MPS') {
      wind.steady = 1.944 * wind.steady;
      wind.gust = 1.944 * wind.gust;
    }

    if (!isVRB) {
      wind.direction = parseInt(splited[0].slice(0,3));
    }

    if (wind.direction === 0) {
      wind.direction = 360
    }

    return wind;
  }

  const isValidDirection = (direction: number | 'VRB') => {
    if (typeof direction === 'number') {
      return (direction >= 0 && direction <= 360) 
          && (direction % 10 === 0) 
    }
    else return direction === 'VRB'
  }

  const isWindCalm = (wind: Wind) => {
    return wind.steady < 1
  }

  const prodStatistics = (entity: MetarEntity) => {
    const { ids, entities } = entity;
    const result = createResultFormat();

    for (let i=0; i < ids.length; i++) {
      const date = ids[i];
      const METAR = entities[date];
      const month = new Date(date).getMonth();

      const wind = getWindComponent(METAR);
      if (!wind) continue;

      const { direction, steady, gust } = wind;
      if (!isValidDirection(direction)) continue;

      let dir = direction.toString();
      if (isWindCalm(wind)) {
        dir = 'CALM';
      }

      result[month][dir]['count']++;
      result[month][dir]['speed'].push(steady);      
      result[month][dir]['sum'] += steady;
      result[month][dir]['max'] = Math.max(gust, result[month][dir]['max']);
      result[month]['total']++;
    }

    for (let month=0; month < 12; month++) {
      const keys = Array(38).fill(10).map((val, i) => {
        if (i === 36) return 'VRB';
        else if (i === 37) return 'CALM';
        else return (i+1)*val;
      });
      keys.forEach((key) => {
        const byMonth = result[month];
        const byMonthDir = result[month][key];
        
        result[month][key]['mean'] = roundAt(byMonthDir['sum'] / byMonthDir['count'] + Number.EPSILON, 2) || 0;
        result[month][key]['rate'] = roundAt(byMonthDir['count'] / byMonth['total'] + Number.EPSILON, 4) || 0;
      })
    }

    return result;
  }


  globalThis.onmessage = (event: MessageEvent<MetarEntity>) => {
    const entity = event.data;
    const statistics = prodStatistics(entity);
    postMessage(statistics);
  }
}