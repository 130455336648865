import { MetarEntity } from "../../main/main.slice";
import { AnalyticsHelper } from "../analytics.helper";
import Card from "./Card";

export interface MetarInfo {
  entity: MetarEntity;
  analyticsHelper: AnalyticsHelper;
}

export default function MetarInfo(props: MetarInfo) {
  const { entity, analyticsHelper } = props;
  const { st, ed } = analyticsHelper.getPeriodFromEntity(entity);

  return(
    <Card>
      <h3 className="text-sm font-semibold mb-4">METAR INFO
        <hr className="mt-2"/>
      </h3>
      <ul className="text-sm leading-6">
        <li>Totals {analyticsHelper.countMetFromEntity(entity)}</li>
        <li>Period {st} ~ {ed}</li>
      </ul>
    </Card>
  )
}