import * as echarts from 'echarts';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { HourlyEchartDataset } from './temperature.interface';

export interface HourlyEchartProps {
  echartDataset: HourlyEchartDataset;
}

export default function HourlyEchart(props: HourlyEchartProps) {
  const { echartDataset } = props;
  const chartRef = useRef<HTMLDivElement>(null);
  const min = Math.floor(Math.min(...echartDataset.source.map((val) => val['mean T']))/2)*2;

  const onResize = useCallback((chart: echarts.ECharts, chartRef: React.RefObject<HTMLDivElement>) => {
    return () => {
      if (!chartRef || !chartRef.current) return;
      const { offsetWidth, offsetHeight } = chartRef.current;
      
      chart.resize({
        width: offsetWidth,
        height: offsetHeight,
      })
    }
  }, [])

  const options = useMemo(() => ({
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['mean T'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '8%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { alignWithLabel: true },
      }
    ],
    yAxis: [{ 
      type: 'value',
      allowDecimals: false,
      min,
    }],
    color: ["#e04941", '#10c926', "#687eba"],
    dataset: echartDataset,
    series: [{type: 'line'}],
  }), [echartDataset])
  
  useEffect(() => {
    let chart: echarts.ECharts;
		if (chartRef.current) {
	    chart = echarts.init(chartRef.current);
	    chart.setOption(options);
      window.addEventListener('resize', onResize(chart, chartRef))
		}
    return (() => { 
      if (chartRef && chartRef.current) {
        echarts.dispose(chartRef.current)
        window.addEventListener('resize', onResize(chart, chartRef))
      }
    })
  }, [chartRef, onResize, echartDataset, options]);

  return (
    <>
      <div
        ref={chartRef}
        className="w-full h-80 md:h-[500px]"
      />
    </>
  )
}