import { ReactComponent as Spinner } from '../../../assets/svg/spinner.svg';
export interface LoadingProps {
  text?: string;
  css?: string;
  spinner?: string;
}

export default function Loading(props: LoadingProps) {
  const { text, css, spinner } = props;
  
  return(
    <div role="status" className={`flex items-center space-x-2 ${css}`}>
      <Spinner className={`animate-spin ${spinner ? spinner : 'stroke-gray-500'}`} />
      <span className='text-xs font-medium text-gray-500'>{text}</span>
    </div>
  )
}