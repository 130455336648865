export class UnitHelper {
  constructor(
    private readonly oneMile = 1600,
    private readonly oneMps = 1.944,
  ) {}

  cavokToMeter() {
    return 9999;
  }

  mileToMeter(mile: string) {
    const str = mile.trim();
    if (str === 'P6SM') {
      return 9999;
    }
  
    const fraction = str.split('SM')[0];
    return this.fractionToDecimal(fraction) * this.oneMile;
  }

  mpsToKT(mps: number) {
    return mps * this.oneMps;
  }

  degreeToRadian(degree: number) {
    return degree * Math.PI / 180;
  }

  roundAt(num: number, point: number) {
    return parseFloat(num.toFixed(point));
  }

  getDateComponent(dateStr: string) {
    const date = new Date(dateStr);
    return {
      year: date.getFullYear(),
      month: date.getMonth(),
      date: date.getDate(),
      hour: date.getHours(),
      min: date.getMinutes(),
    }
  }

  is0clock(dateStr: string) {
    const min = new Date(dateStr).getMinutes();
    return (min === 0);
  }

  isNearly0clock(dateStr: string, diff: number) {
    const min = new Date(dateStr).getMinutes();
    return (min >= (60-diff) || min <= diff);
  }

  adjustTo0clock(dateStr: string, diff: number) { 
    let { year, month, date, hour, min } = this.getDateComponent(dateStr);
    if (min >= (60-diff)) {
      hour = hour+1;
      const newDate = new Date(year, month, date, hour);
      year = newDate.getFullYear();
      month = newDate.getMonth();
      date = newDate.getDate();
      hour = newDate.getHours();
    }

    return { year, month, date, hour, min };
  }

  getNumberofDays(year: number, month: number) {
    return new Date(year, month, 0).getDate();
  }

  private fractionToDecimal(fraction: string) {
    const x = fraction.split(' ');
    if (x.length > 1){
      const y = x[1].split('/');
      const z = y.map((val) => parseInt(val))
      return (parseInt(x[0]) + (z[0] / z[1]));
    }
  
    const y = x[0].split('/');
    const z = y.map((val) => parseInt(val));
    if(z.length > 1){
      return (z[0] / z[1]);
    }
  
    return z[0];
  }
}