import { useCallback, useState } from 'react';
import { ReactComponent as QuestionMark } from '../../../assets/svg/question-mark.svg';
import ModalWrapper from '../../../common/components/modal/Wrapper';

export interface QuestionmarkProps {
  children: JSX.Element;
}
export default function Questionmark(props: QuestionmarkProps) {
  const { children } = props;
  const [modal, setModal] = useState<JSX.Element | null>(null);

  const onClick = useCallback(() => {
    setModal(<div>hi</div>)
  }, []);

  return (
    <>
      <QuestionMark className="w-6 h-6 p-1 cursor-pointer" onClick={onClick}/>
      {
        modal && 
        <ModalWrapper setModal={setModal} innerHeight='h-min-1/4 sm:h-min-1/5'>
          {children}
        </ModalWrapper>
      }
    </>
  )
}