import Wrapper from "../../common/components/container/Wrapper";

export default function Header() {
  return (
    <header className='italic text-center text-white leading-8'>
      <Wrapper>
        <div className='pt-44 md:pt-60'>
          <h1 className='text-4xl font-semibold'>METAR TO REPORT</h1>
          <p>find meaning in countless METARs</p>
        </div>
      </Wrapper>
    </header>
  )
}