export interface WrapperProps {
  children?: JSX.Element | JSX.Element[];
}

export default function Wrapper({ children }: WrapperProps) {
  return (
    <div className="max-w-6xl m-auto px-4">
      {Array.isArray(children) ? children.map((jsx) => jsx) : children}
    </div>
  )
}