import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WEATHER_CODE } from "../../common/constants/category.constant";
import { Windrose } from "./windorse/windrose.interface";

export interface AnalyticsConditions {
  strongwind: { wind: number | null; runway: number | null };
  visibility: number;
  weather: typeof WEATHER_CODE[number];
}

export interface AnalyticsState {
  windrose: Windrose | null;
  strongwind: {
    condition: AnalyticsConditions['strongwind'];
  };
  visibility: {
    condition: AnalyticsConditions['visibility'] | null;
  };
  weather: {
    condition: AnalyticsConditions['weather'] | null;
  }
}

const initialState: AnalyticsState = {
  windrose: null,
  strongwind: {
    condition: { wind: null, runway: null },
  },
  visibility: {
    condition: null,
  },
  weather: {
    condition: null,
  }
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    getWindroseEntity: (state, action: PayloadAction<Windrose>) => {
      state.windrose = action.payload;
    },
    setStrongwindContition: (state, action: PayloadAction<AnalyticsState['strongwind']['condition']>) => {
      state.strongwind.condition = action.payload;
    },
    setVisiblityContidtion: (state, action: PayloadAction<AnalyticsState['visibility']['condition']>) => {
      state.visibility.condition = action.payload;
    },
    setWeatherCondition: (state, action: PayloadAction<AnalyticsState['weather']['condition']>) => {
      state.weather.condition = action.payload;
    }
  },
});

export default analyticsSlice.reducer;
export const { 
  getWindroseEntity, 
  setStrongwindContition, 
  setVisiblityContidtion,
  setWeatherCondition,
} = analyticsSlice.actions;