import { useCallback, useState } from "react";
import { MONTH } from "../../../common/constants/category.constant";
import { TypeGuardHelper } from "../../../common/helper/typeguard.helper";
import Card from "../components/Card";
import Switch from "../components/Switch";
import HourlyEchart from "./Hourly-Echart";
import HourlyTable from "./Hourly-Table";
import { TemperatureHelper } from "./temperature.helper";
import { HourlyEchartDataset, HourlyTData, HourlyTotalTData, TemperatureDataset } from "./temperature.interface";

export interface HourlyProps {
  dataset: TemperatureDataset;
  THelper: TemperatureHelper;
  typeHelper: TypeGuardHelper;
}

export default function Hourly(props: HourlyProps) {
  const { dataset, THelper, typeHelper } = props;
  const [year, setYear] = useState('total');
  const [month, setMonth] = useState(0);
  const [view, setView] = useState<'graph' | 'table'>('graph');
  const chartDataset = (THelper.getDatasetForEcharts(dataset, year, 'hourly') as HourlyEchartDataset[])[month];
  const hourlyDataset: HourlyTData[] | HourlyTotalTData[] = dataset.hourly[year][month];

  const onChangeSelect = useCallback((e: React.ChangeEvent<HTMLDivElement>) => {
    switch (e.target.id) {
      case 'year': {
        return setYear(((e.target as any).value))
      }
      case 'month': {
        return setMonth(((e.target as any).value))
      }
    }
  }, [])

  return (
    <Card>
      <div className="flex justify-between text-sm font-semibold mb-4">
        <h3>Temperature [℃] (hourly)</h3>
        <div onChange={onChangeSelect} className="flex">
          <select value={year} id="year">
            {Object.keys(dataset.hourly).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
          <select value={month} id="month">
            {MONTH.map((month, idx) => (
              <option key={month} value={idx}>{month}</option>
            ))}
          </select>
        </div>
      </div>
      
      {
        view === 'graph' 
        ? <HourlyEchart echartDataset={chartDataset}/>
        : <HourlyTable hourlyDataset={hourlyDataset} typeHelper={typeHelper}/>
      }
      <Switch view={view} setView={setView} />
    </Card>
  )
}