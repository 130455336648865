import Card from "../components/Card";
import { ReactComponent as QuestionMark } from '../../../assets/svg/question-mark.svg';
import React, { useCallback } from "react";
import { AnalyticsState } from "../analytics.slice";
import Questionmark from "../components/Questionmark";

export interface VisibilitySearchProps {
  submitVisCondition: (payload: AnalyticsState['visibility']['condition']) => void;
  visibility: AnalyticsState['visibility'];
}

export default function Search(props: VisibilitySearchProps) {
  const { submitVisCondition, visibility } = props;
  const { condition } = visibility;

  const onSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    const $inputVisiblity = parseInt(((e.target as HTMLFormElement)[0] as HTMLInputElement).value) || null;
    submitVisCondition($inputVisiblity);
  }, [submitVisCondition])

  return(
    <Card>
      <div className="flex justify-between text-sm">
        <h3 className="font-semibold after:content-['*'] after:mr-0.5 after:text-red-500">SEARCH</h3>
        <Questionmark>
          <SearchChildren />
        </Questionmark>
      </div>
      <hr className="mt-2 mb-4"/>
      <form className="flex justify-between" onSubmit={onSubmit}>
        <div className="text-sm py-1">
          <label htmlFor="vis" className="inline-block w-28">Visiblity &le; </label>
          <input type='text' defaultValue={condition || undefined} className="w-12 border mx-1 text-right" id="vis"/>
          m
        </div>
        <button type="submit" className='w-14 h-14 my-auto rounded-lg bg-green-500 text-xs text-white flex justify-center items-center hover:bg-green-400'>
          SEARCH
        </button>
      </form>
    </Card>
  )
}

function SearchChildren() {
  return(
    <div className="text-sm sm:text-base">
      <h4 className="text-lg font-semibold">METAR</h4>
      <p>RKPC 111000Z 31015KT <strong className="text-sky-600">1200</strong> R07/P2000D R25/P2000D -SHSN FEW015 BKN030 M01/M04 Q1026 NOSIG</p>
      <ul className="list-disc list-inside pt-2 pl-2 text-sm">
        <li>It anaylizes visibility</li>
        <li>It doesn't anaylize <strong className="text-red-600">RVR</strong></li>
        <li>It doesn't anaylize <strong className="text-red-600">Remark</strong></li>
        <li>It doesn't anaylize <strong className="text-red-600">Trend Forecast</strong></li>
      </ul>
    </div>
  )
}