import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { AnalyticsComponentProps } from "../Analytics.container";
import { AnalyticsState, setStrongwindContition } from "../analytics.slice";
import Search from "./Search";
import { AnalyticsDataset } from "../analytics.interface";
import Monthly from "../components/Monthly";
import Hourly from "../components/Hourly";

export default function StrongwindContainer(props: AnalyticsComponentProps) {
  const { entity, analyticsHelper } = props;
  const { strongwind } = useAppSelector((state) => state.analytics);
  const { wind, runway } = strongwind.condition;
  const dispatch = useAppDispatch();

  let dataset: AnalyticsDataset | null = null;
  if (wind) {
    dataset = analyticsHelper.createDataset(entity, 'strongwind', strongwind.condition);
  }

  const submitStrongwindCondition = useCallback((payload: AnalyticsState['strongwind']['condition']) => {
    dispatch(setStrongwindContition(payload));
  }, [dispatch])

  return(
    <>
      <Search submitStrongwindCondition={submitStrongwindCondition} strongwind={strongwind}/>
      {
        dataset 
        ? <>
            <Monthly dataset={dataset} analyticsHelper={analyticsHelper} />
            <Hourly dataset={dataset} analyticsHelper={analyticsHelper} />
          </>
        : null
      }
    </>
  )
}