import Wrapper from "../../../common/components/container/Wrapper";
import Header from "../../main/Header";
import bg_webp from '../../../assets/images/bg_metar2report_webp.webp';

export default function Nodata() {
  return (
    <div
      className='flex flex-col justify-between fixed w-screen h-screen bg-center bg-scroll bg-no-repeat bg-cover'
      style={{backgroundImage: `url(${bg_webp})`}}
    > 
      <Header />
      <main className='h-full'>
        <Wrapper>
          <h1 className='text-xl text-center text-white italic mt-12'>However, It can't find meaning in no data.</h1>
        </Wrapper>
      </main>
    </div>
  );
}