import { useCallback, useState } from "react";
import Card from "../Card";
import Switch from "../components/Switch";
import MonthlyEchart from "./Monthly-Echart";
import MonthlyTable from "./Monthly-Table";
import { TemperatureHelper } from "./temperature.helper";
import { MonthlyEchartDataset, TemperatureDataset } from "./temperature.interface";

export interface MonthlyProps {
  dataset: TemperatureDataset;
  THelper: TemperatureHelper;
}

export default function Monthly(props: MonthlyProps) {
  const { dataset, THelper } = props;
  const [year, setYear] = useState('total');
  const [view, setView] = useState<'graph' | 'table'>('graph');
  const chartDataset = THelper.getDatasetForEcharts(dataset, year, 'monthly') as MonthlyEchartDataset;
  const monthlyDataset = dataset.monthly[year];

  const onChangeSelect = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setYear(e.target.value)
  }, [])

  return (
    <Card>
      <div className="flex justify-between text-sm font-semibold mb-4">
        <h3>Temperature [℃] (monthly)</h3>
        <select onChange={onChangeSelect} value={year}>
          {Object.keys(dataset.monthly).map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
      </div>
      {
        view === 'graph' 
        ? <MonthlyEchart echartDataset={chartDataset} />
        : <MonthlyTable monthlyDataset={monthlyDataset} />
      }
      <Switch view={view} setView={setView} />
    </Card>
  )
}