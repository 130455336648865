import { AnalyticsComponentProps } from "../Analytics.container";
import Hourly from "./Hourly";
import { HumidityHelper } from "./humidity.helper";
import Monthly from "./Monthly";

const humidityHelepr = new HumidityHelper();

export default function HumidityContainer(props: AnalyticsComponentProps) {
  const { entity } = props;
  const dataset = humidityHelepr.createDataset(entity);
  
  return (
    <>
    {
        dataset 
        ? <>
            <Monthly dataset={dataset} humidityHelper={humidityHelepr} />
            <Hourly dataset={dataset} humidityHelper={humidityHelepr} />
          </>
        : null
      }
    </>
  )
}