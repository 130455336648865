export default function Description() {
  return(
    <>
      <div className="max-w-6xl h-full m-auto">
        <h3 className='text-midium font-semibold after:content-["*"] after:ml-0.5 after:text-red-500'>
          How to use it
        </h3>
        <div className="block w-full h-[calc(100%-20px)] py-2 px-4 bg-gray-50 rounded-lg text-sm border overflow-y-scroll">
          <p className="pt-2 whitespace-pre-line">
            {`※ It strongly depends on special format.

            1. What you need : Date & METAR
            2. Date format : 'YYYY-MM-DD HH:MM'
            3. Where can I get METARs : recommand `}
            <a href="https://mesonet.agron.iastate.edu/request/download.phtml?network=KR__ASOS" target="_blank" rel="noreferrer" className="text-red-600 hover:font-bold">here</a>
          </p>
          <p className="whitespace-pre-line">
            {`4. Except for dates and metar, content is filtered automatically.
              
              Sample) Just try it! Copy & Paste below (RKSI, 1month)
           2021-01-01 00:00 010000Z 14005KT 9999 -SN BKN025 M04/M06 Q1026 NOSIG
           2021-01-01 00:30 010030Z 15005KT 6000 -SN FEW014 BKN025 M03/M06 Q1027 NOSIG
           2021-01-01 01:00 010100Z 13007KT 3500 -SN BR FEW014 BKN025 M04/M07 Q1027 NOSIG
           2021-01-01 01:30 010130Z 13007KT 9000 4000SW -SN FEW018 BKN025 M04/M06 Q1027 NOSIG
           2021-01-01 02:00 010200Z 14008KT 110V170 9000 -SN FEW018 SCT030 M03/M06 Q1026 NOSIG
           2021-01-01 02:30 010230Z 15010KT 9999 SCT030 M02/M06 Q1026 NOSIG
           2021-01-01 03:00 010300Z 15009KT 9999 SCT030 M01/M06 Q1025 NOSIG
           2021-01-01 03:30 010330Z 15009KT 9999 SCT030 M01/M06 Q1025 NOSIG
           2021-01-01 04:00 010400Z 16006KT 130V190 9999 SCT030 M01/M06 Q1025 NOSIG
           2021-01-01 04:30 010430Z 16006KT 130V210 9999 FEW018 SCT030 M00/M05 Q1024 NOSIG
           2021-01-01 05:00 010500Z 18005KT 140V220 9999 SCT018 SCT030 M01/M05 Q1024 NOSIG
           2021-01-01 05:30 010530Z 18005KT 140V220 9999 SCT017 SCT030 00/M05 Q1024 NOSIG
           2021-01-01 06:00 010600Z 26004KT 170V310 9999 SCT018 BKN040 01/M05 Q1024 NOSIG
           2021-01-01 06:30 010630Z 24005KT 9999 SCT030 00/M05 Q1024 NOSIG
           2021-01-01 07:00 010700Z 24004KT 210V270 9999 SCT030 SCT070 M00/M05 Q1024 NOSIG
           2021-01-01 07:30 010730Z 23003KT 200V260 9999 SCT030 SCT070 M00/M05 Q1024 NOSIG
           2021-01-01 08:00 010800Z 23003KT CAVOK M00/M05 Q1024 NOSIG
           2021-01-01 08:30 010830Z 21002KT CAVOK M00/M05 Q1024 NOSIG
           2021-01-01 09:00 010900Z 01001KT 9999 BKN045 M00/M05 Q1024 NOSIG
           2021-01-01 09:30 010930Z 01002KT 9999 BKN045 M01/M06 Q1024 NOSIG
           2021-01-01 10:00 011000Z 34003KT 310V010 9999 BKN045 M02/M06 Q1025 NOSIG
           2021-01-01 10:30 011030Z 32007KT 9999 SCT045 M01/M08 Q1025 NOSIG
           2021-01-01 11:00 011100Z 32008KT 9999 BKN045 M02/M09 Q1025 NOSIG
           2021-01-01 11:30 011130Z 32005KT 9999 BKN045 M01/M08 Q1025 NOSIG
           2021-01-01 12:00 011200Z 32008KT 9999 BKN045 M01/M08 Q1025 NOSIG
           2021-01-01 12:30 011230Z 33011KT 9999 BKN045 M00/M10 Q1026 NOSIG
           2021-01-01 13:00 011300Z 33013KT CAVOK M02/M11 Q1026 NOSIG
           2021-01-01 13:30 011330Z 33009KT CAVOK M03/M10 Q1026 NOSIG
           2021-01-01 14:00 011400Z 35006KT 9999 FEW030 M04/M10 Q1026 NOSIG
           2021-01-01 14:30 011430Z 36007KT 9999 FEW040 M03/M11 Q1026 NOSIG
           2021-01-01 15:00 011500Z 36007KT CAVOK M03/M13 Q1026 NOSIG
           2021-01-01 15:30 011530Z 35006KT 9999 FEW045 M04/M13 Q1026 NOSIG
           2021-01-01 16:00 011600Z 01005KT 9999 FEW045 SCT070 M04/M13 Q1026 NOSIG
           2021-01-01 16:30 011630Z 02005KT 9999 FEW045 SCT070 M04/M13 Q1026 NOSIG
           2021-01-01 17:00 011700Z 36007KT 9999 FEW045 SCT070 M05/M16 Q1027 NOSIG
           2021-01-01 17:30 011730Z 36009KT CAVOK M05/M15 Q1027 NOSIG
           2021-01-01 18:00 011800Z 01007KT CAVOK M05/M15 Q1027 NOSIG
           2021-01-01 18:30 011830Z 03007KT CAVOK M06/M16 Q1027 NOSIG
           2021-01-01 19:00 011900Z 02006KT CAVOK M06/M16 Q1027 NOSIG
           2021-01-01 19:30 011930Z 04004KT CAVOK M07/M16 Q1027 NOSIG
           2021-01-01 20:00 012000Z 02006KT CAVOK M06/M16 Q1027 NOSIG
           2021-01-01 20:30 012030Z 03004KT CAVOK M07/M16 Q1027 NOSIG
           2021-01-01 21:00 012100Z 04004KT CAVOK M07/M15 Q1027 NOSIG
           2021-01-01 21:30 012130Z 36005KT CAVOK M07/M15 Q1027 NOSIG
           2021-01-01 22:00 012200Z 01003KT 340V040 CAVOK M07/M16 Q1028 NOSIG
           2021-01-01 22:30 012230Z 05003KT CAVOK M07/M17 Q1028 NOSIG
           2021-01-01 23:00 012300Z 03004KT CAVOK M07/M17 Q1028 NOSIG
           2021-01-01 23:30 012330Z 03004KT CAVOK M07/M16 Q1029 NOSIG
           2021-01-02 00:00 020000Z 03004KT CAVOK M06/M17 Q1029 NOSIG
           2021-01-02 00:30 020030Z 34005KT CAVOK M05/M16 Q1030 NOSIG
           2021-01-02 01:00 020100Z 32005KT CAVOK M04/M15 Q1030 NOSIG
           2021-01-02 01:30 020130Z 34005KT 300V360 CAVOK M04/M15 Q1030 NOSIG
           2021-01-02 02:00 020200Z 31005KT 260V360 CAVOK M03/M15 Q1030 NOSIG
           2021-01-02 02:30 020230Z 32005KT 280V360 CAVOK M03/M14 Q1030 NOSIG
           2021-01-02 03:00 020300Z 31005KT 270V030 CAVOK M03/M14 Q1030 NOSIG
           2021-01-02 03:30 020330Z 30009KT 270V330 CAVOK M03/M14 Q1030 NOSIG
           2021-01-02 04:00 020400Z 29009KT 250V320 CAVOK M02/M13 Q1030 NOSIG
           2021-01-02 04:30 020430Z 30012KT CAVOK M02/M13 Q1030 NOSIG
           2021-01-02 05:00 020500Z 32011KT 280V350 CAVOK M02/M12 Q1030 NOSIG
           2021-01-02 05:30 020530Z 32010KT CAVOK M02/M13 Q1029 NOSIG
           2021-01-02 06:00 020600Z 31010KT CAVOK M02/M14 Q1029 NOSIG
           2021-01-02 06:30 020630Z 31010KT CAVOK M02/M15 Q1029 NOSIG
           2021-01-02 07:00 020700Z 30012KT CAVOK M02/M13 Q1030 NOSIG
           2021-01-02 07:30 020730Z 31011KT CAVOK M02/M14 Q1030 NOSIG
           2021-01-02 08:00 020800Z 31010KT CAVOK M02/M14 Q1030 NOSIG
           2021-01-02 08:30 020830Z 31010KT CAVOK M03/M15 Q1030 NOSIG
           2021-01-02 09:00 020900Z 31010KT CAVOK M03/M14 Q1031 NOSIG
           2021-01-02 09:30 020930Z 31010KT CAVOK M03/M13 Q1031 NOSIG
           2021-01-02 10:00 021000Z 31011KT CAVOK M03/M14 Q1031 NOSIG
           2021-01-02 10:30 021030Z 31011KT CAVOK M03/M13 Q1031 NOSIG
           2021-01-02 11:00 021100Z 31012KT CAVOK M03/M13 Q1032 NOSIG
           2021-01-02 11:30 021130Z 31012KT CAVOK M03/M14 Q1031 NOSIG
           2021-01-02 12:00 021200Z 31012KT CAVOK M04/M15 Q1032 NOSIG
           2021-01-02 12:30 021230Z 32013KT CAVOK M04/M14 Q1032 NOSIG
           2021-01-02 13:00 021300Z 33015KT CAVOK M04/M13 Q1032 NOSIG
           2021-01-02 13:30 021330Z 33014KT CAVOK M05/M13 Q1032 NOSIG
           2021-01-02 14:00 021400Z 34014KT CAVOK M05/M12 Q1032 NOSIG
           2021-01-02 14:30 021430Z 35012KT CAVOK M05/M16 Q1032 NOSIG
           2021-01-02 15:00 021500Z 36011KT CAVOK M06/M16 Q1033 NOSIG
           2021-01-02 15:30 021530Z 36008KT CAVOK M06/M16 Q1033 NOSIG
           2021-01-02 16:00 021600Z 36006KT CAVOK M07/M16 Q1033 NOSIG
           2021-01-02 16:30 021630Z 36007KT CAVOK M06/M15 Q1033 NOSIG
           2021-01-02 17:00 021700Z 36006KT CAVOK M07/M16 Q1034 NOSIG
           2021-01-02 17:30 021730Z 01006KT CAVOK M07/M16 Q1034 NOSIG
           2021-01-02 18:00 021800Z 02003KT CAVOK M07/M16 Q1034 NOSIG
           2021-01-02 18:30 021830Z 02004KT CAVOK M07/M16 Q1034 NOSIG
           2021-01-02 19:00 021900Z 04003KT CAVOK M07/M15 Q1034 NOSIG
           2021-01-02 19:30 021930Z 07001KT CAVOK M07/M15 Q1034 NOSIG
           2021-01-02 20:00 022000Z 02001KT CAVOK M07/M15 Q1034 NOSIG
           2021-01-02 20:30 022030Z 19002KT CAVOK M08/M14 Q1034 NOSIG
           2021-01-02 21:00 022100Z 14004KT CAVOK M07/M15 Q1034 NOSIG
           2021-01-02 21:30 022130Z 16002KT CAVOK M07/M14 Q1034 NOSIG
           2021-01-02 22:00 022200Z 07001KT CAVOK M07/M15 Q1034 NOSIG
           2021-01-02 22:30 022230Z 01004KT CAVOK M06/M14 Q1035 NOSIG
           2021-01-02 23:00 022300Z 36005KT CAVOK M07/M14 Q1035 NOSIG
           2021-01-02 23:30 022330Z 36006KT CAVOK M06/M14 Q1035 NOSIG
           2021-01-03 00:00 030000Z 36006KT 9999 FEW045 M05/M14 Q1035 NOSIG
           2021-01-03 00:30 030030Z 35006KT 320V030 9999 FEW045 M04/M17 Q1035 NOSIG
           2021-01-03 01:00 030100Z 02005KT 340V100 CAVOK M04/M14 Q1035 NOSIG
           2021-01-03 01:30 030130Z 03002KT CAVOK M03/M14 Q1035 NOSIG
           2021-01-03 02:00 030200Z 32008KT 280V350 9999 SCT045 M03/M19 Q1035 NOSIG
           2021-01-03 02:30 030230Z 34008KT 300V360 9999 FEW040 M03/M19 Q1035 NOSIG
           2021-01-03 03:00 030300Z 33005KT 290V360 9999 SCT045 M03/M18 Q1035 NOSIG
           2021-01-03 03:30 030330Z 32008KT 280V350 9999 SCT045 SCT200 M03/M19 Q1034 NOSIG
           2021-01-03 04:00 030400Z 32008KT 300V360 9999 SCT040 OVC200 M02/M18 Q1034 NOSIG
           2021-01-03 04:30 030430Z 32011KT 9999 SCT030 OVC200 M02/M19 Q1034 NOSIG
           2021-01-03 05:00 030500Z 33010KT 9999 SCT030 OVC200 M03/M17 Q1034 NOSIG
           2021-01-03 05:30 030530Z 33010KT 9999 FEW025 OVC200 M03/M16 Q1034 NOSIG
           2021-01-03 06:00 030600Z 32007KT CAVOK M03/M15 Q1033 NOSIG
           2021-01-03 06:30 030630Z 31004KT CAVOK M03/M15 Q1033 NOSIG
           2021-01-03 07:00 030700Z 32005KT CAVOK M03/M14 Q1033 NOSIG
           2021-01-03 07:30 030730Z 30003KT 260V350 CAVOK M03/M14 Q1033 NOSIG
           2021-01-03 08:00 030800Z 34002KT 9999 FEW035 SCT200 M03/M14 Q1033 NOSIG
           2021-01-03 08:30 030830Z 33002KT 9999 SCT040 BKN200 M04/M13 Q1033 NOSIG
           2021-01-03 09:00 030900Z 02002KT 9999 FEW040 SCT200 M04/M13 Q1033 NOSIG
           2021-01-03 09:30 030930Z 33001KT CAVOK M04/M13 Q1033 NOSIG
           2021-01-03 10:00 031000Z 28001KT CAVOK M03/M13 Q1034 NOSIG
           2021-01-03 10:30 031030Z 26001KT CAVOK M04/M13 Q1034 NOSIG
           2021-01-03 11:00 031100Z 26003KT 220V300 9999 FEW035 M04/M14 Q1034 NOSIG
           2021-01-03 11:30 031130Z 18002KT 9999 FEW040 M04/M13 Q1034 NOSIG
           2021-01-03 12:00 031200Z 30001KT 9999 FEW035 M06/M13 Q1034 NOSIG
           2021-01-03 12:30 031230Z 13003KT 9999 SCT035 M04/M12 Q1034 NOSIG
           2021-01-03 13:00 031300Z 05001KT 9999 FEW030 M06/M13 Q1034 NOSIG
           2021-01-03 13:30 031330Z 09003KT 9999 FEW030 M06/M13 Q1034 NOSIG
           2021-01-03 14:00 031400Z 05006KT 030V090 9999 FEW030 M06/M12 Q1034 NOSIG
           2021-01-03 14:30 031430Z 05006KT CAVOK M05/M13 Q1034 NOSIG
           2021-01-03 15:00 031500Z 06006KT CAVOK M06/M13 Q1034 NOSIG
           2021-01-03 15:30 031530Z 05007KT CAVOK M05/M14 Q1034 NOSIG
           2021-01-03 16:00 031600Z 05006KT CAVOK M06/M14 Q1034 NOSIG
           2021-01-03 16:30 031630Z 05007KT CAVOK M06/M15 Q1034 NOSIG
           2021-01-03 17:00 031700Z 04005KT CAVOK M06/M15 Q1035 NOSIG
           2021-01-03 17:30 031730Z 04006KT CAVOK M07/M15 Q1034 NOSIG
           2021-01-03 18:00 031800Z 09003KT 060V140 CAVOK M06/M15 Q1034 NOSIG
           2021-01-03 18:30 031830Z 08004KT CAVOK M07/M15 Q1034 NOSIG
           2021-01-03 19:00 031900Z 11003KT 050V140 CAVOK M06/M15 Q1034 NOSIG
           2021-01-03 19:30 031930Z 09003KT 030V140 CAVOK M07/M16 Q1034 NOSIG
           2021-01-03 20:00 032000Z 07003KT 020V120 9999 FEW030 M07/M16 Q1034 NOSIG
           2021-01-03 20:30 032030Z 12003KT 080V160 9999 SCT030 M07/M17 Q1033 NOSIG
           2021-01-03 21:00 032100Z 06006KT 9999 FEW030 M07/M16 Q1033 NOSIG
           2021-01-03 21:30 032130Z 06005KT 9999 FEW030 M07/M16 Q1033 NOSIG
           2021-01-03 22:00 032200Z 08004KT 9999 FEW030 M07/M17 Q1033 NOSIG
           2021-01-03 22:30 032230Z 09002KT 9999 FEW030 M07/M16 Q1033 NOSIG
           2021-01-03 23:00 032300Z 06005KT 9999 SCT045 M07/M15 Q1033 NOSIG
           2021-01-03 23:30 032330Z 08005KT 9999 FEW045 M07/M14 Q1033 NOSIG
           2021-01-04 00:00 040000Z 04004KT CAVOK M06/M13 Q1033 NOSIG
           2021-01-04 00:30 040030Z 07003KT 050V120 9999 FEW045 SCT070 M05/M13 Q1034 NOSIG
           2021-01-04 01:00 040100Z 06005KT 9999 SCT045 SCT070 M04/M14 Q1033 NOSIG
           2021-01-04 01:30 040130Z 06006KT 9999 BKN045 M03/M14 Q1033 NOSIG
           2021-01-04 02:00 040200Z 04004KT 010V120 9999 BKN045 M03/M14 Q1033 NOSIG
           2021-01-04 02:30 040230Z 08004KT 360V150 9999 BKN045 M02/M14 Q1032 NOSIG
           2021-01-04 03:00 040300Z 02005KT 340V140 9999 SCT045 M02/M13 Q1032 NOSIG
           2021-01-04 03:30 040330Z 13006KT 070V170 9999 SCT045 BKN070 M02/M13 Q1031 NOSIG
           2021-01-04 04:00 040400Z 12003KT 9999 BKN045 M01/M13 Q1031 NOSIG
           2021-01-04 04:30 040430Z 16006KT 130V190 9999 SCT045 BKN070 M01/M12 Q1031 NOSIG
           2021-01-04 05:00 040500Z 14005KT 090V180 9999 BKN045 M01/M12 Q1030 NOSIG
           2021-01-04 05:30 040530Z 15005KT 100V190 9999 BKN040 BKN065 M01/M12 Q1030 NOSIG
           2021-01-04 06:00 040600Z 16006KT 120V190 9999 BKN040 BKN065 M00/M11 Q1030 NOSIG
           2021-01-04 06:30 040630Z 14005KT 110V190 9999 BKN040 BKN065 M00/M11 Q1030 NOSIG
           2021-01-04 07:00 040700Z 09004KT 050V130 9999 BKN040 BKN065 M00/M11 Q1030 NOSIG
           2021-01-04 07:30 040730Z 08003KT 060V120 9999 BKN040 M00/M11 Q1029 NOSIG
           2021-01-04 08:00 040800Z 12004KT 080V150 9999 BKN045 M01/M09 Q1029 NOSIG
           2021-01-04 08:30 040830Z 14005KT 9999 BKN040 M01/M08 Q1028 NOSIG
           2021-01-04 09:00 040900Z 15007KT 9999 BKN035 M01/M07 Q1028 NOSIG
           2021-01-04 09:30 040930Z 14007KT 9999 BKN030 M01/M07 Q1029 NOSIG
           2021-01-04 10:00 041000Z 12005KT 080V150 9999 BKN030 M01/M07 Q1028 NOSIG
           2021-01-04 10:30 041030Z 13006KT 9999 BKN025 M01/M07 Q1028 NOSIG
           2021-01-04 11:00 041100Z 12005KT 9999 BKN025 M01/M07 Q1028 NOSIG
           2021-01-04 11:30 041130Z 12004KT 9999 BKN045 M00/M06 Q1028 BECMG -SN
           2021-01-04 12:00 041200Z 07004KT 040V120 9999 -SN BKN035 M00/M06 Q1028 NOSIG
           2021-01-04 12:30 041230Z 02004KT 9000 -SN BKN035 M00/M06 Q1028 NOSIG
           2021-01-04 13:00 041300Z 02006KT 9000 -SN BKN030 M01/M06 Q1028 NOSIG
           2021-01-04 13:30 041330Z 02008KT 9999 -SN BKN025 M01/M08 Q1028 NOSIG
           2021-01-04 14:00 041400Z 04010KT 9999 -SN BKN025 M02/M10 Q1029 NOSIG
           2021-01-04 14:30 041430Z 04011KT 9999 -SN BKN025 M02/M09 Q1029 NOSIG
           2021-01-04 15:00 041500Z 04015KT 9999 -SN BKN025 M02/M08 Q1029 NOSIG
           2021-01-04 15:30 041530Z 02010KT 9999 -SN BKN025 M02/M08 Q1029 NOSIG
           2021-01-04 16:00 041600Z 05007KT 9999 -SN BKN025 M02/M07 Q1029 NOSIG
           2021-01-04 16:30 041630Z 04007KT 9999 -SN BKN025 M02/M07 Q1029 NOSIG
           2021-01-04 17:00 041700Z 04007KT 9999 -SN BKN030 M02/M06 Q1029 NOSIG
           2021-01-04 17:30 041730Z 03005KT 9999 BKN030 M03/M07 Q1029 NOSIG
           2021-01-04 18:00 041800Z 03004KT 9999 -SN BKN035 M03/M06 Q1029 NOSIG
           2021-01-04 18:30 041830Z 36004KT 320V020 9999 -SN BKN040 M03/M07 Q1029 NOSIG
           2021-01-04 19:00 041900Z 35009KT 9999 -SN BKN040 M03/M07 Q1029 NOSIG
           2021-01-04 19:30 041930Z 35010KT 9999 -SN BKN040 M03/M08 Q1029 NOSIG
           2021-01-04 20:00 042000Z 35010KT 9999 -SN BKN040 M03/M08 Q1029 NOSIG
           2021-01-04 20:30 042030Z 35011KT 9999 -SN FEW018 BKN040 M04/M08 Q1029 BECMG NSW
           2021-01-04 21:00 042100Z 36011KT 9999 FEW017 BKN040 M04/M08 Q1030 NOSIG
           2021-01-04 21:30 042130Z 36011KT 9999 FEW019 BKN045 M05/M10 Q1031 NOSIG
           2021-01-04 22:00 042200Z 36008KT 9999 SCT040 BKN200 M05/M10 Q1031 NOSIG
           2021-01-04 22:30 042230Z 36009KT 320V020 9999 FEW040 SCT200 M05/M11 Q1032 NOSIG
           2021-01-04 23:00 042300Z 35008KT 9999 FEW040 M06/M13 Q1032 NOSIG
           2021-01-04 23:30 042330Z 35010KT CAVOK M05/M14 Q1032 NOSIG
           2021-01-05 00:00 050000Z 36012KT CAVOK M05/M17 Q1033 NOSIG
           2021-01-05 00:30 050030Z 34015KT CAVOK M04/M18 Q1033 NOSIG
           2021-01-05 01:00 050100Z 33013KT CAVOK M04/M17 Q1033 NOSIG
           2021-01-05 01:30 050130Z 33012KT CAVOK M04/M17 Q1033 NOSIG
           2021-01-05 02:00 050200Z 32011KT CAVOK M04/M16 Q1034 NOSIG
           2021-01-05 02:30 050230Z 32014KT 9999 FEW030 M04/M16 Q1033 NOSIG
           2021-01-05 03:00 050300Z 32013KT 9999 FEW030 M04/M15 Q1033 NOSIG
           2021-01-05 03:30 050330Z 32015KT 9999 FEW030 SCT200 M04/M15 Q1032 NOSIG
           2021-01-05 04:00 050400Z 31015KT 9999 FEW030 SCT200 M04/M17 Q1031 NOSIG
           2021-01-05 04:30 050430Z 32015KT 9999 FEW030 SCT200 M03/M16 Q1031 NOSIG
           2021-01-05 05:00 050500Z 32013KT 9999 FEW030 SCT200 M04/M17 Q1031 NOSIG
           2021-01-05 05:30 050530Z 31010KT 9999 FEW030 SCT200 M05/M18 Q1030 NOSIG
           2021-01-05 06:00 050600Z 31011KT 9999 FEW030 SCT200 M04/M16 Q1030 NOSIG
           2021-01-05 06:30 050630Z 31010KT 9999 FEW030 M04/M15 Q1030 NOSIG
           2021-01-05 07:00 050700Z 31009KT 270V340 9999 FEW030 M04/M15 Q1030 NOSIG
           2021-01-05 07:30 050730Z 32008KT 9999 FEW030 M04/M15 Q1030 NOSIG
           2021-01-05 08:00 050800Z 34009KT CAVOK M05/M15 Q1029 NOSIG
           2021-01-05 08:30 050830Z 33008KT CAVOK M06/M16 Q1030 NOSIG
           2021-01-05 09:00 050900Z 33007KT 9999 FEW035 M06/M16 Q1030 NOSIG
           2021-01-05 09:30 050930Z 31007KT CAVOK M06/M16 Q1030 NOSIG
           2021-01-05 10:00 051000Z 35005KT CAVOK M05/M15 Q1031 NOSIG
           2021-01-05 10:30 051030Z 36007KT CAVOK M07/M15 Q1030 NOSIG
           2021-01-05 11:00 051100Z 36007KT CAVOK M07/M15 Q1031 NOSIG
           2021-01-05 11:30 051130Z 35007KT CAVOK M08/M15 Q1031 NOSIG
           2021-01-05 12:00 051200Z 34007KT CAVOK M08/M15 Q1031 NOSIG
           2021-01-05 12:30 051230Z 34009KT CAVOK M08/M15 Q1031 NOSIG
           2021-01-05 13:00 051300Z 34010KT CAVOK M08/M15 Q1030 NOSIG
           2021-01-05 13:30 051330Z 35009KT CAVOK M08/M15 Q1030 NOSIG
           2021-01-05 14:00 051400Z 34009KT CAVOK M09/M15 Q1030 NOSIG
           2021-01-05 14:30 051430Z 34007KT CAVOK M08/M14 Q1030 NOSIG
           2021-01-05 15:00 051500Z 34008KT CAVOK M09/M14 Q1030 NOSIG
           2021-01-05 15:30 051530Z 34008KT CAVOK M09/M15 Q1030 NOSIG
           2021-01-05 16:00 051600Z 34007KT 310V010 CAVOK M09/M15 Q1030 NOSIG
           2021-01-05 16:30 051630Z 01008KT CAVOK M09/M15 Q1030 NOSIG
           2021-01-05 17:00 051700Z 01006KT CAVOK M10/M15 Q1030 NOSIG
           2021-01-05 17:30 051730Z 02005KT CAVOK M10/M15 Q1030 NOSIG
           2021-01-05 18:00 051800Z 04003KT CAVOK M11/M16 Q1029 NOSIG
           2021-01-05 18:30 051830Z 02004KT CAVOK M10/M15 Q1029 NOSIG
           2021-01-05 19:00 051900Z 04003KT 360V080 CAVOK M10/M15 Q1029 NOSIG
           2021-01-05 19:30 051930Z 05004KT CAVOK M10/M16 Q1028 NOSIG
           2021-01-05 20:00 052000Z 07004KT CAVOK M10/M16 Q1028 NOSIG
           2021-01-05 20:30 052030Z 07003KT CAVOK M11/M16 Q1028 NOSIG
           2021-01-05 21:00 052100Z 07003KT CAVOK M11/M16 Q1028 NOSIG
           2021-01-05 21:30 052130Z 12003KT CAVOK M12/M17 Q1028 NOSIG
           2021-01-05 22:00 052200Z 10004KT CAVOK M11/M16 Q1028 NOSIG
           2021-01-05 22:30 052230Z 12004KT CAVOK M10/M17 Q1028 NOSIG
           2021-01-05 23:00 052300Z 11003KT CAVOK M11/M17 Q1028 NOSIG
           2021-01-05 23:30 052330Z 10003KT 070V130 CAVOK M09/M17 Q1028 NOSIG
           2021-01-06 00:00 060000Z 14002KT CAVOK M09/M17 Q1029 NOSIG
           2021-01-06 00:30 060030Z 12003KT 050V190 9999 FEW030 M08/M16 Q1029 NOSIG
           2021-01-06 01:00 060100Z 06003KT 020V110 9999 FEW045 M07/M15 Q1028 NOSIG
           2021-01-06 01:30 060130Z 07002KT 9999 FEW045 M06/M15 Q1028 NOSIG
           2021-01-06 02:00 060200Z 02004KT 310V070 CAVOK M06/M15 Q1028 NOSIG
           2021-01-06 02:30 060230Z 35003KT CAVOK M06/M15 Q1027 NOSIG
           2021-01-06 03:00 060300Z 36004KT 320V040 CAVOK M05/M15 Q1026 NOSIG
           2021-01-06 03:30 060330Z 34003KT 270V040 CAVOK M05/M17 Q1025 NOSIG
           2021-01-06 04:00 060400Z 35003KT 360V080 CAVOK M04/M17 Q1024 NOSIG
           2021-01-06 04:30 060430Z 06004KT 350V090 CAVOK M05/M18 Q1023 NOSIG
           2021-01-06 05:00 060500Z 09003KT CAVOK M04/M20 Q1023 NOSIG
           2021-01-06 05:30 060530Z 01003KT 330V050 CAVOK M04/M23 Q1022 NOSIG
           2021-01-06 06:00 060600Z 15002KT CAVOK M03/M22 Q1022 NOSIG
           2021-01-06 06:30 060630Z 19006KT 150V230 CAVOK M03/M18 Q1022 NOSIG
           2021-01-06 07:00 060700Z 18008KT CAVOK M04/M17 Q1021 NOSIG
           2021-01-06 07:30 060730Z 16010KT CAVOK M04/M17 Q1021 NOSIG
           2021-01-06 08:00 060800Z 16014KT 9999 SCT045 BKN070 M04/M16 Q1020 NOSIG
           2021-01-06 08:30 060830Z 19011KT 150V220 8000 -SN BKN030 OVC070 M03/M14 Q1020 NOSIG
           2021-01-06 09:00 060900Z 19013KT 0600 R15L/1700U R15R/1800U R16/P2000D SN BR SCT005 BKN025 OVC060 M05/M07 Q1019 NOSIG
           2021-01-06 09:30 060930Z 21011KT 170V240 2000 -SN BR SCT010 BKN025 OVC060 M03/M05 Q1018 NOSIG
           2021-01-06 10:00 061000Z 25020G31KT 3500 -SN BR SCT010 BKN025 OVC060 M03/M06 Q1018 WS ALL RWY NOSIG
           2021-01-06 10:30 061030Z 25019KT 200V270 2000 -SN BR SCT010 BKN025 OVC060 M02/M06 Q1017 WS ALL RWY NOSIG
           2021-01-06 11:00 061100Z 30021KT 0400 R33R/1500D R15R/0700D R34/1100D SN BR FEW008CB SCT015 BKN025 OVC060 M04/M04 Q1018 WS ALL RWY NOSIG
           2021-01-06 11:30 061130Z 30020G31KT 0600 R33R/1300U R33L/0800U R34/P2000N -SN BR SCT005 BKN025 OVC060 M04/M05 Q1018 WS ALL RWY NOSIG
           2021-01-06 12:00 061200Z 30022KT 0600 0250S R33R/P2000U R33L/1100D R34/P2000N SN BR SCT005 BKN025 OVC060 M04/M06 Q1017 WS ALL RWY NOSIG
           2021-01-06 12:30 061230Z 29015KT 0400 R33R/1700U R33L/1000N R34/P2000N SN BR SCT005 BKN025 OVC060 M05/M06 Q1017 NOSIG
           2021-01-06 13:00 061300Z 30019KT 0400 R33R/1500N R33L/0800U R34/1600D SN BR SCT005 BKN025 OVC060 M05/M07 Q1017 NOSIG
           2021-01-06 13:30 061330Z 33024KT 0250 R33R/0800D R33L/1200U R34/0800D +SN BR SCT005 BKN025 OVC060 M07/M08 Q1018 WS R34 NOSIG
           2021-01-06 14:00 061400Z 33031KT 0250 R33R/0700D R33L/P2000U R34/0700N +SN BR SCT010 BKN025 BKN060 M09/M10 Q1019 WS R16 R34 NOSIG
           2021-01-06 14:30 061430Z 33030KT 0500 R33R/P2000U R33L/P2000N R34/0600N DRSN SCT014 BKN025 BKN070 M09/M12 Q1020 WS R16 R34 NOSIG
           2021-01-06 15:00 061500Z 33026KT 2000 -DRSN SCT025 M10/M13 Q1021 WS R16 R34 NOSIG
           2021-01-06 15:30 061530Z 33024KT 8000 -DRSN SCT025 M10/M15 Q1021 NOSIG
           2021-01-06 16:00 061600Z 33027KT 4500 0450SW -SN BR SCT025 BKN070 M10/M15 Q1022 NOSIG
           2021-01-06 16:30 061630Z 34022KT 4500 0900NW -SN BR BKN025 BKN070 M10/M15 Q1023 WS R16 R34 NOSIG
           2021-01-06 17:00 061700Z 33025KT 4500 1700SW -SN BR BKN025 BKN070 M11/M17 Q1023 WS R16 R34 BECMG BR
           2021-01-06 17:30 061730Z 33025KT 4500 0800NW DRSN SCT025 BKN080 M12/M17 Q1024 WS R16 R34 NOSIG
           2021-01-06 18:00 061800Z 32025KT 4500 1000S DRSN SCT025 M12/M19 Q1024 WS ALL RWY NOSIG
           2021-01-06 18:30 061830Z 32025G39KT 2000 0700SW DRSN SCT025 M12/M19 Q1024 WS R16 R34 NOSIG
           2021-01-06 19:00 061900Z 32026KT 4500 0800NW DRSN SCT025 M13/M20 Q1025 WS R16 R34 NOSIG
           2021-01-06 19:30 061930Z 33026KT 9000 1600NW SCT025 M13/M19 Q1025 NOSIG
           2021-01-06 20:00 062000Z 32023KT 9999 SCT030 M14/M20 Q1025 WS R16 R34 NOSIG
           2021-01-06 20:30 062030Z 33025KT 9999 SCT030 M14/M21 Q1026 WS R16 R34 NOSIG
           2021-01-06 21:00 062100Z 33021KT 9999 FEW030 M14/M20 Q1026 WS R16 R34 NOSIG
           2021-01-06 21:30 062130Z 32018KT 9999 FEW030 M15/M20 Q1026 WS R16 R34 NOSIG
           2021-01-06 22:00 062200Z 32019KT 9999 FEW030 M14/M21 Q1026 WS R16 R34 NOSIG
           2021-01-06 22:30 062230Z 32019KT 9999 FEW030 M14/M21 Q1026 NOSIG
           2021-01-06 23:00 062300Z 32018KT 9999 FEW030 M15/M22 Q1027 WS R16 R34 NOSIG
           2021-01-06 23:30 062330Z 32019KT 9999 FEW030 M15/M22 Q1027 NOSIG
           2021-01-07 00:00 070000Z 33022KT 9999 FEW030 M14/M21 Q1028 NOSIG
           2021-01-07 00:30 070030Z 33019KT 9999 FEW030 M14/M21 Q1029 NOSIG
           2021-01-07 01:00 070100Z 33021KT 9999 FEW030 M14/M21 Q1029 NOSIG
           2021-01-07 01:30 070130Z 33022KT 9999 FEW030 M14/M22 Q1029 NOSIG
           2021-01-07 02:00 070200Z 33024KT 9999 FEW030 M14/M23 Q1029 NOSIG
           2021-01-07 02:30 070230Z 33022KT 9999 FEW030 M13/M23 Q1028 NOSIG
           2021-01-07 03:00 070300Z 34016KT 9999 FEW030 M13/M22 Q1028 NOSIG
           2021-01-07 03:30 070330Z 32016KT 300V360 9999 FEW030 M13/M21 Q1028 NOSIG
           2021-01-07 04:00 070400Z 33013KT 300V010 9999 FEW030 M13/M20 Q1028 NOSIG
           2021-01-07 04:30 070430Z 32015KT 9999 FEW030 M13/M21 Q1028 NOSIG
           2021-01-07 05:00 070500Z 32014KT 9999 FEW030 M13/M21 Q1027 NOSIG
           2021-01-07 05:30 070530Z 33016KT 9999 FEW030 M12/M21 Q1027 NOSIG
           2021-01-07 06:00 070600Z 32015KT 9999 FEW030 M12/M21 Q1027 NOSIG
           2021-01-07 06:30 070630Z 33014KT 9999 FEW040 M12/M20 Q1027 NOSIG
           2021-01-07 07:00 070700Z 34013KT CAVOK M12/M21 Q1027 NOSIG
           2021-01-07 07:30 070730Z 34014KT 320V020 CAVOK M12/M21 Q1027 NOSIG
           2021-01-07 08:00 070800Z 35012KT CAVOK M12/M21 Q1027 NOSIG
           2021-01-07 08:30 070830Z 35010KT CAVOK M13/M21 Q1027 NOSIG
           2021-01-07 09:00 070900Z 36010KT CAVOK M13/M21 Q1027 NOSIG
           2021-01-07 09:30 070930Z 36010KT CAVOK M13/M21 Q1027 NOSIG
           2021-01-07 10:00 071000Z 35013KT CAVOK M13/M22 Q1027 NOSIG
           2021-01-07 10:30 071030Z 35012KT CAVOK M13/M21 Q1027 NOSIG
           2021-01-07 11:00 071100Z 35011KT CAVOK M13/M21 Q1027 NOSIG
           2021-01-07 11:30 071130Z 36009KT CAVOK M13/M21 Q1027 NOSIG
           2021-01-07 12:00 071200Z 35012KT CAVOK M13/M20 Q1027 NOSIG
           2021-01-07 12:30 071230Z 36012KT CAVOK M14/M20 Q1027 NOSIG
           2021-01-07 13:00 071300Z 36012KT CAVOK M14/M20 Q1027 NOSIG
           2021-01-07 13:30 071330Z 36012KT CAVOK M14/M20 Q1027 NOSIG
           2021-01-07 14:00 071400Z 01012KT CAVOK M14/M21 Q1027 NOSIG
           2021-01-07 14:30 071430Z 01012KT CAVOK M14/M21 Q1027 NOSIG
           2021-01-07 15:00 071500Z 36016KT CAVOK M14/M21 Q1027 NOSIG
           2021-01-07 15:30 071530Z 36013KT CAVOK M15/M22 Q1027 NOSIG
           2021-01-07 16:00 071600Z 35015KT CAVOK M15/M22 Q1027 NOSIG
           2021-01-07 16:30 071630Z 34018KT CAVOK M15/M23 Q1027 NOSIG
           2021-01-07 17:00 071700Z 35015KT CAVOK M15/M23 Q1027 NOSIG
           2021-01-07 17:30 071730Z 35016KT CAVOK M16/M24 Q1027 NOSIG
           2021-01-07 18:00 071800Z 35013KT CAVOK M16/M24 Q1027 NOSIG
           2021-01-07 18:30 071830Z 35013KT CAVOK M16/M23 Q1027 NOSIG
           2021-01-07 19:00 071900Z 36010KT CAVOK M16/M24 Q1027 NOSIG
           2021-01-07 19:30 071930Z 36010KT CAVOK M16/M24 Q1027 NOSIG
           2021-01-07 20:00 072000Z 36011KT CAVOK M16/M25 Q1027 NOSIG
           2021-01-07 20:30 072030Z 36012KT CAVOK M16/M25 Q1027 NOSIG
           2021-01-07 21:00 072100Z 36009KT CAVOK M16/M24 Q1027 NOSIG
           2021-01-07 21:30 072130Z 35010KT CAVOK M16/M24 Q1027 NOSIG
           2021-01-07 22:00 072200Z 01008KT CAVOK M16/M25 Q1027 NOSIG
           2021-01-07 22:30 072230Z 36008KT CAVOK M16/M25 Q1027 NOSIG
           2021-01-07 23:00 072300Z 01005KT CAVOK M17/M25 Q1027 NOSIG
           2021-01-07 23:30 072330Z 02007KT CAVOK M16/M25 Q1027 NOSIG
           2021-01-08 00:00 080000Z 03008KT CAVOK M16/M25 Q1027 NOSIG
           2021-01-08 00:30 080030Z 03006KT 350V050 CAVOK M16/M25 Q1027 NOSIG
           2021-01-08 01:00 080100Z 01006KT 330V040 CAVOK M16/M25 Q1027 NOSIG
           2021-01-08 01:30 080130Z 01005KT 340V060 CAVOK M15/M25 Q1027 NOSIG
           2021-01-08 02:00 080200Z 36005KT 320V040 CAVOK M15/M24 Q1027 NOSIG
           2021-01-08 02:30 080230Z 36007KT 320V060 CAVOK M14/M23 Q1026 NOSIG
           2021-01-08 03:00 080300Z 36009KT 320V030 CAVOK M13/M23 Q1026 NOSIG
           2021-01-08 03:30 080330Z 01011KT 330V030 CAVOK M13/M23 Q1025 NOSIG
           2021-01-08 04:00 080400Z 36010KT 320V020 CAVOK M12/M22 Q1025 NOSIG
           2021-01-08 04:30 080430Z 34010KT CAVOK M12/M22 Q1024 NOSIG
           2021-01-08 05:00 080500Z 35010KT 310V010 CAVOK M12/M21 Q1024 NOSIG
           2021-01-08 05:30 080530Z 36010KT CAVOK M12/M21 Q1024 NOSIG
           2021-01-08 06:00 080600Z 34014KT CAVOK M11/M20 Q1023 NOSIG
           2021-01-08 06:30 080630Z 34013KT CAVOK M11/M20 Q1023 NOSIG
           2021-01-08 07:00 080700Z 34012KT CAVOK M11/M19 Q1023 NOSIG
           2021-01-08 07:30 080730Z 34011KT 320V020 CAVOK M11/M20 Q1023 NOSIG
           2021-01-08 08:00 080800Z 35010KT 320V020 CAVOK M11/M20 Q1023 NOSIG
           2021-01-08 08:30 080830Z 34011KT CAVOK M12/M20 Q1023 NOSIG
           2021-01-08 09:00 080900Z 35008KT CAVOK M12/M19 Q1024 NOSIG
           2021-01-08 09:30 080930Z 35012KT CAVOK M12/M21 Q1024 NOSIG
           2021-01-08 10:00 081000Z 36008KT CAVOK M12/M21 Q1024 NOSIG
           2021-01-08 10:30 081030Z 34011KT CAVOK M13/M20 Q1024 NOSIG
           2021-01-08 11:00 081100Z 36009KT CAVOK M13/M20 Q1025 NOSIG
           2021-01-08 11:30 081130Z 36009KT CAVOK M13/M19 Q1025 NOSIG
           2021-01-08 12:00 081200Z 36010KT CAVOK M13/M19 Q1025 NOSIG
           2021-01-08 12:30 081230Z 36010KT CAVOK M13/M19 Q1025 NOSIG
           2021-01-08 13:00 081300Z 35012KT CAVOK M13/M20 Q1025 NOSIG
           2021-01-08 13:30 081330Z 36011KT CAVOK M13/M21 Q1025 NOSIG
           2021-01-08 14:00 081400Z 03007KT CAVOK M14/M22 Q1025 NOSIG
           2021-01-08 14:30 081430Z 03010KT CAVOK M14/M22 Q1025 NOSIG
           2021-01-08 15:00 081500Z 03009KT CAVOK M14/M23 Q1025 NOSIG
           2021-01-08 15:30 081530Z 03009KT CAVOK M14/M23 Q1025 NOSIG
           2021-01-08 16:00 081600Z 03008KT CAVOK M15/M22 Q1025 NOSIG
           2021-01-08 16:30 081630Z 03006KT CAVOK M15/M22 Q1025 NOSIG
           2021-01-08 17:00 081700Z 01006KT CAVOK M14/M23 Q1026 NOSIG
           2021-01-08 17:30 081730Z 01005KT CAVOK M14/M23 Q1025 NOSIG
           2021-01-08 18:00 081800Z 36009KT CAVOK M14/M23 Q1025 NOSIG
           2021-01-08 18:30 081830Z 35007KT CAVOK M14/M23 Q1025 NOSIG
           2021-01-08 19:00 081900Z 36008KT CAVOK M14/M22 Q1025 NOSIG
           2021-01-08 19:30 081930Z 36008KT CAVOK M14/M22 Q1025 NOSIG
           2021-01-08 20:00 082000Z 36009KT CAVOK M13/M23 Q1025 NOSIG
           2021-01-08 20:30 082030Z 01007KT CAVOK M13/M23 Q1025 NOSIG
           2021-01-08 21:00 082100Z 01006KT CAVOK M14/M24 Q1025 NOSIG
           2021-01-08 21:30 082130Z 03006KT CAVOK M14/M24 Q1024 NOSIG
           2021-01-08 22:00 082200Z 09004KT 060V120 CAVOK M14/M24 Q1024 NOSIG
           2021-01-08 22:30 082230Z 06004KT 020V090 CAVOK M15/M24 Q1024 NOSIG
           2021-01-08 23:00 082300Z 05001KT CAVOK M14/M23 Q1024 NOSIG
           2021-01-08 23:30 082330Z 04007KT 020V080 CAVOK M14/M23 Q1025 NOSIG
           2021-01-09 00:00 090000Z 04007KT 9999 FEW035 M13/M22 Q1025 NOSIG
           2021-01-09 00:30 090030Z 04005KT 9999 FEW035 M12/M22 Q1025 NOSIG
           2021-01-09 01:00 090100Z 03006KT 9999 FEW035 M12/M22 Q1025 NOSIG
           2021-01-09 01:30 090130Z 05004KT 360V070 9999 FEW035 M12/M23 Q1026 NOSIG
           2021-01-09 02:00 090200Z 03003KT 360V080 9999 FEW040 M11/M22 Q1026 NOSIG
           2021-01-09 02:30 090230Z 04002KT CAVOK M11/M21 Q1026 NOSIG
           2021-01-09 03:00 090300Z 09003KT CAVOK M10/M21 Q1025 NOSIG
           2021-01-09 03:30 090330Z 33008KT 300V360 CAVOK M09/M20 Q1025 NOSIG
           2021-01-09 04:00 090400Z 33013KT CAVOK M09/M19 Q1025 NOSIG
           2021-01-09 04:30 090430Z 33011KT 300V030 CAVOK M09/M18 Q1024 NOSIG
           2021-01-09 05:00 090500Z 33015KT CAVOK M09/M18 Q1024 NOSIG
           2021-01-09 05:30 090530Z 32012KT 300V360 CAVOK M09/M18 Q1024 NOSIG
           2021-01-09 06:00 090600Z 32009KT CAVOK M08/M18 Q1024 NOSIG
           2021-01-09 06:30 090630Z 32011KT CAVOK M08/M18 Q1024 NOSIG
           2021-01-09 07:00 090700Z 31012KT CAVOK M08/M17 Q1024 NOSIG
           2021-01-09 07:30 090730Z 31013KT CAVOK M08/M17 Q1024 NOSIG
           2021-01-09 08:00 090800Z 32011KT CAVOK M08/M17 Q1024 NOSIG
           2021-01-09 08:30 090830Z 32014KT CAVOK M09/M17 Q1024 NOSIG
           2021-01-09 09:00 090900Z 31011KT 9999 FEW030 M09/M17 Q1025 NOSIG
           2021-01-09 09:30 090930Z 31013KT 9999 FEW030 M09/M17 Q1025 NOSIG
           2021-01-09 10:00 091000Z 32015KT CAVOK M09/M16 Q1025 NOSIG
           2021-01-09 10:30 091030Z 34013KT CAVOK M09/M16 Q1026 NOSIG
           2021-01-09 11:00 091100Z 35010KT CAVOK M09/M17 Q1026 NOSIG
           2021-01-09 11:30 091130Z 35008KT CAVOK M09/M17 Q1026 NOSIG
           2021-01-09 12:00 091200Z 36005KT CAVOK M10/M17 Q1027 NOSIG
           2021-01-09 12:30 091230Z 36007KT CAVOK M10/M17 Q1027 NOSIG
           2021-01-09 13:00 091300Z 01008KT CAVOK M11/M17 Q1027 NOSIG
           2021-01-09 13:30 091330Z 05006KT CAVOK M10/M17 Q1027 NOSIG
           2021-01-09 14:00 091400Z 17001KT CAVOK M11/M18 Q1027 NOSIG
           2021-01-09 14:30 091430Z 04002KT CAVOK M11/M17 Q1027 NOSIG
           2021-01-09 15:00 091500Z 04005KT CAVOK M11/M17 Q1027 NOSIG
           2021-01-09 15:30 091530Z 07003KT 040V110 CAVOK M11/M17 Q1027 NOSIG
           2021-01-09 16:00 091600Z 12003KT CAVOK M12/M17 Q1027 NOSIG
           2021-01-09 16:30 091630Z 10003KT CAVOK M11/M18 Q1027 NOSIG
           2021-01-09 17:00 091700Z 18003KT CAVOK M11/M18 Q1028 NOSIG
           2021-01-09 17:30 091730Z 12002KT CAVOK M12/M18 Q1028 NOSIG
           2021-01-09 18:00 091800Z 15004KT CAVOK M12/M18 Q1028 NOSIG
           2021-01-09 18:30 091830Z 15003KT CAVOK M12/M18 Q1027 NOSIG
           2021-01-09 19:00 091900Z 16002KT CAVOK M13/M18 Q1028 NOSIG
           2021-01-09 19:30 091930Z 11001KT CAVOK M11/M18 Q1028 NOSIG
           2021-01-09 20:00 092000Z 14002KT CAVOK M12/M18 Q1027 NOSIG
           2021-01-09 20:30 092030Z 16002KT CAVOK M11/M18 Q1027 NOSIG
           2021-01-09 21:00 092100Z 14002KT CAVOK M12/M18 Q1027 NOSIG
           2021-01-09 21:30 092130Z 13003KT CAVOK M12/M18 Q1027 NOSIG
           2021-01-09 22:00 092200Z 12002KT CAVOK M13/M18 Q1028 NOSIG
           2021-01-09 22:30 092230Z 07002KT CAVOK M12/M18 Q1028 NOSIG
           2021-01-09 23:00 092300Z 15002KT CAVOK M12/M17 Q1028 NOSIG
           2021-01-09 23:30 092330Z 12002KT CAVOK M11/M17 Q1028 NOSIG
           2021-01-10 00:00 100000Z 08004KT CAVOK M10/M16 Q1028 NOSIG
           2021-01-10 00:30 100030Z 10005KT 070V140 CAVOK M08/M16 Q1028 NOSIG
           2021-01-10 01:00 100100Z 12004KT 070V160 CAVOK M07/M16 Q1028 NOSIG
           2021-01-10 01:30 100130Z 12006KT 090V150 CAVOK M07/M15 Q1028 NOSIG
           2021-01-10 02:00 100200Z 14007KT CAVOK M06/M15 Q1028 NOSIG
           2021-01-10 02:30 100230Z 15008KT CAVOK M07/M14 Q1028 NOSIG
           2021-01-10 03:00 100300Z 16009KT CAVOK M07/M14 Q1028 NOSIG
           2021-01-10 03:30 100330Z 16007KT 130V200 CAVOK M06/M15 Q1027 NOSIG
           2021-01-10 04:00 100400Z 15005KT 090V200 9999 SCT045 BKN070 M06/M15 Q1027 NOSIG
           2021-01-10 04:30 100430Z 16005KT 120V200 9999 SCT045 SCT070 M05/M15 Q1027 NOSIG
           2021-01-10 05:00 100500Z 15003KT 9999 SCT045 SCT070 M05/M14 Q1026 NOSIG
           2021-01-10 05:30 100530Z 18003KT 110V230 9999 SCT045 BKN070 M05/M14 Q1026 NOSIG
           2021-01-10 06:00 100600Z 16004KT 110V210 9999 BKN040 BKN070 M05/M14 Q1026 NOSIG
           2021-01-10 06:30 100630Z 19003KT 9999 BKN045 BKN070 M04/M14 Q1026 NOSIG
           2021-01-10 07:00 100700Z 06006KT 010V100 9999 -SN BKN045 BKN070 M04/M13 Q1027 NOSIG
           2021-01-10 07:30 100730Z 05005KT 020V100 9999 -SN BKN045 M05/M11 Q1027 NOSIG
           2021-01-10 08:00 100800Z 05005KT 9999 SCT035 BKN070 M05/M12 Q1027 NOSIG
           2021-01-10 08:30 100830Z 05004KT 9999 FEW045 BKN070 M05/M13 Q1027 NOSIG
           2021-01-10 09:00 100900Z 05005KT 9999 -SN BKN045 BKN070 M05/M13 Q1027 NOSIG
           2021-01-10 09:30 100930Z 05004KT 9999 -SN BKN045 BKN070 M05/M13 Q1028 NOSIG
           2021-01-10 10:00 101000Z 06001KT 9999 -SN BKN045 BKN070 M05/M13 Q1028 NOSIG
           2021-01-10 10:30 101030Z 20001KT 9999 -SN BKN045 BKN070 M05/M13 Q1029 NOSIG
           2021-01-10 11:00 101100Z 30001KT 9999 -SN BKN045 BKN070 M05/M13 Q1029 NOSIG
           2021-01-10 11:30 101130Z 28001KT 9999 BKN045 BKN070 M05/M13 Q1029 NOSIG
           2021-01-10 12:00 101200Z 21001KT 9999 BKN045 BKN070 M05/M13 Q1029 NOSIG
           2021-01-10 12:30 101230Z 34001KT 9999 SCT045 M06/M13 Q1029 NOSIG
           2021-01-10 13:00 101300Z 30001KT 9999 SCT045 M08/M14 Q1029 NOSIG
           2021-01-10 13:30 101330Z 29003KT CAVOK M07/M13 Q1030 NOSIG
           2021-01-10 14:00 101400Z 32005KT CAVOK M05/M10 Q1030 NOSIG
           2021-01-10 14:30 101430Z 34005KT CAVOK M08/M10 Q1030 NOSIG
           2021-01-10 15:00 101500Z 01006KT CAVOK M07/M10 Q1030 NOSIG
           2021-01-10 15:30 101530Z 36007KT CAVOK M07/M12 Q1030 NOSIG
           2021-01-10 16:00 101600Z 35008KT CAVOK M07/M12 Q1030 NOSIG
           2021-01-10 16:30 101630Z 34008KT CAVOK M07/M13 Q1030 NOSIG
           2021-01-10 17:00 101700Z 36006KT CAVOK M07/M12 Q1030 NOSIG
           2021-01-10 17:30 101730Z 36007KT CAVOK M08/M13 Q1030 NOSIG
           2021-01-10 18:00 101800Z 02006KT CAVOK M09/M14 Q1030 NOSIG
           2021-01-10 18:30 101830Z 04006KT CAVOK M08/M14 Q1029 NOSIG
           2021-01-10 19:00 101900Z 01005KT CAVOK M07/M13 Q1029 NOSIG
           2021-01-10 19:30 101930Z 03003KT CAVOK M08/M14 Q1029 NOSIG
           2021-01-10 20:00 102000Z 14002KT CAVOK M08/M14 Q1029 NOSIG
           2021-01-10 20:30 102030Z 28003KT 230V310 CAVOK M08/M15 Q1029 NOSIG
           2021-01-10 21:00 102100Z 08003KT 030V130 CAVOK M08/M14 Q1029 NOSIG
           2021-01-10 21:30 102130Z 13002KT CAVOK M09/M14 Q1030 NOSIG
           2021-01-10 22:00 102200Z 12002KT CAVOK M09/M14 Q1029 NOSIG
           2021-01-10 22:30 102230Z 09002KT CAVOK M08/M14 Q1029 NOSIG
           2021-01-10 23:00 102300Z 06004KT CAVOK M10/M14 Q1029 NOSIG
           2021-01-10 23:30 102330Z 09004KT CAVOK M08/M14 Q1028 NOSIG
           2021-01-11 00:00 110000Z 08003KT CAVOK M08/M13 Q1029 NOSIG
           2021-01-11 00:30 110030Z 06002KT CAVOK M07/M13 Q1029 NOSIG
           2021-01-11 01:00 110100Z 04003KT CAVOK M06/M13 Q1029 NOSIG
           2021-01-11 01:30 110130Z 04002KT CAVOK M06/M12 Q1029 NOSIG
           2021-01-11 02:00 110200Z 09004KT 060V140 CAVOK M05/M12 Q1029 NOSIG
           2021-01-11 02:30 110230Z 12004KT 090V150 CAVOK M05/M13 Q1028 NOSIG
           2021-01-11 03:00 110300Z 14003KT 090V250 CAVOK M05/M13 Q1028 NOSIG
           2021-01-11 03:30 110330Z 15002KT CAVOK M04/M12 Q1027 NOSIG
           2021-01-11 04:00 110400Z 11003KT 060V140 CAVOK M04/M13 Q1026 NOSIG
           2021-01-11 04:30 110430Z 05005KT CAVOK M04/M13 Q1026 NOSIG
           2021-01-11 05:00 110500Z 01003KT 310V090 CAVOK M04/M13 Q1026 NOSIG
           2021-01-11 05:30 110530Z 04004KT 010V070 CAVOK M04/M14 Q1025 NOSIG
           2021-01-11 06:00 110600Z 36003KT 330V040 CAVOK M04/M12 Q1025 NOSIG
           2021-01-11 06:30 110630Z 02002KT 9999 FEW040 SCT110 OVC160 M04/M13 Q1024 NOSIG
           2021-01-11 07:00 110700Z 05001KT 9999 FEW040 SCT110 OVC160 M04/M12 Q1024 NOSIG
           2021-01-11 07:30 110730Z 16002KT 9999 FEW040 BKN110 OVC160 M04/M13 Q1024 NOSIG
           2021-01-11 08:00 110800Z 29002KT 9999 SCT040 BKN110 BKN160 M04/M13 Q1024 NOSIG
           2021-01-11 08:30 110830Z 34001KT 9999 SCT045 BKN110 BKN160 M04/M13 Q1024 NOSIG
           2021-01-11 09:00 110900Z 27004KT 9999 SCT045 BKN110 M04/M13 Q1024 NOSIG
           2021-01-11 09:30 110930Z 25002KT 9999 SCT045 BKN110 M04/M13 Q1024 NOSIG
           2021-01-11 10:00 111000Z 00000KT 9999 -SN SCT040 BKN110 M05/M13 Q1024 NOSIG
           2021-01-11 10:30 111030Z 36002KT 9999 -SN SCT035 BKN110 M06/M13 Q1024 NOSIG
           2021-01-11 11:00 111100Z 06003KT 9999 -SN SCT035 BKN110 M05/M13 Q1024 NOSIG
           2021-01-11 11:30 111130Z 02003KT 9999 -SN SCT040 BKN070 M05/M12 Q1024 NOSIG
           2021-01-11 12:00 111200Z 05003KT 9999 -SN SCT040 BKN070 M05/M12 Q1024 NOSIG
           2021-01-11 12:30 111230Z 05002KT 9999 -SN SCT040 BKN070 M05/M12 Q1024 NOSIG
           2021-01-11 13:00 111300Z 18001KT 9999 -SN SCT035 BKN070 M05/M13 Q1024 NOSIG
           2021-01-11 13:30 111330Z 18004KT 9999 -SN SCT035 BKN070 M05/M12 Q1023 NOSIG
           2021-01-11 14:00 111400Z 18004KT 9999 -SN SCT035 BKN070 M04/M12 Q1023 NOSIG
           2021-01-11 14:30 111430Z 20004KT 9999 -SN SCT035 BKN070 M04/M10 Q1023 NOSIG
           2021-01-11 15:00 111500Z 19004KT 9999 -SN SCT035 BKN070 M04/M09 Q1023 NOSIG
           2021-01-11 15:30 111530Z 20005KT 9000 -SN SCT035 BKN070 M04/M07 Q1022 NOSIG
           2021-01-11 16:00 111600Z 21003KT 8000 -SN SCT040 BKN070 M04/M07 Q1022 BECMG NSW
           2021-01-11 16:30 111630Z 10001KT 8000 -SN SCT040 BKN070 M05/M08 Q1022 NOSIG
           2021-01-11 17:00 111700Z 05003KT 9999 SCT040 BKN070 M06/M08 Q1021 BECMG SCT040
           2021-01-11 17:30 111730Z 03003KT 8000 SCT040 BKN070 M06/M08 Q1021 BECMG SCT040
           2021-01-11 18:00 111800Z 03003KT 8000 FEW012 SCT070 M07/M09 Q1021 NOSIG
           2021-01-11 18:30 111830Z 04003KT 8000 FEW012 SCT070 M08/M09 Q1021 NOSIG
           2021-01-11 19:00 111900Z 08003KT 360V130 8000 NSC M08/M10 Q1020 NOSIG
           2021-01-11 19:30 111930Z 10003KT 8000 FEW013 M07/M10 Q1020 NOSIG
           2021-01-11 20:00 112000Z 11005KT 8000 SCT014 M07/M10 Q1020 NOSIG
           2021-01-11 20:30 112030Z 11003KT 8000 SCT014 M06/M08 Q1019 NOSIG
           2021-01-11 21:00 112100Z 12005KT 8000 -SN SCT012 BKN025 M05/M07 Q1019 NOSIG
           2021-01-11 21:30 112130Z 12005KT 7000 -SN SCT012 BKN025 M05/M07 Q1019 NOSIG
           2021-01-11 22:00 112200Z 14009KT 5000 -SN BR SCT010 BKN025 M05/M07 Q1019 NOSIG
           2021-01-11 22:30 112230Z 15009KT 5000 -SN BR SCT010 BKN025 M06/M07 Q1019 NOSIG
           2021-01-11 23:00 112300Z 15010KT 4500 BR SCT009 BKN025 M05/M07 Q1019 NOSIG
           2021-01-11 23:30 112330Z 15011KT 2800 BR SCT006 BKN025 M05/M06 Q1019 NOSIG
           2021-01-12 00:00 120000Z 15011KT 3500 BR SCT006 BKN025 M05/M06 Q1019 NOSIG
           2021-01-12 00:30 120030Z 15012KT 4000 -SN BR FEW006 BKN025 M04/M06 Q1019 NOSIG
           2021-01-12 01:00 120100Z 15012KT 4800 BR BKN025 M04/M06 Q1019 NOSIG
           2021-01-12 01:30 120130Z 15013KT 5000 BR BKN040 M03/M05 Q1019 NOSIG
           2021-01-12 02:00 120200Z 15013KT 5000 BR BKN040 M02/M04 Q1018 NOSIG
           2021-01-12 02:30 120230Z 15012KT 6000 BKN040 M02/M05 Q1018 NOSIG
           2021-01-12 03:00 120300Z 16013KT 6000 BKN040 M01/M05 Q1017 BECMG -SN
           2021-01-12 03:30 120330Z 15013KT 6000 -SN BKN030 M00/M03 Q1017 NOSIG
           2021-01-12 04:00 120400Z 16010KT 3500 1500NW -SN BR FEW010 BKN030 M01/M02 Q1016 NOSIG
           2021-01-12 04:30 120430Z 17011KT 2700 -SN BR FEW010 BKN030 M01/M01 Q1016 NOSIG
           2021-01-12 05:00 120500Z 17008KT 2700 1200S -SN BR FEW010 BKN030 M00/M01 Q1015 NOSIG
           2021-01-12 05:30 120530Z 24006KT 200V270 0800 R15L/0800N R15R/0800N R16/0650D SN BR FEW008 BKN030 M00/M01 Q1015 NOSIG
           2021-01-12 06:00 120600Z 25006KT 220V320 2500 -SN FEW008 BKN030 M00/M01 Q1015 NOSIG
           2021-01-12 06:30 120630Z 21006KT 170V250 2500 1200S -SN BR FEW007 BKN030 00/M00 Q1015 NOSIG
           2021-01-12 07:00 120700Z 22007KT 180V240 3500 -SN BR FEW008 BKN030 00/M00 Q1015 NOSIG
           2021-01-12 07:30 120730Z 27007KT 210V300 7000 FEW010 BKN040 BKN180 M00/M01 Q1015 NOSIG
           2021-01-12 08:00 120800Z 29006KT 8000 BKN040 BKN180 01/M04 Q1014 NOSIG
           2021-01-12 08:30 120830Z 26006KT 9999 BKN040 BKN180 01/M05 Q1015 TEMPO -SN
           2021-01-12 09:00 120900Z 25004KT 210V350 9999 BKN040 01/M05 Q1015 NOSIG
           2021-01-12 09:30 120930Z 23003KT 9999 BKN040 01/M05 Q1015 NOSIG
           2021-01-12 10:00 121000Z 25003KT 180V300 9999 BKN040 01/M04 Q1015 NOSIG
           2021-01-12 10:30 121030Z 23004KT 180V260 9999 BKN040 01/M04 Q1015 NOSIG
           2021-01-12 11:00 121100Z 11001KT 9999 BKN040 M03/M05 Q1015 NOSIG
           2021-01-12 11:30 121130Z 14003KT 9999 BKN040 M04/M07 Q1015 NOSIG
           2021-01-12 12:00 121200Z 14005KT 9999 SCT040 M02/M04 Q1015 NOSIG
           2021-01-12 12:30 121230Z 12008KT 6000 2000SE NSC M02/M03 Q1015 NOSIG
           2021-01-12 13:00 121300Z 15009KT 2000 0900SE -SN PRFG FEW002 BKN050 M02/M02 Q1015 NOSIG
           2021-01-12 13:30 121330Z 18007KT 140V220 6000 -SN FEW002 BKN040 00/M03 Q1015 BECMG NSW
           2021-01-12 14:00 121400Z 12006KT 5000 2000SE BR NSC M01/M02 Q1015 NOSIG
           2021-01-12 14:30 121430Z 15010KT 2000 BR NSC M02/M02 Q1015 NOSIG
           2021-01-12 15:00 121500Z 14009KT 2500 BR NSC M02/M03 Q1015 NOSIG
           2021-01-12 15:30 121530Z 14010KT 120V180 2500 BR NSC M02/M03 Q1015 NOSIG
           2021-01-12 16:00 121600Z 15009KT 3000 BR NSC M01/M02 Q1015 NOSIG
           2021-01-12 16:30 121630Z 15007KT 3000 BR NSC M02/M03 Q1015 NOSIG
           2021-01-12 17:00 121700Z 14009KT 3000 BR NSC M02/M03 Q1015 NOSIG
           2021-01-12 17:30 121730Z 14009KT 3000 BR NSC M02/M03 Q1015 NOSIG
           2021-01-12 18:00 121800Z 13008KT 3000 BR NSC M01/M02 Q1015 NOSIG
           2021-01-12 18:30 121830Z 13008KT 080V170 3500 BR SCT048 M03/M04 Q1014 NOSIG
           2021-01-12 19:00 121900Z 15007KT 120V180 3500 BR SCT048 M03/M04 Q1014 NOSIG
           2021-01-12 19:30 121930Z 14007KT 3500 BR SCT048 M03/M04 Q1014 NOSIG
           2021-01-12 20:00 122000Z 15010KT 4000 BR SCT045 M01/M02 Q1014 NOSIG
           2021-01-12 20:30 122030Z 14010KT 4000 BR SCT045 M02/M03 Q1014 NOSIG
           2021-01-12 21:00 122100Z 14009KT 3500 BR SCT045 M02/M03 Q1013 NOSIG
           2021-01-12 21:30 122130Z 14009KT 3500 BR SCT045 M02/M03 Q1014 NOSIG
           2021-01-12 22:00 122200Z 14008KT 4000 BR SCT044 M02/M03 Q1013 NOSIG
           2021-01-12 22:30 122230Z 14009KT 4000 BR SCT044 M03/M04 Q1014 NOSIG
           2021-01-12 23:00 122300Z 14010KT 4500 BR SCT044 M02/M03 Q1014 BECMG 6000 NSW
           2021-01-12 23:30 122330Z 14009KT 4500 BR SCT044 M02/M03 Q1014 BECMG 6000 NSW
           2021-01-13 00:00 130000Z 14011KT 5000 BR SCT035 M01/M02 Q1014 NOSIG
           2021-01-13 00:30 130030Z 14010KT 5000 BR FEW040 M00/M02 Q1014 NOSIG
           2021-01-13 01:00 130100Z 15010KT 5000 BR FEW040 00/M01 Q1014 NOSIG
           2021-01-13 01:30 130130Z 15010KT 6000 FEW040 01/M01 Q1014 NOSIG
           2021-01-13 02:00 130200Z 15012KT 6000 FEW040 02/M00 Q1014 NOSIG
           2021-01-13 02:30 130230Z 15011KT 7000 FEW035 03/01 Q1014 NOSIG
           2021-01-13 03:00 130300Z 17011KT 7000 FEW035 04/01 Q1013 NOSIG
           2021-01-13 03:30 130330Z 17010KT 8000 FEW035 05/02 Q1013 NOSIG
           2021-01-13 04:00 130400Z 17010KT 8000 FEW035 06/02 Q1013 NOSIG
           2021-01-13 04:30 130430Z 17009KT 9000 FEW035 07/03 Q1012 NOSIG
           2021-01-13 05:00 130500Z 20011KT 170V230 8000 NSC 09/03 Q1012 NOSIG
           2021-01-13 05:30 130530Z 22012KT 8000 NSC 09/03 Q1012 NOSIG
           2021-01-13 06:00 130600Z 22014KT 8000 NSC 08/03 Q1012 NOSIG
           2021-01-13 06:30 130630Z 23013KT 9000 SCT035 08/03 Q1012 NOSIG
           2021-01-13 07:00 130700Z 23012KT 9000 SCT030 07/04 Q1013 NOSIG
           2021-01-13 07:30 130730Z 23013KT 9000 SCT020 07/03 Q1013 NOSIG
           2021-01-13 08:00 130800Z 25009KT 8000 FEW030 07/04 Q1013 NOSIG
           2021-01-13 08:30 130830Z 26009KT 8000 NSC 06/03 Q1014 NOSIG
           2021-01-13 09:00 130900Z 26009KT 7000 NSC 05/03 Q1014 NOSIG
           2021-01-13 09:30 130930Z 25005KT 6000 NSC 04/02 Q1014 NOSIG
           2021-01-13 10:00 131000Z 28007KT 6000 NSC 04/01 Q1015 NOSIG
           2021-01-13 10:30 131030Z 28008KT 6000 NSC 04/01 Q1015 NOSIG
           2021-01-13 11:00 131100Z 31006KT 6000 NSC 02/00 Q1016 NOSIG
           2021-01-13 11:30 131130Z 33009KT 5000 DU BR NSC 02/M01 Q1017 NOSIG
           2021-01-13 12:00 131200Z 33007KT 5000 DU BR NSC 00/M02 Q1017 NOSIG
           2021-01-13 12:30 131230Z 31006KT 5000 DU BR NSC 00/M03 Q1018 NOSIG
           2021-01-13 13:00 131300Z 33010KT 8000 NSC 01/M05 Q1018 NOSIG
           2021-01-13 13:30 131330Z 33012KT 9000 NSC M00/M05 Q1019 NOSIG
           2021-01-13 14:00 131400Z 33010KT 9000 NSC M00/M07 Q1019 NOSIG
           2021-01-13 14:30 131430Z 32011KT 9000 NSC M01/M05 Q1020 NOSIG
           2021-01-13 15:00 131500Z 32011KT 9000 NSC M01/M06 Q1020 NOSIG
           2021-01-13 15:30 131530Z 33011KT 9000 NSC M01/M06 Q1021 NOSIG
           2021-01-13 16:00 131600Z 34008KT 9000 NSC M03/M06 Q1021 NOSIG
           2021-01-13 16:30 131630Z 35008KT 9000 NSC M02/M07 Q1021 NOSIG
           2021-01-13 17:00 131700Z 36007KT 9000 NSC M02/M07 Q1021 NOSIG
           2021-01-13 17:30 131730Z 27001KT 9000 NSC M03/M07 Q1021 NOSIG
           2021-01-13 18:00 131800Z 10001KT 9000 NSC M02/M08 Q1021 NOSIG
           2021-01-13 18:30 131830Z 21005KT 160V230 CAVOK M03/M07 Q1022 NOSIG
           2021-01-13 19:00 131900Z 24002KT CAVOK M03/M07 Q1022 NOSIG
           2021-01-13 19:30 131930Z 36002KT CAVOK M02/M09 Q1022 NOSIG
           2021-01-13 20:00 132000Z 33003KT 300V010 CAVOK M02/M09 Q1023 NOSIG
           2021-01-13 20:30 132030Z 29003KT CAVOK M03/M09 Q1023 NOSIG
           2021-01-13 21:00 132100Z 32006KT CAVOK M03/M08 Q1023 NOSIG
           2021-01-13 21:30 132130Z 01002KT CAVOK M04/M09 Q1023 NOSIG
           2021-01-13 22:00 132200Z 34004KT CAVOK M03/M09 Q1023 NOSIG
           2021-01-13 22:30 132230Z 04003KT 010V070 CAVOK M04/M09 Q1024 NOSIG
           2021-01-13 23:00 132300Z 03004KT CAVOK M04/M09 Q1024 NOSIG
           2021-01-13 23:30 132330Z 05004KT CAVOK M04/M09 Q1024 NOSIG
           2021-01-14 00:00 140000Z 04007KT CAVOK M02/M09 Q1024 NOSIG
           2021-01-14 00:30 140030Z 05007KT CAVOK M01/M09 Q1024 NOSIG
           2021-01-14 01:00 140100Z 05006KT CAVOK M00/M10 Q1024 NOSIG
           2021-01-14 01:30 140130Z 06005KT CAVOK 01/M12 Q1024 NOSIG
           2021-01-14 02:00 140200Z 06005KT 030V110 CAVOK 02/M10 Q1024 NOSIG
           2021-01-14 02:30 140230Z 03007KT CAVOK 03/M10 Q1023 NOSIG
           2021-01-14 03:00 140300Z 03007KT CAVOK 03/M12 Q1023 NOSIG
           2021-01-14 03:30 140330Z 04006KT 030V090 CAVOK 04/M11 Q1023 NOSIG
           2021-01-14 04:00 140400Z 13006KT 050V170 CAVOK 04/M11 Q1022 NOSIG
           2021-01-14 04:30 140430Z 16006KT 120V190 CAVOK 05/M10 Q1022 NOSIG
           2021-01-14 05:00 140500Z 13002KT CAVOK 05/M11 Q1021 NOSIG
           2021-01-14 05:30COR  140530Z 05002KT CAVOK 06/M13 Q1021 NOSIG
           2021-01-14 06:00 140600Z 02002KT CAVOK 06/M12 Q1021 NOSIG
           2021-01-14 06:30 140630Z 15005KT 130V200 CAVOK 06/M11 Q1021 NOSIG
           2021-01-14 07:00 140700Z 04003KT 360V160 CAVOK 06/M11 Q1020 NOSIG
           2021-01-14 07:30 140730Z 02003KT CAVOK 06/M11 Q1020 NOSIG
           2021-01-14 08:00 140800Z 05003KT CAVOK 05/M10 Q1020 NOSIG
           2021-01-14 08:30 140830Z 05003KT CAVOK 04/M10 Q1020 NOSIG
           2021-01-14 09:00 140900Z 06004KT CAVOK 03/M09 Q1020 NOSIG
           2021-01-14 09:30 140930Z 05004KT CAVOK 03/M09 Q1020 NOSIG
           2021-01-14 10:00 141000Z 05006KT CAVOK 03/M09 Q1020 NOSIG
           2021-01-14 10:30 141030Z 07005KT CAVOK 01/M09 Q1020 NOSIG
           2021-01-14 11:00 141100Z 09002KT CAVOK 03/M10 Q1019 NOSIG
           2021-01-14 11:30 141130Z 06006KT CAVOK 02/M09 Q1019 NOSIG
           2021-01-14 12:00 141200Z 06006KT CAVOK M00/M08 Q1019 NOSIG
           2021-01-14 12:30 141230Z 07004KT CAVOK 01/M08 Q1019 NOSIG
           2021-01-14 13:00 141300Z 12002KT CAVOK 00/M08 Q1019 NOSIG
           2021-01-14 13:30 141330Z 14002KT CAVOK 02/M05 Q1019 NOSIG
           2021-01-14 14:00 141400Z 06005KT 9999 FEW030 SCT200 03/M08 Q1019 NOSIG
           2021-01-14 14:30 141430Z 05004KT 9999 FEW030 SCT200 02/M07 Q1019 NOSIG
           2021-01-14 15:00 141500Z 05006KT 9999 FEW030 SCT200 01/M07 Q1019 NOSIG
           2021-01-14 15:30 141530Z 05005KT 9999 FEW030 SCT200 02/M07 Q1019 NOSIG
           2021-01-14 16:00 141600Z 06006KT 9999 FEW030 SCT200 M01/M07 Q1019 NOSIG
           2021-01-14 16:30 141630Z 06006KT 9999 BKN030 BKN200 M00/M07 Q1019 NOSIG
           2021-01-14 17:00 141700Z 06005KT 9999 BKN030 BKN200 01/M07 Q1019 NOSIG
           2021-01-14 17:30 141730Z 13002KT 9999 BKN030 BKN200 01/M07 Q1019 NOSIG
           2021-01-14 18:00 141800Z 14006KT 9999 BKN040 BKN200 M01/M06 Q1018 NOSIG
           2021-01-14 18:30 141830Z 15006KT 9999 BKN040 BKN200 00/M05 Q1018 NOSIG
           2021-01-14 19:00 141900Z 16010KT 9999 BKN030 BKN200 03/M04 Q1017 NOSIG
           2021-01-14 19:30 141930Z 16010KT 9999 BKN030 BKN200 01/M04 Q1017 NOSIG
           2021-01-14 20:00 142000Z 16009KT 9999 FEW019 BKN030 BKN200 02/M03 Q1016 NOSIG
           2021-01-14 20:30 142030Z 16010KT 9999 FEW018 BKN030 BKN180 03/M03 Q1016 NOSIG
           2021-01-14 21:00 142100Z 16013KT 9999 FEW018 BKN030 BKN180 03/M02 Q1015 NOSIG
           2021-01-14 21:30 142130Z 16010KT 9999 FEW018 BKN030 BKN180 03/M02 Q1015 NOSIG
           2021-01-14 22:00 142200Z 17009KT 9999 BKN030 BKN180 04/M01 Q1016 NOSIG
           2021-01-14 22:30 142230Z 17010KT 9999 BKN030 BKN180 04/M00 Q1015 NOSIG
           2021-01-14 23:00 142300Z 15010KT 9999 BKN030 BKN180 04/M01 Q1015 NOSIG
           2021-01-14 23:30 142330Z 15011KT 9999 BKN030 BKN180 04/M00 Q1014 NOSIG
           2021-01-15 00:00 150000Z 15010KT 9000 BKN025 BKN180 04/M00 Q1014 NOSIG
           2021-01-15 00:30 150030Z 16010KT 9000 BKN020 BKN180 06/03 Q1014 NOSIG
           2021-01-15 01:00 150100Z 15012KT 8000 -RA FEW015 BKN020 OVC070 06/02 Q1014 NOSIG
           2021-01-15 01:30 150130Z 16010KT 9000 SCT017 BKN025 OVC070 07/04 Q1014 NOSIG
           2021-01-15 02:00 150200Z 17012KT 9999 SCT017 BKN025 BKN200 09/05 Q1014 NOSIG
           2021-01-15 02:30 150230Z 21015KT 9999 SCT015 BKN025 OVC120 10/07 Q1013 NOSIG
           2021-01-15 03:00 150300Z 21015KT 9999 SCT015 BKN025 OVC120 10/07 Q1012 NOSIG
           2021-01-15 03:30 150330Z 23017KT 9999 SCT017 BKN025 OVC120 09/06 Q1013 NOSIG
           2021-01-15 04:00 150400Z 22013G25KT 180V250 9999 FEW018 SCT030 BKN180 09/06 Q1012 NOSIG
           2021-01-15 04:30 150430Z 23013KT 8000 SCT015 BKN030 BKN180 09/06 Q1011 NOSIG
           2021-01-15 05:00 150500Z 26016KT 6000 SCT017 BKN030 OVC180 08/05 Q1012 NOSIG
           2021-01-15 05:30 150530Z 27016KT 4500 BR FEW017 BKN030 BKN180 07/04 Q1012 NOSIG
           2021-01-15 06:00 150600Z 27018G29KT 3500 BR FEW017 BKN025 OVC180 07/03 Q1012 NOSIG
           2021-01-15 06:30 150630Z 27015KT 3500 BR FEW018 BKN025 OVC180 06/03 Q1013 NOSIG
           2021-01-15 07:00 150700Z 27016KT 3000 BR BKN030 OVC180 06/03 Q1014 NOSIG
           2021-01-15 07:30 150730Z 30012KT 4000 BR SCT013 BKN030 OVC180 04/02 Q1014 NOSIG
           2021-01-15 08:00 150800Z 31017KT 7000 FEW013 BKN030 OVC180 03/M01 Q1015 NOSIG
           2021-01-15 08:30 150830Z 30015KT 8000 SCT030 OVC180 03/M01 Q1016 NOSIG
           2021-01-15 09:00 150900Z 30014KT 8000 FEW030 OVC160 03/M01 Q1016 NOSIG
           2021-01-15 09:30 150930Z 30011KT 9000 FEW030 OVC160 02/M02 Q1016 NOSIG
           2021-01-15 10:00 151000Z 30012KT CAVOK 02/M03 Q1017 NOSIG
           2021-01-15 10:30 151030Z 30012KT 9000 NSC 02/M02 Q1017 NOSIG
           2021-01-15 11:00 151100Z 31012KT 8000 NSC 01/M02 Q1018 NOSIG
           2021-01-15 11:30 151130Z 31011KT 7000 NSC 01/M04 Q1018 NOSIG
           2021-01-15 12:00 151200Z 31013KT 7000 NSC 00/M04 Q1018 NOSIG
           2021-01-15 12:30 151230Z 32012KT 6000 NSC M00/M04 Q1019 NOSIG
           2021-01-15 13:00 151300Z 32011KT 6000 NSC M00/M04 Q1019 NOSIG
           2021-01-15 13:30 151330Z 32011KT 6000 NSC M01/M05 Q1019 NOSIG
           2021-01-15 14:00 151400Z 31011KT 6000 NSC M01/M05 Q1020 NOSIG
           2021-01-15 14:30 151430Z 31010KT 6000 SCT035 BKN120 OVC200 M02/M05 Q1020 NOSIG
           2021-01-15 15:00 151500Z 31010KT 6000 FEW035 SCT120 OVC200 M02/M05 Q1020 NOSIG
           2021-01-15 15:30 151530Z 32008KT 6000 SCT035 SCT120 OVC200 M02/M05 Q1020 NOSIG
           2021-01-15 16:00 151600Z 31012KT 6000 SCT035 M03/M06 Q1021 NOSIG
           2021-01-15 16:30 151630Z 31011KT 7000 NSC M03/M06 Q1021 NOSIG
           2021-01-15 17:00 151700Z 31010KT 6000 NSC M03/M06 Q1022 NOSIG
           2021-01-15 17:30 151730Z 33012KT 6000 NSC M03/M07 Q1022 NOSIG
           2021-01-15 18:00 151800Z 33012KT 7000 FEW015 M04/M07 Q1022 NOSIG
           2021-01-15 18:30 151830Z 32011KT 7000 FEW015 M05/M08 Q1022 NOSIG
           2021-01-15 19:00 151900Z 32012KT 7000 NSC M05/M08 Q1023 NOSIG
           2021-01-15 19:30 151930Z 32011KT 8000 FEW015 M05/M08 Q1023 NOSIG
           2021-01-15 20:00 152000Z 32013KT 8000 FEW015 M04/M08 Q1023 NOSIG
           2021-01-15 20:30 152030Z 32011KT 8000 FEW015 M05/M09 Q1024 NOSIG
           2021-01-15 21:00 152100Z 32013KT 8000 SCT017 M05/M09 Q1024 NOSIG
           2021-01-15 21:30 152130Z 32010KT 9000 SCT017 M05/M09 Q1024 NOSIG
           2021-01-15 22:00 152200Z 33009KT 9000 SCT016 M06/M10 Q1025 NOSIG
           2021-01-15 22:30 152230Z 32007KT 8000 SCT016 M06/M10 Q1025 NOSIG
           2021-01-15 23:00 152300Z 31007KT 9999 FEW020 M06/M10 Q1025 NOSIG
           2021-01-15 23:30 152330Z 36005KT 9999 FEW020 M06/M10 Q1026 NOSIG
           2021-01-16 00:00 160000Z 36007KT 9999 FEW020 M06/M10 Q1026 NOSIG
           2021-01-16 00:30 160030Z 34008KT 9000 FEW020 M05/M10 Q1026 NOSIG
           2021-01-16 01:00 160100Z 34009KT 9000 NSC M05/M11 Q1027 NOSIG
           2021-01-16 01:30 160130Z 33007KT 300V360 CAVOK M05/M12 Q1027 NOSIG
           2021-01-16 02:00 160200Z 33008KT 300V360 9999 FEW020 M05/M11 Q1027 NOSIG
           2021-01-16 02:30 160230Z 31007KT 270V340 CAVOK M04/M11 Q1027 NOSIG
           2021-01-16 03:00 160300Z 31007KT 9999 FEW020 M04/M11 Q1027 NOSIG
           2021-01-16 03:30 160330Z 28008KT 250V310 9999 FEW020 M04/M10 Q1027 NOSIG
           2021-01-16 04:00 160400Z 30010KT 270V330 9999 FEW020 M03/M10 Q1027 NOSIG
           2021-01-16 04:30 160430Z 29011KT 9999 FEW020 M04/M11 Q1026 NOSIG
           2021-01-16 05:00 160500Z 30011KT 270V330 9999 FEW020 M04/M12 Q1026 NOSIG
           2021-01-16 05:30 160530Z 30011KT 9999 FEW020 M03/M11 Q1026 NOSIG
           2021-01-16 06:00 160600Z 31012KT 280V340 9999 FEW030 M03/M12 Q1026 NOSIG
           2021-01-16 06:30 160630Z 30014KT 9999 FEW030 M03/M13 Q1026 NOSIG
           2021-01-16 07:00 160700Z 30014KT 9999 FEW030 M03/M12 Q1027 NOSIG
           2021-01-16 07:30 160730Z 31012KT 9999 FEW030 M03/M12 Q1027 NOSIG
           2021-01-16 08:00 160800Z 31012KT 9999 FEW030 M04/M13 Q1027 NOSIG
           2021-01-16 08:30 160830Z 32014KT 9999 FEW030 M04/M12 Q1027 NOSIG
           2021-01-16 09:00 160900Z 32013KT 9999 FEW030 M05/M12 Q1027 NOSIG
           2021-01-16 09:30 160930Z 32014KT CAVOK M05/M12 Q1027 NOSIG
           2021-01-16 10:00 161000Z 32013KT CAVOK M05/M13 Q1028 NOSIG
           2021-01-16 10:30 161030Z 32013KT CAVOK M06/M13 Q1028 NOSIG
           2021-01-16 11:00 161100Z 32015KT CAVOK M06/M14 Q1028 NOSIG
           2021-01-16 11:30 161130Z 32014KT CAVOK M06/M15 Q1028 NOSIG
           2021-01-16 12:00 161200Z 33013KT CAVOK M07/M16 Q1028 NOSIG
           2021-01-16 12:30 161230Z 33015KT CAVOK M07/M15 Q1028 NOSIG
           2021-01-16 13:00 161300Z 34012KT CAVOK M07/M15 Q1029 NOSIG
           2021-01-16 13:30 161330Z 35009KT CAVOK M07/M15 Q1029 NOSIG
           2021-01-16 14:00 161400Z 36008KT CAVOK M07/M16 Q1029 NOSIG
           2021-01-16 14:30 161430Z 36010KT CAVOK M08/M16 Q1029 NOSIG
           2021-01-16 15:00 161500Z 36008KT CAVOK M08/M15 Q1029 NOSIG
           2021-01-16 15:30 161530Z 36007KT CAVOK M08/M15 Q1030 NOSIG
           2021-01-16 16:00 161600Z 36006KT CAVOK M08/M15 Q1030 NOSIG
           2021-01-16 16:30 161630Z 36006KT CAVOK M09/M15 Q1030 NOSIG
           2021-01-16 17:00 161700Z 33005KT 290V360 CAVOK M08/M15 Q1030 NOSIG
           2021-01-16 17:30 161730Z 31008KT 200V340 CAVOK M08/M15 Q1031 NOSIG
           2021-01-16 18:00 161800Z 32007KT CAVOK M08/M16 Q1031 NOSIG
           2021-01-16 18:30 161830Z 31008KT 290V030 CAVOK M09/M16 Q1030 NOSIG
           2021-01-16 19:00 161900Z 32008KT CAVOK M09/M15 Q1030 NOSIG
           2021-01-16 19:30 161930Z 33009KT CAVOK M09/M15 Q1030 NOSIG
           2021-01-16 20:00 162000Z 32010KT CAVOK M08/M14 Q1030 NOSIG
           2021-01-16 20:30 162030Z 32009KT CAVOK M09/M15 Q1030 NOSIG
           2021-01-16 21:00 162100Z 32008KT CAVOK M09/M15 Q1030 NOSIG
           2021-01-16 21:30 162130Z 32008KT CAVOK M09/M16 Q1030 NOSIG
           2021-01-16 22:00 162200Z 31007KT CAVOK M08/M16 Q1030 NOSIG
           2021-01-16 22:30 162230Z 32007KT CAVOK M09/M16 Q1031 NOSIG
           2021-01-16 23:00 162300Z 32007KT CAVOK M09/M16 Q1031 NOSIG
           2021-01-16 23:30 162330Z 33007KT CAVOK M07/M16 Q1031 NOSIG
           2021-01-17 00:00 170000Z 36005KT 300V050 CAVOK M06/M16 Q1031 NOSIG
           2021-01-17 00:30 170030Z 34007KT 290V020 CAVOK M06/M17 Q1031 NOSIG
           2021-01-17 01:00 170100Z 34005KT 310V010 CAVOK M05/M16 Q1031 NOSIG
           2021-01-17 01:30 170130Z 35005KT 320V040 CAVOK M05/M17 Q1031 NOSIG
           2021-01-17 02:00 170200Z 35003KT 310V040 CAVOK M05/M18 Q1030 NOSIG
           2021-01-17 02:30 170230Z 36002KT CAVOK M04/M18 Q1030 NOSIG
           2021-01-17 03:00 170300Z 17003KT 090V230 CAVOK M03/M20 Q1029 NOSIG
           2021-01-17 03:30 170330Z 13008KT 080V160 CAVOK M04/M17 Q1028 NOSIG
           2021-01-17 04:00 170400Z 16005KT CAVOK M03/M17 Q1027 NOSIG
           2021-01-17 04:30 170430Z 16007KT 130V190 9999 BKN040 M04/M16 Q1027 NOSIG
           2021-01-17 05:00 170500Z 16007KT 9999 BKN040 M03/M16 Q1026 NOSIG
           2021-01-17 05:30 170530Z 19006KT 140V230 9999 SCT040 M03/M16 Q1026 NOSIG
           2021-01-17 06:00 170600Z 19007KT 160V240 9999 SCT040 M02/M13 Q1026 NOSIG
           2021-01-17 06:30 170630Z 18009KT 9999 SCT040 M02/M12 Q1026 NOSIG
           2021-01-17 07:00 170700Z 21008KT 160V240 9999 SCT040 M02/M11 Q1025 NOSIG
           2021-01-17 07:30 170730Z 21008KT 170V240 9999 BKN035 M02/M10 Q1025 NOSIG
           2021-01-17 08:00 170800Z 21006KT 170V240 9999 BKN035 M02/M09 Q1025 NOSIG
           2021-01-17 08:30 170830Z 21006KT 180V240 9999 FEW015 BKN025 M02/M07 Q1025 NOSIG
           2021-01-17 09:00 170900Z 22005KT 150V240 9999 FEW015 BKN025 M02/M06 Q1025 NOSIG
           2021-01-17 09:30 170930Z 23005KT 9999 -SN FEW015 BKN025 BKN070 M02/M07 Q1024 NOSIG
           2021-01-17 10:00 171000Z 22005KT 9999 -SN BKN020 BKN060 M01/M06 Q1024 NOSIG
           2021-01-17 10:30 171030Z 26007KT 6000 2000N -SN FEW015 BKN025 M02/M04 Q1024 NOSIG
           2021-01-17 11:00 171100Z 25004KT 1500 0400N -SN BR BKN007 OVC020 M02/M03 Q1024 NOSIG
           2021-01-17 11:30 171130Z 23002KT 9999 -SN SCT007 BKN020 M03/M04 Q1024 NOSIG
           2021-01-17 12:00 171200Z 27008KT 4500 -SN BR SCT007 BKN020 M02/M03 Q1024 NOSIG
           2021-01-17 12:30 171230Z 27006KT 8000 -SN FEW010 BKN020 M03/M04 Q1024 NOSIG
           2021-01-17 13:00 171300Z 29008KT 8000 -SN FEW010 BKN025 M01/M05 Q1024 NOSIG
           2021-01-17 13:30 171330Z 30010KT 8000 2000NW -SN FEW010 BKN025 M01/M05 Q1024 NOSIG
           2021-01-17 14:00 171400Z 31008KT 9999 FEW017 BKN045 M02/M06 Q1024 NOSIG
           2021-01-17 14:30 171430Z 31006KT 9999 SCT045 M03/M07 Q1023 NOSIG
           2021-01-17 15:00 171500Z 30004KT 9999 BKN045 M03/M07 Q1023 NOSIG
           2021-01-17 15:30 171530Z 31004KT 8000 -SN FEW010 BKN040 OVC070 M02/M06 Q1023 NOSIG
           2021-01-17 16:00 171600Z 27009KT 250V310 4500 1700W -SN BR FEW010 BKN025 OVC070 M02/M05 Q1023 NOSIG
           2021-01-17 16:30 171630Z 29005KT 8000 -SN FEW014 BKN040 BKN070 M02/M04 Q1022 NOSIG
           2021-01-17 17:00 171700Z 33003KT 8000 -SN BKN045 BKN140 M04/M05 Q1022 NOSIG
           2021-01-17 17:30 171730Z 16002KT 8000 -SN FEW017 BKN045 OVC070 M03/M05 Q1022 NOSIG
           2021-01-17 18:00 171800Z 13004KT 9000 -SN FEW012 BKN040 OVC070 M04/M05 Q1021 NOSIG
           2021-01-17 18:30 171830Z 14005KT 9000 -SN FEW015 BKN045 OVC070 M03/M04 Q1021 NOSIG
           2021-01-17 19:00 171900Z 15006KT 9999 BKN045 OVC130 M04/M05 Q1020 NOSIG
           2021-01-17 19:30 171930Z 13005KT 9999 SCT045 OVC100 M03/M04 Q1020 NOSIG
           2021-01-17 20:00 172000Z 13007KT 9999 SCT045 OVC100 M02/M04 Q1019 NOSIG
           2021-01-17 20:30 172030Z 13008KT 9999 BKN045 OVC090 M02/M03 Q1019 NOSIG
           2021-01-17 21:00 172100Z 13008KT 7000 BKN045 OVC100 M02/M03 Q1019 NOSIG
           2021-01-17 21:30 172130Z 12008KT 8000 BKN045 OVC130 M02/M04 Q1018 NOSIG
           2021-01-17 22:00 172200Z 13004KT 120V220 9000 BKN045 OVC080 M01/M04 Q1018 NOSIG
           2021-01-17 22:30 172230Z 18004KT 9999 BKN045 OVC120 M00/M07 Q1018 NOSIG
           2021-01-17 23:00 172300Z 25007KT 210V290 9000 SCT045 OVC080 01/M06 Q1017 NOSIG
           2021-01-17 23:30 172330Z 25009KT 9000 SCT045 BKN070 01/M05 Q1017 NOSIG
           2021-01-18 00:00 180000Z 27012KT 9999 SCT045 BKN070 02/M06 Q1017 NOSIG
           2021-01-18 00:30 180030Z 27013KT 9999 SCT045 BKN070 02/M06 Q1017 NOSIG
           2021-01-18 01:00 180100Z 35009KT 9999 SCT045 BKN070 01/M04 Q1018 NOSIG
           2021-01-18 01:30 180130Z 35007KT CAVOK 01/M04 Q1018 NOSIG
           2021-01-18 02:00 180200Z 33010KT CAVOK 01/M05 Q1018 NOSIG
           2021-01-18 02:30 180230Z 33011KT CAVOK 01/M04 Q1018 NOSIG
           2021-01-18 03:00 180300Z 32011KT 9999 SCT040 BKN120 01/M03 Q1018 NOSIG
           2021-01-18 03:30 180330Z 33011KT 9999 SCT040 BKN120 00/M03 Q1018 NOSIG
           2021-01-18 04:00 180400Z 30013KT 9999 FEW015 SCT040 BKN100 01/M03 Q1018 NOSIG
           2021-01-18 04:30 180430Z 30014KT 9999 FEW015 BKN060 BKN120 00/M03 Q1018 NOSIG
           2021-01-18 05:00 180500Z 32016KT 9999 SCT020 BKN060 BKN120 00/M05 Q1018 BECMG SCT040
           2021-01-18 05:30 180530Z 31018KT 9999 SCT040 01/M11 Q1018 WS R34 NOSIG
           2021-01-18 06:00 180600Z 31022KT 9999 FEW040 01/M13 Q1019 WS R34 NOSIG
           2021-01-18 06:30 180630Z 31021KT CAVOK 00/M12 Q1019 WS R16 R34 NOSIG
           2021-01-18 07:00 180700Z 31022KT CAVOK M00/M15 Q1020 WS R16 R34 NOSIG
           2021-01-18 07:30 180730Z 31019KT CAVOK M01/M14 Q1021 WS R16 R34 NOSIG
           2021-01-18 08:00 180800Z 32020KT CAVOK M02/M15 Q1022 WS R16 R34 NOSIG
           2021-01-18 08:30 180830Z 33021KT CAVOK M03/M15 Q1023 WS R16 R34 NOSIG
           2021-01-18 09:00 180900Z 33019KT CAVOK M04/M14 Q1023 NOSIG
           2021-01-18 09:30 180930Z 33019KT CAVOK M04/M14 Q1024 NOSIG
           2021-01-18 10:00 181000Z 33016KT CAVOK M04/M14 Q1025 NOSIG
           2021-01-18 10:30 181030Z 33016KT CAVOK M05/M14 Q1025 NOSIG
           2021-01-18 11:00 181100Z 33016KT CAVOK M05/M13 Q1026 NOSIG
           2021-01-18 11:30 181130Z 33015KT CAVOK M05/M14 Q1026 NOSIG
           2021-01-18 12:00 181200Z 33020KT 9999 FEW030 M06/M14 Q1027 WS R16 R34 NOSIG
           2021-01-18 12:30 181230Z 33018KT CAVOK M06/M15 Q1027 NOSIG
           2021-01-18 13:00 181300Z 34015KT CAVOK M07/M16 Q1028 NOSIG
           2021-01-18 13:30 181330Z 35015KT CAVOK M08/M18 Q1028 NOSIG
           2021-01-18 14:00 181400Z 35013KT CAVOK M08/M20 Q1029 NOSIG
           2021-01-18 14:30 181430Z 34016KT CAVOK M09/M21 Q1029 NOSIG
           2021-01-18 15:00 181500Z 34015KT CAVOK M09/M20 Q1029 NOSIG
           2021-01-18 15:30 181530Z 35015KT CAVOK M09/M20 Q1029 NOSIG
           2021-01-18 16:00 181600Z 35012KT CAVOK M09/M19 Q1029 NOSIG
           2021-01-18 16:30 181630Z 36010KT CAVOK M09/M19 Q1029 NOSIG
           2021-01-18 17:00 181700Z 36008KT CAVOK M10/M20 Q1029 NOSIG
           2021-01-18 17:30 181730Z 01005KT 360V170 CAVOK M10/M21 Q1030 NOSIG
           2021-01-18 18:00 181800Z 03003KT 360V120 CAVOK M10/M21 Q1031 NOSIG
           2021-01-18 18:30 181830Z 07003KT CAVOK M10/M20 Q1031 NOSIG
           2021-01-18 19:00 181900Z 33005KT CAVOK M11/M20 Q1030 NOSIG
           2021-01-18 19:30 181930Z 01005KT 330V130 CAVOK M11/M19 Q1030 NOSIG
           2021-01-18 20:00 182000Z 08003KT 040V120 CAVOK M11/M19 Q1030 NOSIG
           2021-01-18 20:30 182030Z 01004KT CAVOK M11/M20 Q1031 NOSIG
           2021-01-18 21:00 182100Z 03004KT CAVOK M11/M19 Q1031 NOSIG
           2021-01-18 21:30 182130Z 05003KT CAVOK M11/M19 Q1032 NOSIG
           2021-01-18 22:00 182200Z 04003KT CAVOK M11/M19 Q1032 NOSIG
           2021-01-18 22:30 182230Z 02005KT CAVOK M11/M18 Q1032 NOSIG
           2021-01-18 23:00 182300Z 04005KT CAVOK M11/M19 Q1032 NOSIG
           2021-01-18 23:30 182330Z 08003KT 050V110 CAVOK M10/M19 Q1033 NOSIG
           2021-01-19 00:00 190000Z 07005KT CAVOK M09/M18 Q1033 NOSIG
           2021-01-19 00:30 190030Z 05008KT CAVOK M09/M18 Q1033 NOSIG
           2021-01-19 01:00 190100Z 04010KT CAVOK M08/M19 Q1033 NOSIG
           2021-01-19 01:30 190130Z 05007KT CAVOK M08/M19 Q1034 NOSIG
           2021-01-19 02:00 190200Z 02005KT 350V050 CAVOK M07/M19 Q1034 NOSIG
           2021-01-19 02:30 190230Z 05002KT CAVOK M06/M19 Q1034 NOSIG
           2021-01-19 03:00 190300Z 04004KT 340V130 CAVOK M06/M21 Q1034 NOSIG
           2021-01-19 03:30 190330Z 36003KT 360V160 CAVOK M05/M23 Q1033 NOSIG
           2021-01-19 04:00 190400Z 33002KT CAVOK M04/M23 Q1033 NOSIG
           2021-01-19 04:30 190430Z 01002KT CAVOK M04/M24 Q1033 NOSIG
           2021-01-19 05:00 190500Z 02003KT CAVOK M04/M23 Q1033 NOSIG
           2021-01-19 05:30 190530Z 34003KT 360V130 CAVOK M04/M22 Q1032 NOSIG
           2021-01-19 06:00 190600Z 01002KT CAVOK M03/M21 Q1032 NOSIG
           2021-01-19 06:30 190630Z 35003KT 290V040 CAVOK M03/M21 Q1032 NOSIG
           2021-01-19 07:00 190700Z 32003KT 280V030 CAVOK M03/M18 Q1033 NOSIG
           2021-01-19 07:30 190730Z 33004KT 300V360 CAVOK M03/M19 Q1032 NOSIG
           2021-01-19 08:00 190800Z 32003KT CAVOK M04/M18 Q1032 NOSIG
           2021-01-19 08:30 190830Z 33003KT CAVOK M05/M18 Q1032 NOSIG
           2021-01-19 09:00 190900Z 30003KT CAVOK M05/M17 Q1033 NOSIG
           2021-01-19 09:30 190930Z 33003KT CAVOK M05/M17 Q1033 NOSIG
           2021-01-19 10:00 191000Z 32002KT CAVOK M05/M16 Q1033 NOSIG
           2021-01-19 10:30 191030Z 31002KT CAVOK M05/M16 Q1033 NOSIG
           2021-01-19 11:00 191100Z 29001KT CAVOK M05/M17 Q1033 NOSIG
           2021-01-19 11:30 191130Z 00000KT CAVOK M05/M16 Q1033 NOSIG
           2021-01-19 12:00 191200Z 06003KT CAVOK M05/M15 Q1033 NOSIG
           2021-01-19 12:30 191230Z 07003KT CAVOK M05/M14 Q1033 NOSIG
           2021-01-19 13:00 191300Z 06004KT CAVOK M06/M15 Q1033 NOSIG
           2021-01-19 13:30 191330Z 06005KT CAVOK M06/M15 Q1032 NOSIG
           2021-01-19 14:00 191400Z 06004KT CAVOK M06/M14 Q1033 NOSIG
           2021-01-19 14:30 191430Z 06005KT CAVOK M06/M15 Q1032 NOSIG
           2021-01-19 15:00 191500Z 07005KT CAVOK M05/M16 Q1032 NOSIG
           2021-01-19 15:30 191530Z 07005KT CAVOK M06/M18 Q1032 NOSIG
           2021-01-19 16:00 191600Z 06005KT CAVOK M05/M17 Q1032 NOSIG
           2021-01-19 16:30 191630Z 06006KT CAVOK M06/M17 Q1032 NOSIG
           2021-01-19 17:00 191700Z 05005KT CAVOK M06/M17 Q1032 NOSIG
           2021-01-19 17:30 191730Z 05006KT CAVOK M06/M14 Q1032 NOSIG
           2021-01-19 18:00 191800Z 05007KT CAVOK M06/M14 Q1032 NOSIG
           2021-01-19 18:30 191830Z 05006KT CAVOK M06/M15 Q1032 NOSIG
           2021-01-19 19:00 191900Z 05007KT CAVOK M06/M16 Q1032 NOSIG
           2021-01-19 19:30 191930Z 05006KT CAVOK M07/M15 Q1032 NOSIG
           2021-01-19 20:00 192000Z 06006KT CAVOK M08/M15 Q1032 NOSIG
           2021-01-19 20:30 192030Z 06007KT CAVOK M07/M15 Q1032 NOSIG
           2021-01-19 21:00 192100Z 07005KT CAVOK M07/M15 Q1032 NOSIG
           2021-01-19 21:30 192130Z 06005KT CAVOK M07/M16 Q1032 NOSIG
           2021-01-19 22:00 192200Z 06006KT CAVOK M07/M15 Q1032 NOSIG
           2021-01-19 22:30 192230Z 06008KT CAVOK M08/M14 Q1032 NOSIG
           2021-01-19 23:00 192300Z 06006KT CAVOK M07/M14 Q1031 NOSIG
           2021-01-19 23:30 192330Z 06006KT CAVOK M06/M14 Q1031 NOSIG
           2021-01-20 00:00 200000Z 06006KT CAVOK M05/M13 Q1031 NOSIG
           2021-01-20 00:30 200030Z 06007KT CAVOK M04/M13 Q1031 NOSIG
           2021-01-20 01:00 200100Z 06006KT CAVOK M02/M13 Q1032 NOSIG
           2021-01-20 01:30 200130Z 05005KT CAVOK M01/M15 Q1032 NOSIG
           2021-01-20 02:00 200200Z 12006KT 070V160 CAVOK M00/M14 Q1032 NOSIG
           2021-01-20 02:30 200230Z 12008KT CAVOK 00/M13 Q1031 NOSIG
           2021-01-20 03:00 200300Z 13007KT 090V160 CAVOK 02/M14 Q1030 NOSIG
           2021-01-20 03:30 200330Z 14010KT CAVOK 02/M15 Q1029 NOSIG
           2021-01-20 04:00 200400Z 13007KT CAVOK 03/M15 Q1029 NOSIG
           2021-01-20 04:30 200430Z 13008KT CAVOK 05/M15 Q1028 NOSIG
           2021-01-20 05:00 200500Z 14010KT 110V170 CAVOK 05/M14 Q1028 NOSIG
           2021-01-20 05:30 200530Z 15009KT 120V180 CAVOK 05/M13 Q1028 NOSIG
           2021-01-20 06:00 200600Z 17009KT CAVOK 05/M12 Q1028 NOSIG
           2021-01-20 06:30 200630Z 17007KT 140V200 CAVOK 05/M12 Q1028 NOSIG
           2021-01-20 07:00 200700Z 15007KT CAVOK 06/M12 Q1028 NOSIG
           2021-01-20 07:30 200730Z 15005KT CAVOK 04/M10 Q1028 NOSIG
           2021-01-20 08:00 200800Z 14004KT CAVOK 04/M09 Q1027 NOSIG
           2021-01-20 08:30 200830Z 16004KT CAVOK 04/M11 Q1028 NOSIG
           2021-01-20 09:00 200900Z 14004KT CAVOK 03/M10 Q1027 NOSIG
           2021-01-20 09:30 200930Z 14003KT CAVOK 03/M10 Q1027 NOSIG
           2021-01-20 10:00 201000Z 09004KT 9999 FEW030 BKN190 02/M11 Q1027 NOSIG
           2021-01-20 10:30 201030Z 04002KT 9999 FEW030 BKN190 03/M11 Q1027 NOSIG
           2021-01-20 11:00 201100Z 13004KT 9999 SCT030 BKN190 02/M11 Q1027 NOSIG
           2021-01-20 11:30 201130Z 04002KT 9999 SCT030 BKN190 02/M12 Q1027 NOSIG
           2021-01-20 12:00 201200Z 04004KT 9999 BKN040 02/M11 Q1027 NOSIG
           2021-01-20 12:30 201230Z 07002KT 9999 BKN040 03/M11 Q1027 NOSIG
           2021-01-20 13:00 201300Z 05004KT 9999 BKN040 02/M09 Q1027 NOSIG
           2021-01-20 13:30 201330Z 06005KT 9999 BKN040 02/M07 Q1027 NOSIG
           2021-01-20 14:00 201400Z 07003KT 050V120 9999 BKN040 02/M07 Q1027 NOSIG
           2021-01-20 14:30 201430Z 11002KT 9999 BKN040 04/M10 Q1027 NOSIG
           2021-01-20 15:00 201500Z 07002KT 9999 BKN040 04/M10 Q1027 NOSIG
           2021-01-20 15:30 201530Z 12004KT 9999 BKN040 03/M10 Q1027 NOSIG
           2021-01-20 16:00 201600Z 06004KT 9999 BKN045 03/M10 Q1027 NOSIG
           2021-01-20 16:30 201630Z 06004KT 9999 BKN045 03/M09 Q1027 NOSIG
           2021-01-20 17:00 201700Z 06004KT 9999 BKN045 03/M08 Q1027 NOSIG
           2021-01-20 17:30 201730Z 06004KT 9999 BKN045 03/M08 Q1027 NOSIG
           2021-01-20 18:00 201800Z 13002KT 9999 BKN040 02/M07 Q1026 NOSIG
           2021-01-20 18:30 201830Z 08003KT 060V120 9999 BKN040 02/M05 Q1026 NOSIG
           2021-01-20 19:00 201900Z 06005KT 9999 BKN040 02/M07 Q1026 NOSIG
           2021-01-20 19:30 201930Z 06004KT 9999 BKN045 03/M07 Q1026 NOSIG
           2021-01-20 20:00 202000Z 07004KT 9999 BKN045 03/M07 Q1026 NOSIG
           2021-01-20 20:30 202030Z 13006KT 9999 BKN045 03/M07 Q1025 NOSIG
           2021-01-20 21:00 202100Z 13006KT 9999 BKN045 02/M05 Q1025 NOSIG
           2021-01-20 21:30 202130Z 13005KT 9999 BKN040 03/M05 Q1025 NOSIG
           2021-01-20 22:00 202200Z 06004KT 9999 BKN040 03/M06 Q1025 NOSIG
           2021-01-20 22:30 202230Z 03002KT 9999 BKN040 03/M06 Q1025 NOSIG
           2021-01-20 23:00 202300Z 05003KT 9999 SCT030 OVC060 03/M05 Q1025 NOSIG
           2021-01-20 23:30 202330Z 06004KT 9999 SCT030 OVC060 03/M05 Q1025 NOSIG
           2021-01-21 00:00 210000Z 05003KT 9999 SCT030 BKN060 04/M05 Q1025 NOSIG
           2021-01-21 00:30 210030Z 06004KT 9999 FEW030 BKN060 05/M06 Q1025 NOSIG
           2021-01-21 01:00 210100Z 06003KT 9999 FEW030 BKN060 05/M06 Q1025 NOSIG
           2021-01-21 01:30 210130Z 05005KT 9999 FEW030 BKN070 06/M06 Q1024 NOSIG
           2021-01-21 02:00 210200Z 04004KT 9999 BKN040 BKN070 06/M06 Q1024 NOSIG
           2021-01-21 02:30 210230Z 13006KT 100V160 9999 FEW019 BKN040 BKN070 07/M06 Q1023 BECMG -RA
           2021-01-21 03:00 210300Z 10004KT 040V130 9999 BKN025 BKN070 07/M05 Q1023 NOSIG
           2021-01-21 03:30 210330Z 05004KT 340V080 9999 -RA SCT025 OVC070 07/M05 Q1022 NOSIG
           2021-01-21 04:00 210400Z 02002KT 8000 -RA BKN025 OVC070 07/M04 Q1022 NOSIG
           2021-01-21 04:30 210430Z 03002KT 7000 -RA BKN025 OVC070 06/M01 Q1022 NOSIG
           2021-01-21 05:00 210500Z 07002KT 6000 -RA BKN025 OVC070 06/02 Q1021 NOSIG
           2021-01-21 05:30 210530Z 02003KT 4000 -RA BR FEW016 BKN025 OVC070 05/02 Q1021 NOSIG
           2021-01-21 06:00 210600Z 05003KT 020V080 3500 -RA BR SCT016 BKN025 OVC070 04/02 Q1021 NOSIG
           2021-01-21 06:30 210630Z 01002KT 3000 -RA BR SCT016 BKN025 OVC070 04/03 Q1021 NOSIG
           2021-01-21 07:00 210700Z 05003KT 020V080 2500 -RA BR SCT013 BKN025 OVC070 04/03 Q1021 NOSIG
           2021-01-21 07:30 210730Z 06004KT 2000 1300N -RA BR SCT014 BKN025 OVC070 04/03 Q1021 NOSIG
           2021-01-21 08:00 210800Z 13004KT 1500 0800NW -RA PRFG SCT010 BKN020 OVC070 05/04 Q1021 NOSIG
           2021-01-21 08:30 210830Z 09004KT 060V120 1500 0500SW RA PRFG SCT015 BKN020 OVC070 05/04 Q1021 NOSIG
           2021-01-21 09:00 210900Z 04004KT 1000 0700N R15L/1500N R15R/1500U R16/1900N -RA PRFG SCT011 BKN020 OVC070 05/04 Q1021 NOSIG
           2021-01-21 09:30 210930Z 05004KT 1000 0450N R15L/1100N R15R/1300D R16/1600D RA PRFG SCT011 BKN020 OVC070 04/04 Q1021 NOSIG
           2021-01-21 10:00 211000Z 05007KT 1000 0600N R15L/1400N R15R/1400N R16/1700N -RA PRFG SCT009 BKN020 OVC070 04/03 Q1021 NOSIG
           2021-01-21 10:30 211030Z 07006KT 1000 0800N R33R/P2000N R33L/1900N R34/P2000N -RA PRFG SCT007 BKN020 OVC070 04/03 Q1021 NOSIG
           2021-01-21 11:00 211100Z 05007KT 1000 0700N R33R/1300N R33L/1400U R34/P2000N RA PRFG SCT005 BKN020 OVC070 04/03 Q1021 NOSIG
           2021-01-21 11:30 211130Z 05008KT 1000 0700N R33R/P2000N R33L/1900N R34/P2000N -RA PRFG SCT004 BKN025 OVC070 03/03 Q1021 NOSIG
           2021-01-21 12:00 211200Z 06006KT 1000 0700N R33R/P2000N R33L/1900D R34/P2000N -DZ PRFG SCT015 BKN020 OVC070 04/03 Q1021 NOSIG
           2021-01-21 12:30 211230Z 06005KT 1000 0600N R33R/1200D R33L/1500N R34/P2000N -RA PRFG SCT015 BKN020 OVC070 04/03 Q1020 NOSIG
           2021-01-21 13:00 211300Z 06001KT 1100 0700N R33R/P2000N R33L/P2000N R34/1800U -DZ PRFG FEW015 BKN020 OVC070 04/04 Q1020 NOSIG
           2021-01-21 13:30 211330Z 04003KT 1100 0700N R33R/P2000N R33L/P2000N R34/P2000N -DZ PRFG FEW015 BKN020 OVC070 04/03 Q1020 NOSIG
           2021-01-21 14:00 211400Z 07005KT 050V110 1200 0500N R33R/1500N R33L/1800U R34/P2000N -DZ PRFG FEW015 BKN020 OVC070 04/03 Q1020 NOSIG
           2021-01-21 14:30 211430Z 07002KT 0900 R33R/1700D R33L/P2000N R34/1500N -DZ FG SCT010 BKN020 OVC070 05/05 Q1019 NOSIG
           2021-01-21 15:00 211500Z 05004KT 0500 R33R/1500U R33L/1600D R34/1800D FG SCT004 BKN020 OVC070 04/03 Q1020 NOSIG
           2021-01-21 15:30 211530Z 05004KT 0400 R33R/1100N R33L/P2000D R34/0800U FG SCT004 BKN020 OVC070 03/03 Q1020 NOSIG
           2021-01-21 16:00 211600Z 05005KT 0500 R33R/P2000N R33L/1900U R34/P2000N FG SCT004 BKN020 OVC070 03/03 Q1019 NOSIG
           2021-01-21 16:30 211630Z 05006KT 0500 R33R/P2000N R33L/1700U R34/1900N FG SCT004 BKN020 OVC070 04/03 Q1020 NOSIG
           2021-01-21 17:00 211700Z 05007KT 0900 R33R/P2000N R33L/1900N R34/P2000N -RA FG SCT005 BKN020 OVC070 04/04 Q1019 NOSIG
           2021-01-21 17:30 211730Z 05006KT 0900 R33R/P2000N R33L/P2000N R34/P2000N -RA FG SCT005 BKN020 04/04 Q1019 NOSIG
           2021-01-21 18:00 211800Z 05007KT 0600 R33R/P2000N R33L/P2000N R34/P2000N FG BKN005 BKN020 04/03 Q1019 NOSIG
           2021-01-21 18:30 211830Z 05007KT 0900 R33R/P2000N R33L/P2000N R34/P2000N FG BKN005 04/03 Q1019 NOSIG
           2021-01-21 19:00 211900Z 05008KT 0900 R33R/P2000N R33L/P2000N R34/P2000N FG BKN005 BKN040 04/04 Q1019 NOSIG
           2021-01-21 19:30 211930Z 04005KT 010V070 0600 R33R/P2000N R33L/P2000N R34/P2000N FG BKN004 BKN040 04/04 Q1019 NOSIG
           2021-01-21 20:00 212000Z 04006KT 0500 R33R/1800N R33L/1600N R34/1800N FG BKN004 04/03 Q1019 NOSIG
           2021-01-21 20:30 212030Z 05006KT 0500 R33R/1600N R33L/1600U R34/1800N FG BKN004 04/04 Q1019 NOSIG
           2021-01-21 21:00 212100Z 04005KT 0600 R33R/1800N R33L/1700N R34/P2000N FG BKN005 BKN035 04/04 Q1019 NOSIG
           2021-01-21 21:30 212130Z 05004KT 0600 R33R/1900N R33L/1900N R34/P2000N FG BKN005 BKN035 04/04 Q1019 NOSIG
           2021-01-21 22:00 212200Z 06004KT 1000 R33R/1900N R33L/1900D R34/P2000N BR BKN005 BKN035 04/04 Q1019 NOSIG
           2021-01-21 22:30 212230Z 05005KT 1100 R33R/P2000N R33L/1900D R34/P2000N BR BKN005 BKN035 04/04 Q1020 NOSIG
           2021-01-21 23:00 212300Z 05005KT 1200 0700N R33R/1900D R33L/P2000D R34/P2000N PRFG SCT004 BKN035 04/03 Q1020 NOSIG
           2021-01-21 23:30 212330Z 06005KT 0600 R33R/1800U R33L/1800U R34/1900N FG BKN003 BKN035 04/03 Q1020 NOSIG
           2021-01-22 00:00 220000Z 34002KT 0600 R33R/P2000N R33L/P2000D R34/1100U FG VV001 04/03 Q1021 NOSIG
           2021-01-22 00:30 220030Z 04001KT 0700 R33R/P2000N R33L/P2000N R34/0750N FG VV001 04/04 Q1021 NOSIG
           2021-01-22 01:00 220100Z 02001KT 1000 R33R/P2000N R33L/P2000N R34/1000N BR BKN008 BKN025 05/04 Q1021 NOSIG
           2021-01-22 01:30 220130Z 00000KT 1600 BR BKN008 BKN025 05/05 Q1021 NOSIG
           2021-01-22 02:00 220200Z 04005KT 2000 BR BKN008 OVC025 06/04 Q1021 NOSIG
           2021-01-22 02:30 220230Z 05005KT 020V080 3500 BR BKN007 OVC025 06/05 Q1021 NOSIG
           2021-01-22 03:00 220300Z 04004KT 4500 2200N BR BKN006 OVC025 06/04 Q1020 NOSIG
           2021-01-22 03:30 220330Z 02004KT 5000 BR BKN007 OVC025 06/05 Q1020 NOSIG
           2021-01-22 04:00 220400Z 03003KT 5000 BR BKN008 OVC025 06/05 Q1020 NOSIG
           2021-01-22 04:30 220430Z 32004KT 3500 BR BKN010 OVC025 06/05 Q1019 NOSIG
           2021-01-22 05:00 220500Z 32005KT 3500 BR BKN010 OVC025 06/05 Q1019 NOSIG
           2021-01-22 05:30 220530Z 31004KT 3500 BR BKN010 OVC025 06/05 Q1019 NOSIG
           2021-01-22 06:00 220600Z 34005KT 5000 BR BKN010 OVC025 06/04 Q1019 NOSIG
           2021-01-22 06:30 220630Z 31005KT 8000 BKN012 OVC025 06/04 Q1019 NOSIG
           2021-01-22 07:00 220700Z 33005KT 9000 BKN012 OVC025 06/04 Q1019 NOSIG
           2021-01-22 07:30 220730Z 33005KT 9000 BKN012 OVC025 06/04 Q1019 NOSIG
           2021-01-22 08:00 220800Z 33004KT 8000 BKN010 OVC025 05/04 Q1019 NOSIG
           2021-01-22 08:30 220830Z 35003KT 8000 BKN010 OVC025 05/04 Q1019 NOSIG
           2021-01-22 09:00 220900Z 01003KT 360V060 7000 BKN010 BKN025 06/04 Q1020 NOSIG
           2021-01-22 09:30 220930Z 04003KT 7000 BKN011 OVC025 06/04 Q1020 NOSIG
           2021-01-22 10:00 221000Z 04003KT 7000 BKN010 OVC025 05/04 Q1020 NOSIG
           2021-01-22 10:30 221030Z 04003KT 7000 BKN010 OVC025 06/04 Q1020 NOSIG
           2021-01-22 11:00 221100Z 06004KT 8000 BKN009 OVC025 06/04 Q1020 NOSIG
           2021-01-22 11:30 221130Z 05003KT 6000 BKN009 OVC025 06/04 Q1020 NOSIG
           2021-01-22 12:00 221200Z 06004KT 020V110 6000 BKN010 OVC025 06/04 Q1020 NOSIG
           2021-01-22 12:30 221230Z 12003KT 090V150 6000 BKN010 BKN025 05/04 Q1020 NOSIG
           2021-01-22 13:00 221300Z 04004KT 6000 BKN010 BKN025 06/04 Q1020 NOSIG
           2021-01-22 13:30 221330Z 03003KT 5000 BR BKN010 BKN025 05/04 Q1020 NOSIG
           2021-01-22 14:00 221400Z 04005KT 5000 BR BKN010 BKN025 05/04 Q1020 NOSIG
           2021-01-22 14:30 221430Z 04005KT 4000 BR BKN012 BKN025 05/04 Q1020 NOSIG
           2021-01-22 15:00 221500Z 04005KT 4000 BR BKN012 BKN025 05/03 Q1019 NOSIG
           2021-01-22 15:30 221530Z 04004KT 4000 BR BKN012 BKN025 04/03 Q1020 NOSIG
           2021-01-22 16:00 221600Z 04004KT 4000 BR BKN010 BKN030 05/03 Q1020 NOSIG
           2021-01-22 16:30 221630Z 05005KT 5000 BR SCT010 BKN030 04/03 Q1020 NOSIG
           2021-01-22 17:00 221700Z 04005KT 7000 SCT010 BKN030 04/02 Q1019 NOSIG
           2021-01-22 17:30 221730Z 05007KT 8000 FEW010 BKN030 04/02 Q1020 NOSIG
           2021-01-22 18:00 221800Z 05006KT 9000 FEW010 BKN030 04/01 Q1020 NOSIG
           2021-01-22 18:30 221830Z 05005KT 9999 BKN030 04/01 Q1019 NOSIG
           2021-01-22 19:00 221900Z 06005KT 9999 BKN030 03/01 Q1019 NOSIG
           2021-01-22 19:30 221930Z 06007KT 9999 BKN030 BKN180 04/01 Q1019 NOSIG
           2021-01-22 20:00 222000Z 05009KT 9999 BKN030 04/01 Q1019 NOSIG
           2021-01-22 20:30 222030Z 05011KT 9999 BKN030 04/01 Q1019 NOSIG
           2021-01-22 21:00 222100Z 06012KT 9999 BKN040 04/01 Q1019 NOSIG
           2021-01-22 21:30 222130Z 06012KT 9999 BKN040 04/01 Q1019 NOSIG
           2021-01-22 22:00 222200Z 05013KT 9999 BKN030 04/01 Q1019 NOSIG
           2021-01-22 22:30 222230Z 06011KT 9999 BKN030 04/01 Q1020 NOSIG
           2021-01-22 23:00 222300Z 06010KT 9999 BKN030 05/00 Q1020 NOSIG
           2021-01-22 23:30 222330Z 06009KT 9999 BKN030 BKN200 05/00 Q1020 NOSIG
           2021-01-23 00:00 230000Z 05011KT 9999 BKN030 BKN200 05/00 Q1020 NOSIG
           2021-01-23 00:30 230030Z 06012KT 9999 SCT030 BKN200 05/00 Q1020 NOSIG
           2021-01-23 01:00 230100Z 06011KT 9999 SCT040 BKN200 06/00 Q1020 NOSIG
           2021-01-23 01:30 230130Z 05013KT 9999 SCT040 BKN200 06/00 Q1020 NOSIG
           2021-01-23 02:00 230200Z 06012KT 9999 SCT040 BKN200 07/01 Q1020 NOSIG
           2021-01-23 02:30 230230Z 06011KT 9999 SCT040 BKN200 08/01 Q1020 NOSIG
           2021-01-23 03:00 230300Z 05012KT CAVOK 08/01 Q1019 NOSIG
           2021-01-23 03:30 230330Z 05011KT CAVOK 08/01 Q1019 NOSIG
           2021-01-23 04:00 230400Z 05010KT CAVOK 09/02 Q1018 NOSIG
           2021-01-23 04:30 230430Z 05011KT CAVOK 10/02 Q1018 NOSIG
           2021-01-23 05:00 230500Z 05010KT CAVOK 10/02 Q1018 NOSIG
           2021-01-23 05:30 230530Z 05012KT CAVOK 11/02 Q1018 NOSIG
           2021-01-23 06:00 230600Z 04010KT CAVOK 11/02 Q1018 NOSIG
           2021-01-23 06:30 230630Z 04012KT CAVOK 10/02 Q1018 NOSIG
           2021-01-23 07:00 230700Z 04011KT CAVOK 10/02 Q1018 NOSIG
           2021-01-23 07:30 230730Z 04010KT CAVOK 10/02 Q1018 NOSIG
           2021-01-23 08:00 230800Z 05010KT CAVOK 09/02 Q1019 NOSIG
           2021-01-23 08:30 230830Z 05010KT CAVOK 09/01 Q1019 NOSIG
           2021-01-23 09:00 230900Z 05010KT CAVOK 08/01 Q1019 NOSIG
           2021-01-23 09:30 230930Z 05012KT CAVOK 08/01 Q1019 NOSIG
           2021-01-23 10:00 231000Z 05012KT CAVOK 07/00 Q1020 NOSIG
           2021-01-23 10:30 231030Z 05013KT CAVOK 07/00 Q1020 NOSIG
           2021-01-23 11:00 231100Z 05013KT CAVOK 06/00 Q1020 NOSIG
           2021-01-23 11:30 231130Z 05011KT CAVOK 06/00 Q1021 NOSIG
           2021-01-23 12:00 231200Z 05012KT CAVOK 06/M00 Q1021 NOSIG
           2021-01-23 12:30 231230Z 05012KT CAVOK 05/M00 Q1022 NOSIG
           2021-01-23 13:00 231300Z 05011KT CAVOK 05/M00 Q1022 NOSIG
           2021-01-23 13:30 231330Z 06010KT CAVOK 05/00 Q1022 NOSIG
           2021-01-23 14:00 231400Z 06010KT CAVOK 05/M00 Q1022 NOSIG
           2021-01-23 14:30 231430Z 06010KT CAVOK 05/M00 Q1022 NOSIG
           2021-01-23 15:00 231500Z 06009KT CAVOK 05/M00 Q1023 NOSIG
           2021-01-23 15:30 231530Z 06010KT CAVOK 05/M00 Q1023 NOSIG
           2021-01-23 16:00 231600Z 06008KT CAVOK 05/M00 Q1023 NOSIG
           2021-01-23 16:30 231630Z 06009KT CAVOK 04/M01 Q1023 NOSIG
           2021-01-23 17:00 231700Z 06007KT 030V100 CAVOK 04/M01 Q1023 NOSIG
           2021-01-23 17:30 231730Z 07008KT CAVOK 04/M01 Q1023 NOSIG
           2021-01-23 18:00 231800Z 06009KT CAVOK 04/M01 Q1023 NOSIG
           2021-01-23 18:30 231830Z 06007KT CAVOK 03/M01 Q1024 NOSIG
           2021-01-23 19:00 231900Z 06009KT CAVOK 03/M01 Q1024 NOSIG
           2021-01-23 19:30 231930Z 06009KT CAVOK 03/M01 Q1023 NOSIG
           2021-01-23 20:00 232000Z 05010KT CAVOK 03/M01 Q1024 NOSIG
           2021-01-23 20:30 232030Z 06010KT CAVOK 03/M01 Q1024 NOSIG
           2021-01-23 21:00 232100Z 07008KT CAVOK 03/M01 Q1024 NOSIG
           2021-01-23 21:30 232130Z 07008KT CAVOK 03/M01 Q1024 NOSIG
           2021-01-23 22:00 232200Z 06008KT CAVOK 02/M01 Q1025 NOSIG
           2021-01-23 22:30 232230Z 06009KT CAVOK 02/M01 Q1025 NOSIG
           2021-01-23 23:00 232300Z 06008KT CAVOK 03/M01 Q1026 NOSIG
           2021-01-23 23:30 232330Z 06008KT CAVOK 04/M01 Q1026 NOSIG
           2021-01-24 00:00 240000Z 06007KT CAVOK 04/M00 Q1026 NOSIG
           2021-01-24 00:30 240030Z 06007KT CAVOK 05/M00 Q1027 NOSIG
           2021-01-24 01:00 240100Z 05006KT CAVOK 07/00 Q1027 NOSIG
           2021-01-24 01:30 240130Z 05009KT CAVOK 07/00 Q1027 NOSIG
           2021-01-24 02:00 240200Z 04009KT CAVOK 08/00 Q1027 NOSIG
           2021-01-24 02:30 240230Z 04008KT CAVOK 09/01 Q1027 NOSIG
           2021-01-24 03:00 240300Z 03007KT CAVOK 10/01 Q1027 NOSIG
           2021-01-24 03:30 240330Z 04006KT CAVOK 10/00 Q1026 NOSIG
           2021-01-24 04:00 240400Z 04004KT 010V090 CAVOK 11/00 Q1026 NOSIG
           2021-01-24 04:30 240430Z 04003KT 330V130 CAVOK 11/00 Q1026 NOSIG
           2021-01-24 05:00 240500Z 33004KT 280V360 CAVOK 11/M00 Q1025 NOSIG
           2021-01-24 05:30 240530Z 32006KT CAVOK 11/01 Q1025 NOSIG
           2021-01-24 06:00 240600Z 32007KT CAVOK 11/01 Q1025 NOSIG
           2021-01-24 06:30 240630Z 32005KT CAVOK 11/01 Q1025 NOSIG
           2021-01-24 07:00 240700Z 32006KT CAVOK 09/01 Q1025 NOSIG
           2021-01-24 07:30 240730Z 31005KT CAVOK 08/01 Q1025 NOSIG
           2021-01-24 08:00 240800Z 33005KT CAVOK 07/01 Q1025 NOSIG
           2021-01-24 08:30 240830Z 33005KT CAVOK 07/00 Q1025 NOSIG
           2021-01-24 09:00 240900Z 28005KT CAVOK 07/01 Q1026 NOSIG
           2021-01-24 09:30 240930Z 30005KT CAVOK 06/01 Q1027 NOSIG
           2021-01-24 10:00 241000Z 29003KT CAVOK 06/01 Q1027 NOSIG
           2021-01-24 10:30 241030Z 33003KT CAVOK 05/01 Q1027 NOSIG
           2021-01-24 11:00 241100Z 31002KT CAVOK 05/01 Q1026 NOSIG
           2021-01-24 11:30 241130Z 02002KT CAVOK 04/01 Q1026 NOSIG
           2021-01-24 12:00 241200Z 24002KT CAVOK 06/01 Q1026 NOSIG
           2021-01-24 12:30 241230Z 25002KT CAVOK 04/00 Q1026 NOSIG
           2021-01-24 13:00 241300Z 31002KT CAVOK 04/00 Q1027 NOSIG
           2021-01-24 13:30 241330Z 34003KT CAVOK 02/M00 Q1027 NOSIG
           2021-01-24 14:00 241400Z 34002KT CAVOK 03/00 Q1027 NOSIG
           2021-01-24 14:30 241430Z 01001KT CAVOK 04/01 Q1027 NOSIG
           2021-01-24 15:00 241500Z 05002KT CAVOK 03/01 Q1027 NOSIG
           2021-01-24 15:30 241530Z 30002KT CAVOK 04/00 Q1028 NOSIG
           2021-01-24 16:00 241600Z 29001KT CAVOK 04/01 Q1028 NOSIG
           2021-01-24 16:30 241630Z 13002KT CAVOK 02/M01 Q1028 NOSIG
           2021-01-24 17:00 241700Z 12003KT 090V150 CAVOK 02/M00 Q1028 NOSIG
           2021-01-24 17:30 241730Z 11002KT CAVOK 02/M00 Q1029 NOSIG
           2021-01-24 18:00 241800Z 13001KT CAVOK 04/01 Q1028 NOSIG
           2021-01-24 18:30 241830Z 05004KT CAVOK 03/00 Q1028 NOSIG
           2021-01-24 19:00 241900Z 05005KT CAVOK 02/00 Q1028 NOSIG
           2021-01-24 19:30 241930Z 06006KT CAVOK 01/M00 Q1028 NOSIG
           2021-01-24 20:00 242000Z 05005KT CAVOK 03/01 Q1028 NOSIG
           2021-01-24 20:30 242030Z 06005KT CAVOK 02/00 Q1028 NOSIG
           2021-01-24 21:00 242100Z 06003KT CAVOK 03/M00 Q1028 NOSIG
           2021-01-24 21:30 242130Z 05005KT CAVOK 03/01 Q1028 NOSIG
           2021-01-24 22:00 242200Z 05006KT CAVOK 03/00 Q1028 NOSIG
           2021-01-24 22:30 242230Z 06006KT 8000 NSC 03/00 Q1028 NOSIG
           2021-01-24 23:00 242300Z 06007KT 8000 NSC 02/00 Q1028 NOSIG
           2021-01-24 23:30 242330Z 06007KT 8000 NSC 02/01 Q1027 NOSIG
           2021-01-25 00:00 250000Z 06006KT 9000 NSC 05/01 Q1027 NOSIG
           2021-01-25 00:30 250030Z 05005KT 9000 NSC 04/01 Q1027 NOSIG
           2021-01-25 01:00 250100Z 04003KT 9000 NSC 06/02 Q1027 NOSIG
           2021-01-25 01:30 250130Z 04004KT CAVOK 08/02 Q1027 NOSIG
           2021-01-25 02:00 250200Z 05004KT CAVOK 08/01 Q1027 NOSIG
           2021-01-25 02:30 250230Z 04004KT CAVOK 09/02 Q1027 NOSIG
           2021-01-25 03:00 250300Z 09003KT 030V160 CAVOK 10/01 Q1027 NOSIG
           2021-01-25 03:30 250330Z 16005KT 130V200 CAVOK 09/01 Q1027 NOSIG
           2021-01-25 04:00 250400Z 01002KT CAVOK 11/02 Q1026 NOSIG
           2021-01-25 04:30 250430Z 03005KT 280V050 CAVOK 11/01 Q1025 NOSIG
           2021-01-25 05:00 250500Z 04006KT CAVOK 11/01 Q1024 NOSIG
           2021-01-25 05:30 250530Z 04006KT CAVOK 12/02 Q1024 NOSIG
           2021-01-25 06:00 250600Z 03003KT 320V070 CAVOK 12/02 Q1024 NOSIG
           2021-01-25 06:30 250630Z 16002KT CAVOK 13/00 Q1023 NOSIG
           2021-01-25 07:00 250700Z 31006KT 280V340 CAVOK 11/01 Q1023 NOSIG
           2021-01-25 07:30 250730Z 30008KT CAVOK 09/02 Q1023 NOSIG
           2021-01-25 08:00 250800Z 33006KT CAVOK 07/01 Q1024 NOSIG
           2021-01-25 08:30 250830Z 31005KT CAVOK 08/01 Q1024 NOSIG
           2021-01-25 09:00 250900Z 31004KT CAVOK 06/01 Q1024 NOSIG
           2021-01-25 09:30 250930Z 29004KT CAVOK 06/01 Q1024 NOSIG
           2021-01-25 10:00 251000Z 29004KT CAVOK 06/02 Q1024 NOSIG
           2021-01-25 10:30 251030Z 25002KT CAVOK 06/01 Q1023 NOSIG
           2021-01-25 11:00 251100Z 29004KT CAVOK 05/01 Q1023 NOSIG
           2021-01-25 11:30 251130Z 29005KT CAVOK 04/01 Q1023 NOSIG
           2021-01-25 12:00 251200Z 27001KT CAVOK 05/01 Q1022 NOSIG
           2021-01-25 12:30 251230Z 33001KT 9000 NSC 05/01 Q1023 NOSIG
           2021-01-25 13:00 251300Z 30002KT 9000 NSC 05/01 Q1023 NOSIG
           2021-01-25 13:30 251330Z 31002KT 9000 NSC 05/01 Q1023 NOSIG
           2021-01-25 14:00 251400Z 31003KT 9000 NSC 04/01 Q1023 NOSIG
           2021-01-25 14:30 251430Z 31002KT 8000 NSC 05/01 Q1023 NOSIG
           2021-01-25 15:00 251500Z 31001KT 8000 NSC 04/01 Q1022 NOSIG
           2021-01-25 15:30 251530Z 30003KT 8000 NSC 05/02 Q1022 NOSIG
           2021-01-25 16:00 251600Z 01001KT 7000 NSC 04/01 Q1022 NOSIG
           2021-01-25 16:30 251630Z 13002KT 6000 NSC 04/01 Q1021 NOSIG
           2021-01-25 17:00 251700Z 13006KT 6000 NSC 05/02 Q1021 NOSIG
           2021-01-25 17:30 251730Z 14002KT 5000 BR NSC 04/02 Q1021 NOSIG
           2021-01-25 18:00 251800Z 13006KT 4500 BR NSC 04/02 Q1021 NOSIG
           2021-01-25 18:30 251830Z 15006KT 4000 BR NSC 03/02 Q1020 NOSIG
           2021-01-25 19:00 251900Z 15008KT 5000 BR NSC 04/02 Q1020 NOSIG
           2021-01-25 19:30 251930Z 18003KT 150V250 5000 BR NSC 03/02 Q1020 NOSIG
           2021-01-25 20:00 252000Z 30009KT 260V320 4500 BR NSC 04/02 Q1020 NOSIG
           2021-01-25 20:30 252030Z 30006KT 260V320 5000 BR NSC 04/03 Q1020 NOSIG
           2021-01-25 21:00 252100Z 31003KT 230V340 4000 BR NSC 03/02 Q1020 NOSIG
           2021-01-25 21:30 252130Z 16002KT 4500 BR NSC 04/02 Q1020 NOSIG
           2021-01-25 22:00 252200Z 03002KT 4500 BR BKN045 OVC120 05/02 Q1020 NOSIG
           2021-01-25 22:30 252230Z 05003KT 010V070 4000 -RA BR BKN045 OVC100 04/02 Q1020 NOSIG
           2021-01-25 23:00 252300Z 05001KT 5000 BR SCT045 OVC100 04/02 Q1020 NOSIG
           2021-01-25 23:30 252330Z 13003KT 110V170 4500 BR BKN040 OVC130 04/03 Q1020 NOSIG
           2021-01-26 00:00 260000Z 25006KT 5000 BR BKN030 OVC130 05/03 Q1020 NOSIG
           2021-01-26 00:30 260030Z 20002KT 5000 -RA BR BKN025 OVC100 06/02 Q1020 NOSIG
           2021-01-26 01:00 260100Z 15005KT 4500 -RA BR BKN025 OVC100 05/03 Q1020 NOSIG
           2021-01-26 01:30 260130Z 16004KT 140V200 5000 BR FEW015 BKN025 OVC100 06/03 Q1020 NOSIG
           2021-01-26 02:00 260200Z 16005KT 4000 BR SCT015 BKN025 OVC100 05/03 Q1020 NOSIG
           2021-01-26 02:30 260230Z 17004KT 140V210 4500 BR SCT015 BKN025 OVC100 05/03 Q1020 NOSIG
           2021-01-26 03:00 260300Z 16005KT 4000 BR SCT010 BKN020 OVC100 05/03 Q1020 NOSIG
           2021-01-26 03:30 260330Z 17005KT 3500 BR SCT010 BKN020 OVC100 06/04 Q1020 NOSIG
           2021-01-26 04:00 260400Z 15004KT 120V190 3500 BR SCT008 BKN020 OVC100 06/03 Q1019 NOSIG
           2021-01-26 04:30 260430Z 17005KT 3000 BR SCT006 BKN020 OVC100 05/04 Q1018 NOSIG
           2021-01-26 05:00 260500Z 21003KT 180V260 1600 0600NW R15L/P2000N R15R/P2000N R16/1100D PRFG BKN002 BKN020 OVC100 05/04 Q1018 NOSIG
           2021-01-26 05:30 260530Z 26006KT 240V320 0150 R33R/0500N R34/0450D FG VV001 05/05 Q1018 NOSIG
           2021-01-26 06:00 260600Z 30009KT 0300 R33R/0650D R33L/0650N R34/1200U +DZ FG VV001 05/04 Q1018 NOSIG
           2021-01-26 06:30 260630Z 30008KT 1800 BR BKN005 OVC015 04/04 Q1018 NOSIG
           2021-01-26 07:00 260700Z 31007KT 6000 2000N BKN010 OVC025 04/04 Q1018 NOSIG
           2021-01-26 07:30 260730Z 31007KT 9000 BKN035 05/03 Q1018 NOSIG
           2021-01-26 08:00 260800Z 31010KT 9999 BKN020 05/02 Q1018 NOSIG
           2021-01-26 08:30 260830Z 31010KT 9999 SCT025 04/02 Q1019 NOSIG
           2021-01-26 09:00 260900Z 32010KT 9999 BKN025 04/02 Q1019 NOSIG
           2021-01-26 09:30 260930Z 33011KT 9999 BKN025 04/02 Q1020 NOSIG
           2021-01-26 10:00 261000Z 33008KT 9999 FEW025 03/01 Q1020 NOSIG
           2021-01-26 10:30 261030Z 34010KT 7000 FEW025 02/01 Q1020 NOSIG
           2021-01-26 11:00 261100Z 32007KT 7000 FEW025 02/00 Q1021 NOSIG
           2021-01-26 11:30 261130Z 33006KT 6000 FEW025 02/00 Q1021 NOSIG
           2021-01-26 12:00 261200Z 06002KT 6000 FEW025 02/00 Q1021 NOSIG
           2021-01-26 12:30 261230Z 32003KT 7000 NSC 02/M00 Q1021 NOSIG
           2021-01-26 13:00 261300Z 32005KT 7000 NSC 01/M01 Q1022 NOSIG
           2021-01-26 13:30 261330Z 33005KT 6000 NSC M00/M02 Q1022 NOSIG
           2021-01-26 14:00 261400Z 32004KT 6000 NSC 02/M01 Q1022 NOSIG
           2021-01-26 14:30 261430Z 29004KT 6000 NSC 02/M01 Q1022 NOSIG
           2021-01-26 15:00 261500Z 30005KT 6000 NSC 02/00 Q1023 NOSIG
           2021-01-26 15:30 261530Z 30007KT 6000 NSC 01/M00 Q1023 NOSIG
           2021-01-26 16:00 261600Z 30006KT 6000 NSC 01/M01 Q1023 NOSIG
           2021-01-26 16:30 261630Z 30008KT 7000 NSC 01/M03 Q1023 NOSIG
           2021-01-26 17:00 261700Z 33010KT 9000 NSC M00/M04 Q1024 NOSIG
           2021-01-26 17:30 261730Z 33011KT CAVOK 00/M04 Q1024 NOSIG
           2021-01-26 18:00 261800Z 33009KT CAVOK 00/M05 Q1024 NOSIG
           2021-01-26 18:30 261830Z 33010KT CAVOK 01/M06 Q1024 NOSIG
           2021-01-26 19:00 261900Z 34009KT CAVOK 00/M05 Q1025 NOSIG
           2021-01-26 19:30 261930Z 32008KT CAVOK M00/M08 Q1025 NOSIG
           2021-01-26 20:00 262000Z 32009KT CAVOK 00/M08 Q1025 NOSIG
           2021-01-26 20:30 262030Z 32009KT CAVOK 01/M10 Q1025 NOSIG
           2021-01-26 21:00 262100Z 33010KT CAVOK M00/M09 Q1025 NOSIG
           2021-01-26 21:30 262130Z 35005KT CAVOK M01/M10 Q1025 NOSIG
           2021-01-26 22:00 262200Z 34008KT CAVOK M03/M12 Q1025 NOSIG
           2021-01-26 22:30 262230Z 34006KT CAVOK M03/M12 Q1025 NOSIG
           2021-01-26 23:00 262300Z 35006KT CAVOK M02/M10 Q1025 NOSIG
           2021-01-26 23:30 262330Z 33007KT CAVOK M00/M11 Q1026 NOSIG
           2021-01-27 00:00 270000Z 34008KT CAVOK 00/M10 Q1026 NOSIG
           2021-01-27 00:30 270030Z 34009KT CAVOK 02/M13 Q1026 NOSIG
           2021-01-27 01:00 270100Z 34009KT CAVOK 03/M14 Q1026 NOSIG
           2021-01-27 01:30 270130Z 34010KT CAVOK 03/M16 Q1026 NOSIG
           2021-01-27 02:00 270200Z 32009KT CAVOK 03/M15 Q1026 NOSIG
           2021-01-27 02:30 270230Z 32009KT CAVOK 03/M16 Q1026 NOSIG
           2021-01-27 03:00 270300Z 33010KT CAVOK 04/M14 Q1025 NOSIG
           2021-01-27 03:30 270330Z 31008KT CAVOK 04/M15 Q1024 NOSIG
           2021-01-27 04:00 270400Z 32009KT CAVOK 04/M16 Q1024 NOSIG
           2021-01-27 04:30 270430Z 32010KT CAVOK 04/M15 Q1024 NOSIG
           2021-01-27 05:00 270500Z 29010KT CAVOK 04/M11 Q1024 NOSIG
           2021-01-27 05:30 270530Z 30011KT CAVOK 04/M13 Q1024 NOSIG
           2021-01-27 06:00 270600Z 29013KT CAVOK 04/M13 Q1024 NOSIG
           2021-01-27 06:30 270630Z 29013KT CAVOK 03/M10 Q1024 NOSIG
           2021-01-27 07:00 270700Z 28012KT CAVOK 03/M11 Q1024 NOSIG
           2021-01-27 07:30 270730Z 27010KT CAVOK 03/M12 Q1024 NOSIG
           2021-01-27 08:00 270800Z 28008KT CAVOK 03/M12 Q1024 NOSIG
           2021-01-27 08:30 270830Z 28009KT CAVOK 03/M10 Q1023 NOSIG
           2021-01-27 09:00 270900Z 28010KT 250V320 CAVOK 02/M10 Q1023 NOSIG
           2021-01-27 09:30 270930Z 29007KT CAVOK 02/M09 Q1023 NOSIG
           2021-01-27 10:00 271000Z 29005KT CAVOK 01/M08 Q1023 NOSIG
           2021-01-27 10:30 271030Z 30005KT CAVOK 01/M07 Q1023 NOSIG
           2021-01-27 11:00 271100Z 32003KT 300V010 CAVOK 02/M08 Q1023 NOSIG
           2021-01-27 11:30 271130Z 31003KT CAVOK 01/M08 Q1023 NOSIG
           2021-01-27 12:00 271200Z 31005KT CAVOK 00/M08 Q1023 NOSIG
           2021-01-27 12:30 271230Z 30004KT CAVOK 00/M07 Q1023 NOSIG
           2021-01-27 13:00 271300Z 31004KT CAVOK 01/M05 Q1022 NOSIG
           2021-01-27 13:30 271330Z 31005KT CAVOK 02/M05 Q1022 NOSIG
           2021-01-27 14:00 271400Z 31003KT CAVOK 01/M04 Q1022 NOSIG
           2021-01-27 14:30 271430Z 33002KT CAVOK 01/M04 Q1022 NOSIG
           2021-01-27 15:00 271500Z 36002KT CAVOK 00/M04 Q1021 NOSIG
           2021-01-27 15:30 271530Z 34004KT CAVOK M01/M04 Q1021 NOSIG
           2021-01-27 16:00 271600Z 04002KT CAVOK M00/M03 Q1020 NOSIG
           2021-01-27 16:30 271630Z 08003KT CAVOK M01/M04 Q1020 NOSIG
           2021-01-27 17:00 271700Z 06002KT CAVOK 00/M06 Q1019 NOSIG
           2021-01-27 17:30 271730Z 14003KT CAVOK M02/M07 Q1019 NOSIG
           2021-01-27 18:00 271800Z 13004KT CAVOK M02/M08 Q1018 NOSIG
           2021-01-27 18:30 271830Z 13004KT CAVOK M01/M06 Q1018 NOSIG
           2021-01-27 19:00 271900Z 15003KT CAVOK M00/M06 Q1017 NOSIG
           2021-01-27 19:30 271930Z 14005KT CAVOK M00/M06 Q1017 NOSIG
           2021-01-27 20:00 272000Z 17004KT 140V210 CAVOK M01/M05 Q1017 NOSIG
           2021-01-27 20:30 272030Z 18005KT 160V220 CAVOK 01/M05 Q1016 NOSIG
           2021-01-27 21:00 272100Z 20004KT CAVOK 00/M06 Q1016 NOSIG
           2021-01-27 21:30 272130Z 18005KT 160V220 CAVOK 01/M06 Q1015 NOSIG
           2021-01-27 22:00 272200Z 17005KT CAVOK 01/M04 Q1015 NOSIG
           2021-01-27 22:30 272230Z 19007KT 160V220 CAVOK 01/M04 Q1015 NOSIG
           2021-01-27 23:00 272300Z 17011KT CAVOK 02/M04 Q1014 BECMG 3000 -SNRA SCT010 BKN025
           2021-01-27 23:30 272330Z 15012KT CAVOK 02/M03 Q1013 BECMG 3000 -SNRA SCT010 BKN025
           2021-01-28 00:00 280000Z 18012KT 1200 0800S R15L/P2000N R15R/P2000N R16/P2000D PRFG SCT015 OVC025 01/M02 Q1013 NOSIG
           2021-01-28 00:30 280030Z 23012KT 200V260 1200 0800S R15L/P2000N R15R/P2000N R16/P2000N -RASN PRFG SCT008 OVC025 01/00 Q1013 NOSIG
           2021-01-28 01:00 280100Z 29020KT 6000 -RA FEW012 BKN030 01/00 Q1013 NOSIG
           2021-01-28 01:30 280130Z 30021KT 9999 FEW016 BKN030 02/M01 Q1013 NOSIG
           2021-01-28 02:00 280200Z 32020KT 9999 FEW018 BKN030 OVC080 01/M02 Q1014 NOSIG
           2021-01-28 02:30 280230Z 32020KT 9999 FEW019 BKN030 BKN080 01/M03 Q1014 WS R16 R34 NOSIG
           2021-01-28 03:00 280300Z 32020KT 9999 FEW019 BKN030 BKN080 01/M03 Q1014 WS R16 R34 NOSIG
           2021-01-28 03:30 280330Z 31020KT 9999 FEW018 SCT030 BKN080 02/M03 Q1014 WS R16 R34 NOSIG
           2021-01-28 04:00 280400Z 31023G34KT 9999 FEW030 SCT080 02/M07 Q1015 WS R16 R34 NOSIG
           2021-01-28 04:30 280430Z 31020KT 9999 BKN030 00/M07 Q1015 WS R16 R34 NOSIG
           2021-01-28 05:00 280500Z 31021KT 9999 SCT040 01/M08 Q1015 WS R16 R34 NOSIG
           2021-01-28 05:30 280530Z 31023KT CAVOK 00/M10 Q1016 WS R16 R34 NOSIG
           2021-01-28 06:00 280600Z 31024KT CAVOK M01/M12 Q1017 WS R16 R34 NOSIG
           2021-01-28 06:30 280630Z 31023KT 9999 FEW040 M01/M13 Q1017 WS R16 R34 NOSIG
           2021-01-28 07:00 280700Z 31022KT 9999 FEW040 M02/M13 Q1018 WS R16 R34 NOSIG
           2021-01-28 07:30 280730Z 32024G35KT 9999 SCT040 M03/M14 Q1018 WS R16 R34 NOSIG
           2021-01-28 08:00 280800Z 32023G34KT 9999 BKN040 M04/M13 Q1019 WS R16 R34 NOSIG
           2021-01-28 08:30 280830Z 31025G38KT 9999 BKN040 M03/M16 Q1019 WS ALL RWY NOSIG
           2021-01-28 09:00 280900Z 31028G39KT 9999 SCT040 M04/M17 Q1019 WS R16 R34 NOSIG
           2021-01-28 09:30 280930Z 32023KT 9999 SCT040 M05/M14 Q1019 WS ALL RWY NOSIG
           2021-01-28 10:00 281000Z 32027G39KT 9999 SCT040 M05/M14 Q1020 WS R16 R34 NOSIG
           2021-01-28 10:30 281030Z 32026KT 9999 SCT030 M06/M15 Q1021 WS ALL RWY NOSIG
           2021-01-28 11:00 281100Z 32025G36KT 9999 SCT030 M06/M15 Q1021 WS ALL RWY NOSIG
           2021-01-28 11:30 281130Z 31025KT 9999 SCT030 M07/M16 Q1021 WS R16 R34 NOSIG
           2021-01-28 12:00 281200Z 32023KT 9999 FEW030 M07/M16 Q1022 WS R16 R34 NOSIG
           2021-01-28 12:30 281230Z 32023KT 9999 FEW030 M07/M17 Q1022 WS R16 R34 NOSIG
           2021-01-28 13:00 281300Z 32024KT 9999 FEW030 M07/M19 Q1023 WS R16 R34 NOSIG
           2021-01-28 13:30 281330Z 33029KT 9999 FEW030 M08/M20 Q1023 WS R16 R34 NOSIG
           2021-01-28 14:00 281400Z 33026KT 9999 FEW030 M08/M17 Q1024 NOSIG
           2021-01-28 14:30 281430Z 32022KT 9999 FEW030 M08/M17 Q1024 NOSIG
           2021-01-28 15:00 281500Z 33026KT 9999 FEW030 M09/M18 Q1025 NOSIG
           2021-01-28 15:30 281530Z 33018KT 9999 FEW030 M09/M17 Q1025 NOSIG
           2021-01-28 16:00 281600Z 33020KT 9999 FEW030 M09/M18 Q1025 NOSIG
           2021-01-28 16:30 281630Z 32020KT 9999 FEW030 M09/M18 Q1025 WS R16 R34 NOSIG
           2021-01-28 17:00 281700Z 32022KT 9999 SCT040 M09/M19 Q1026 WS R16 R34 NOSIG
           2021-01-28 17:30 281730Z 32019KT 9999 SCT040 M09/M18 Q1026 WS R16 R34 NOSIG
           2021-01-28 18:00 281800Z 32017KT 9999 SCT040 M09/M18 Q1026 NOSIG
           2021-01-28 18:30 281830Z 33017KT 9999 FEW040 M09/M19 Q1026 NOSIG
           2021-01-28 19:00 281900Z 32021KT 9999 FEW040 M09/M20 Q1026 NOSIG
           2021-01-28 19:30 281930Z 32020KT 9999 FEW040 M09/M22 Q1026 WS R16 R34 NOSIG
           2021-01-28 20:00 282000Z 32022KT 9999 FEW040 M09/M22 Q1026 WS ALL RWY NOSIG
           2021-01-28 20:30 282030Z 32019G31KT CAVOK M09/M22 Q1026 WS ALL RWY NOSIG
           2021-01-28 21:00 282100Z 32017KT CAVOK M10/M22 Q1027 NOSIG
           2021-01-28 21:30 282130Z 32016KT CAVOK M10/M21 Q1027 NOSIG
           2021-01-28 22:00 282200Z 32015KT CAVOK M10/M21 Q1027 NOSIG
           2021-01-28 22:30 282230Z 33017KT CAVOK M10/M21 Q1027 NOSIG
           2021-01-28 23:00 282300Z 35012KT 310V010 CAVOK M10/M20 Q1028 NOSIG
           2021-01-28 23:30 282330Z 36010KT CAVOK M10/M21 Q1028 NOSIG
           2021-01-29 00:00 290000Z 35008KT 320V020 CAVOK M09/M21 Q1028 NOSIG
           2021-01-29 00:30 290030Z 34010KT CAVOK M09/M21 Q1028 NOSIG
           2021-01-29 01:00 290100Z 34010KT CAVOK M08/M20 Q1028 NOSIG
           2021-01-29 01:30 290130Z 34012KT 310V010 CAVOK M08/M20 Q1028 NOSIG
           2021-01-29 02:00 290200Z 34012KT CAVOK M08/M20 Q1028 NOSIG
           2021-01-29 02:30 290230Z 33012KT CAVOK M07/M21 Q1028 NOSIG
           2021-01-29 03:00 290300Z 34010KT 310V010 CAVOK M07/M21 Q1028 NOSIG
           2021-01-29 03:30 290330Z 34012KT CAVOK M07/M21 Q1028 NOSIG
           2021-01-29 04:00 290400Z 33012KT CAVOK M06/M21 Q1027 NOSIG
           2021-01-29 04:30 290430Z 33010KT CAVOK M07/M20 Q1027 NOSIG
           2021-01-29 05:00 290500Z 32010KT 290V350 CAVOK M06/M20 Q1027 NOSIG
           2021-01-29 05:30 290530Z 34009KT CAVOK M05/M19 Q1027 NOSIG
           2021-01-29 06:00 290600Z 31010KT 280V350 CAVOK M06/M20 Q1027 NOSIG
           2021-01-29 06:30 290630Z 32011KT CAVOK M06/M20 Q1027 NOSIG
           2021-01-29 07:00 290700Z 32010KT CAVOK M06/M18 Q1027 NOSIG
           2021-01-29 07:30 290730Z 31009KT CAVOK M05/M17 Q1027 NOSIG
           2021-01-29 08:00 290800Z 32009KT 290V350 CAVOK M06/M16 Q1027 NOSIG
           2021-01-29 08:30 290830Z 32005KT 290V350 CAVOK M06/M16 Q1027 NOSIG
           2021-01-29 09:00 290900Z 35004KT CAVOK M06/M17 Q1026 NOSIG
           2021-01-29 09:30 290930Z 34003KT CAVOK M07/M16 Q1026 NOSIG
           2021-01-29 10:00 291000Z 20002KT CAVOK M06/M17 Q1026 NOSIG
           2021-01-29 10:30 291030Z 15001KT CAVOK M07/M16 Q1027 NOSIG
           2021-01-29 11:00 291100Z 11003KT 080V140 CAVOK M07/M16 Q1026 NOSIG
           2021-01-29 11:30 291130Z 12003KT CAVOK M07/M16 Q1027 NOSIG
           2021-01-29 12:00 291200Z 12003KT CAVOK M07/M16 Q1027 NOSIG
           2021-01-29 12:30 291230Z 12003KT CAVOK M08/M16 Q1027 NOSIG
           2021-01-29 13:00 291300Z 10004KT CAVOK M07/M16 Q1027 NOSIG
           2021-01-29 13:30 291330Z 13005KT CAVOK M08/M15 Q1027 NOSIG
           2021-01-29 14:00 291400Z 12003KT 9999 FEW030 M07/M14 Q1027 NOSIG
           2021-01-29 14:30 291430Z 12003KT 9999 BKN030 M05/M11 Q1027 NOSIG
           2021-01-29 15:00 291500Z 16008KT 9999 BKN030 M05/M10 Q1027 NOSIG
           2021-01-29 15:30 291530Z 16010KT 9999 BKN030 M05/M10 Q1026 NOSIG
           2021-01-29 16:00 291600Z 14011KT 9999 BKN030 M05/M11 Q1026 NOSIG
           2021-01-29 16:30 291630Z 14013KT 9999 BKN040 M05/M11 Q1025 NOSIG
           2021-01-29 17:00 291700Z 14011KT 9999 BKN040 M05/M11 Q1025 NOSIG
           2021-01-29 17:30 291730Z 14013KT 9999 BKN030 M05/M11 Q1025 NOSIG
           2021-01-29 18:00 291800Z 14011KT 9999 FEW019 BKN030 M05/M11 Q1025 NOSIG
           2021-01-29 18:30 291830Z 14014KT 9999 -SN SCT019 BKN030 M04/M12 Q1025 NOSIG
           2021-01-29 19:00 291900Z 15013KT 9999 -SN SCT019 BKN030 M04/M11 Q1024 NOSIG
           2021-01-29 19:30 291930Z 15013KT 9999 -SN SCT019 BKN030 M04/M10 Q1024 NOSIG
           2021-01-29 20:00 292000Z 15015KT 9999 -SN SCT015 BKN030 M04/M09 Q1023 NOSIG
           2021-01-29 20:30 292030Z 15015KT 2000 -SN BR BKN010 BKN030 M04/M07 Q1023 NOSIG
           2021-01-29 21:00 292100Z 15015KT 2000 -SN BR BKN010 BKN030 M04/M06 Q1023 NOSIG
           2021-01-29 21:30 292130Z 15015KT 6000 -SN BKN010 BKN030 M04/M06 Q1023 NOSIG
           2021-01-29 22:00 292200Z 14012KT 9999 -SN BKN010 BKN030 M04/M06 Q1023 NOSIG
           2021-01-29 22:30 292230Z 14012KT 9999 -SN BKN010 BKN030 M03/M05 Q1023 NOSIG
           2021-01-29 23:00 292300Z 13011KT 9999 -SN BKN010 BKN030 M03/M05 Q1023 NOSIG
           2021-01-29 23:30 292330Z 14015KT 9999 SCT019 BKN030 M03/M05 Q1022 NOSIG
           2021-01-30 00:00 300000Z 06007KT 020V120 9999 FEW013 BKN025 BKN070 M02/M05 Q1023 NOSIG
           2021-01-30 00:30 300030Z 11008KT 010V150 9999 FEW014 BKN025 BKN070 M02/M05 Q1023 NOSIG
           2021-01-30 01:00 300100Z 13008KT 9999 FEW017 BKN025 M01/M04 Q1023 NOSIG
           2021-01-30 01:30 300130Z 10004KT 070V130 9999 BKN035 00/M03 Q1024 NOSIG
           2021-01-30 02:00 300200Z 11005KT 050V160 9999 FEW030 01/M03 Q1024 NOSIG
           2021-01-30 02:30 300230Z 13004KT 090V160 9999 FEW030 03/M03 Q1024 NOSIG
           2021-01-30 03:00 300300Z 04006KT 010V090 9999 FEW030 03/M02 Q1024 NOSIG
           2021-01-30 03:30 300330Z 04002KT 9999 FEW030 05/M02 Q1024 NOSIG
           2021-01-30 04:00 300400Z 30009KT 9000 NSC 05/M01 Q1024 NOSIG
           2021-01-30 04:30 300430Z 31008KT 9000 NSC 05/M01 Q1024 NOSIG
           2021-01-30 05:00 300500Z 33011KT CAVOK 04/M02 Q1024 NOSIG
           2021-01-30 05:30 300530Z 32007KT CAVOK 04/M03 Q1024 NOSIG
           2021-01-30 06:00 300600Z 32006KT CAVOK 04/M03 Q1025 NOSIG
           2021-01-30 06:30 300630Z 30005KT 260V340 CAVOK 05/M04 Q1025 NOSIG
           2021-01-30 07:00 300700Z 28008KT CAVOK 04/M03 Q1026 NOSIG
           2021-01-30 07:30 300730Z 29008KT CAVOK 03/M03 Q1026 NOSIG
           2021-01-30 08:00 300800Z 28008KT CAVOK 03/M04 Q1026 NOSIG
           2021-01-30 08:30 300830Z 28006KT CAVOK 02/M03 Q1026 NOSIG
           2021-01-30 09:00 300900Z 28003KT CAVOK 01/M03 Q1027 NOSIG
           2021-01-30 09:30 300930Z 30004KT 240V030 9000 NSC 01/M03 Q1027 NOSIG
           2021-01-30 10:00 301000Z 36002KT 9000 NSC 01/M03 Q1028 NOSIG
           2021-01-30 10:30 301030Z 36004KT 7000 NSC M00/M03 Q1027 NOSIG
           2021-01-30 11:00 301100Z 32002KT 7000 NSC M00/M03 Q1028 NOSIG
           2021-01-30 11:30 301130Z 31001KT 7000 NSC M00/M02 Q1028 NOSIG
           2021-01-30 12:00 301200Z 01003KT 6000 NSC M02/M04 Q1028 NOSIG
           2021-01-30 12:30 301230Z 35003KT 6000 NSC M01/M02 Q1028 NOSIG
           2021-01-30 13:00 301300Z 03002KT 6000 1600NE NSC M01/M02 Q1028 NOSIG
           2021-01-30 13:30 301330Z 01001KT 6000 NSC M01/M02 Q1029 NOSIG
           2021-01-30 14:00 301400Z 33001KT 6000 NSC M02/M03 Q1029 NOSIG
           2021-01-30 14:30 301430Z 35001KT 6000 NSC M02/M03 Q1029 NOSIG
           2021-01-30 15:00 301500Z 31001KT 6000 NSC M02/M04 Q1029 NOSIG
           2021-01-30 15:30 301530Z 04002KT 6000 NSC M02/M04 Q1029 NOSIG
           2021-01-30 16:00 301600Z 11001KT 6000 NSC M03/M05 Q1029 NOSIG
           2021-01-30 16:30 301630Z 06003KT 6000 NSC M02/M06 Q1029 NOSIG
           2021-01-30 17:00 301700Z 05004KT 7000 NSC M04/M05 Q1029 NOSIG
           2021-01-30 17:30 301730Z 06006KT 8000 NSC M03/M05 Q1029 NOSIG
           2021-01-30 18:00 301800Z 06005KT 9000 NSC M03/M07 Q1029 NOSIG
           2021-01-30 18:30 301830Z 06006KT CAVOK M03/M08 Q1029 NOSIG
           2021-01-30 19:00 301900Z 09003KT CAVOK M02/M08 Q1029 NOSIG
           2021-01-30 19:30 301930Z 06004KT CAVOK M03/M09 Q1029 NOSIG
           2021-01-30 20:00 302000Z 06007KT CAVOK M04/M08 Q1029 NOSIG
           2021-01-30 20:30 302030Z 06005KT CAVOK M04/M08 Q1029 NOSIG
           2021-01-30 21:00 302100Z 07005KT CAVOK M03/M08 Q1029 NOSIG
           2021-01-30 21:30 302130Z 12002KT CAVOK M04/M08 Q1029 NOSIG
           2021-01-30 22:00 302200Z 08004KT CAVOK M03/M07 Q1029 NOSIG
           2021-01-30 22:30 302230Z 11004KT CAVOK M03/M06 Q1029 NOSIG
           2021-01-30 23:00 302300Z 11004KT 9000 NSC M02/M06 Q1029 NOSIG
           2021-01-30 23:30 302330Z 11006KT CAVOK M01/M05 Q1029 NOSIG
           2021-01-31 00:00 310000Z 11005KT 080V140 CAVOK M00/M05 Q1029 NOSIG
           2021-01-31 00:30 310030Z 13007KT CAVOK 01/M05 Q1029 NOSIG
           2021-01-31 01:00 310100Z 13008KT CAVOK 02/M06 Q1028 NOSIG
           2021-01-31 01:30 310130Z 12007KT CAVOK 03/M06 Q1028 NOSIG
           2021-01-31 02:00 310200Z 13009KT CAVOK 04/M07 Q1028 NOSIG
           2021-01-31 02:30 310230Z 14010KT 9999 FEW030 BKN200 05/M08 Q1028 NOSIG
           2021-01-31 03:00 310300Z 14014KT 9999 SCT030 BKN200 06/M08 Q1027 NOSIG
           2021-01-31 03:30 310330Z 14011KT 9999 BKN030 BKN200 06/M08 Q1027 NOSIG
           2021-01-31 04:00 310400Z 14012KT 9999 SCT030 BKN200 07/M08 Q1026 NOSIG
           2021-01-31 04:30 310430Z 14012KT 9999 SCT030 08/M07 Q1025 NOSIG
           2021-01-31 05:00 310500Z 15011KT 9999 SCT030 09/M05 Q1025 NOSIG
           2021-01-31 05:30 310530Z 15010KT 9999 BKN040 09/M03 Q1024 NOSIG
           2021-01-31 06:00 310600Z 14011KT 9999 BKN030 10/M03 Q1024 NOSIG
           2021-01-31 06:30 310630Z 15010KT 9999 BKN030 09/M02 Q1024 NOSIG
           2021-01-31 07:00 310700Z 15009KT 9999 BKN030 BKN110 08/M01 Q1023 NOSIG
           2021-01-31 07:30 310730Z 16009KT 9999 BKN030 BKN110 08/M00 Q1023 NOSIG
           2021-01-31 08:00 310800Z 17008KT 9999 BKN030 BKN120 08/01 Q1023 NOSIG
           2021-01-31 08:30 310830Z 17008KT 9999 BKN030 BKN120 08/01 Q1023 NOSIG
           2021-01-31 09:00 310900Z 18008KT 9999 BKN030 BKN120 08/02 Q1023 NOSIG
           2021-01-31 09:30 310930Z 15007KT 9999 BKN030 BKN120 08/02 Q1023 NOSIG
           2021-01-31 10:00 311000Z 15007KT 9999 BKN030 BKN120 08/03 Q1023 NOSIG
           2021-01-31 10:30 311030Z 15006KT 9999 BKN030 BKN120 08/03 Q1023 NOSIG
           2021-01-31 11:00 311100Z 16008KT 9999 BKN030 BKN120 07/02 Q1022 NOSIG
           2021-01-31 11:30 311130Z 15009KT 9999 BKN030 BKN110 08/03 Q1022 NOSIG
           2021-01-31 12:00 311200Z 16008KT 9999 BKN030 BKN110 08/02 Q1022 NOSIG
           2021-01-31 12:30 311230Z 15009KT 9999 BKN035 BKN110 06/02 Q1021 NOSIG
           2021-01-31 13:00 311300Z 16008KT 9999 BKN040 BKN110 04/01 Q1021 NOSIG
           2021-01-31 13:30 311330Z 16009KT 9999 BKN045 BKN100 05/01 Q1021 NOSIG
           2021-01-31 14:00 311400Z 15009KT 9999 BKN045 BKN100 05/01 Q1021 NOSIG
           2021-01-31 14:30 311430Z 16008KT 9999 BKN045 BKN100 05/01 Q1020 NOSIG
           2021-01-31 15:00 311500Z 17007KT 9999 BKN030 BKN100 06/02 Q1020 NOSIG
           2021-01-31 15:30 311530Z 17011KT 9999 BKN030 BKN100 06/01 Q1020 NOSIG
           2021-01-31 16:00 311600Z 16010KT 9999 BKN040 06/01 Q1019 NOSIG
           2021-01-31 16:30 311630Z 17010KT 9999 BKN030 05/01 Q1019 NOSIG
           2021-01-31 17:00 311700Z 16010KT 9999 -RA BKN025 BKN070 05/01 Q1018 NOSIG
           2021-01-31 17:30 311730Z 16010KT 9999 BKN020 05/03 Q1018 NOSIG
           2021-01-31 18:00 311800Z 16009KT 140V200 9999 SCT017 BKN020 05/03 Q1018 NOSIG
           2021-01-31 18:30 311830Z 15009KT 9999 SCT015 BKN020 06/04 Q1017 NOSIG
           2021-01-31 19:00 311900Z 15008KT 9999 BKN011 05/04 Q1017 NOSIG
           2021-01-31 19:30 311930Z 15009KT 9999 BKN009 06/04 Q1017 NOSIG
           2021-01-31 20:00 312000Z 15006KT 9999 BKN007 06/04 Q1016 NOSIG
           2021-01-31 20:30 312030Z 17005KT 140V220 9000 BKN005 06/04 Q1016 NOSIG
           2021-01-31 21:00 312100Z 18004KT 150V230 4000 BR BKN002 06/05 Q1016 NOSIG
           2021-01-31 21:30 312130Z 20003KT 150V240 2000 0800NE PRFG BKN002 07/06 Q1016 NOSIG
           2021-01-31 22:00 312200Z 21004KT 160V250 0500 0250NW R15L/1400D R15R/1300D R16/0900N FG BKN001 07/06 Q1016 NOSIG
           2021-01-31 22:30 312230Z 23006KT 180V240 0350 R15L/1000N R15R/0900U R16/0900N FG VV001 06/06 Q1015 NOSIG
           2021-01-31 23:00 312300Z 22005KT 170V260 0250 R15L/0700N R15R/0700N R16/0600D FG VV001 06/06 Q1015 NOSIG
           2021-01-31 23:30 312330Z 22006KT 170V250 0600 0350NW R15L/1200U R15R/1100U R16/0800N FG VV001 06/06 Q1016 NOSIG
            `}
          </p>
        </div>
      </div>
    </>
  )
}
