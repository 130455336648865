import { UnitHelper } from "../../../common/helper/unit.helper";

export default class VisibilityHelper {
  constructor(
    private readonly unitHelper: UnitHelper,
    private readonly visRegex = /CAVOK|\s\d{3,4}\s|\s(\d\s)?[\w/]+SM\s/gm,
  ) {}

  isLessEqualThan(vis: number, criteria: number) {
    return (vis <= criteria);
  }

  getVisibility(METAR: string) {
    const matched = METAR.match(this.visRegex);

    if (!matched) return null;

    const visibility = matched[0];

    if (visibility === 'CAVOK') {
      return this.unitHelper.cavokToMeter();
    }
    
    if (/SM/.test(visibility)) {
      return this.unitHelper.mileToMeter(visibility);
    }

    return parseInt(visibility);
  }
}