import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Description from "./Description";
import Form from "./Form";
import { MainState, updateEntity } from "./main.slice";
import bg_webp from '../../assets/images/bg_metar2report_webp.webp';
import { ReactComponent as Next } from '../../assets/svg/next.svg';
import Wrapper from "../../common/components/container/Wrapper";
import ModalWrapper from "../../common/components/modal/Wrapper";
import Footer from "./Footer";
import Header from "./Header";

export default function MainContainer() {
  const [modal, setModal] = useState<JSX.Element | null>(null);

  const { progress } = useAppSelector((state) => state.main);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const updateEntityWrapper = useCallback((payload: Partial<MainState>) => {
    dispatch(updateEntity(payload));
  }, [dispatch]);

  const onClickNext = useCallback(() => {
    navigate('/category');
  }, [navigate])

  const onClickBtn = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const target = (e.target as HTMLElement);  
    switch(target.nodeName) {
      case 'BUTTON': {
        setModal(<Form setModal={setModal} updateEntityWrapper={updateEntityWrapper} />)
        break;
      }
      case 'P': {
        setModal(<Description />)
        break;
      }
      default:
        break;
    }      
  }, [updateEntityWrapper]);

  let status;
  switch (true) {
    case progress === 100:
      status = 
      <div className='relative inline-block cursor-pointer hover:animate-pulse' onClick={onClickNext}>
        <Next className='h-12 mx-auto' fill='white' />
        <p className='absolute top-8 inset-x-0'>NEXT</p>
      </div>
      break;
    case progress && progress < 100: 
      status = <p>{progress}%</p>
      break;
    default:
      break;
  }

  return (
    <div
      className='flex flex-col justify-between fixed w-screen h-screen bg-center bg-scroll bg-no-repeat bg-cover'
      style={{backgroundImage: `url(${bg_webp})`}}
    > 
      <Header />
      <main className='h-full'>
        <Wrapper>
          <div className='mt-12 max-w-sm m-auto' onClick={onClickBtn}>
            <button className="block m-auto h-10 w-4/5 bg-zinc-50 text-zinc-700 font-bold rounded-sm hover:animate-pulse md:w-full">
              LOAD DATA
            </button>
            <p className='w-40 mt-2 mx-auto italic text-center text-white leading-8 cursor-pointer hover:text-red-600'>
              ※ how to use it?
            </p>
          </div>
        </Wrapper>
        {modal ? <ModalWrapper setModal={setModal}>{modal}</ModalWrapper> : null}
        {status 
          ? <div className='text-white text-center mt-8 font-semibold'>
            {status} 
            </div>
          : null
        }
      </main>
      <Footer />
    </div>
  );
}